import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from 'components/button/button';
import { ScrollableDiv } from 'components/scrollableDiv';
import { NotificationContext } from 'context/Notifications/notificationContext';
import { getDateTime, getProfileText } from 'utlils/utils';
import { YourNotificationWrapper } from './style';
import { MARK_ALL_NOTIFICATIONS_AS_READ, useAxiosWrapper } from 'services';
import tokenService from 'services/token.service';
import { setAllNotifications } from 'context/Notifications/notificationAction';
import NO_NOTIFICATION_IMAGE from '../../../assests/images/Notification-image.png';

export const YourNotification = () => {
  const navigate = useNavigate();
  const [fnWrapper, context] = useAxiosWrapper();

  const { notificationState, notificationDispatch } =
    useContext(NotificationContext);
  const allUnreadNotifications = notificationState?.notifications?.filter(
    (i) => i?.status === 'UNREAD'
  );

  const markAllNotificationsAsRead = async () => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: MARK_ALL_NOTIFICATIONS_AS_READ,
      method: 'PUT',
      type: 'MARK_ALL_NOTIFICATIONS_AS_READ',
      payload: {
        userId: loggedInUser?.id,
      },
    });
    if (res?.data?.statusCode === 200) {
      setAllNotifications(notificationDispatch, res?.data?.data);
    }
  };

  return (
    <YourNotificationWrapper>
      <div className="notification-container-wrp__">
        <div className="notification-container-wrp__header-content">
          <div className="notification-container-wrp__header-text">
            Your Notification
          </div>
          <CustomButton
            label="Mark All as Read"
            variant="textBtn"
            customPadding="0px"
            color="themeSecondary"
            className="notification-container-wrp__header-btn"
            onClick={markAllNotificationsAsRead}
            disabled={allUnreadNotifications.length === 0}
            loading={
              context?.busy?.status &&
              context?.busy?.type === 'MARK_ALL_NOTIFICATIONS_AS_READ'
            }
          />
        </div>
        <div className="notification-container-wrp__notification-content__">
          {allUnreadNotifications.length ? (
            <ScrollableDiv scrollOnHeight="calc(100vh - 55vh)">
              {allUnreadNotifications.map((notification, index) => {
                const isSmallEnterpriseRole =
                  notification?.sender?.role === 'SMALL_ENTERPRISE';
                const senderName = isSmallEnterpriseRole
                  ? notification?.sender?.smallEnterprise?.enterpriseName
                  : notification?.sender?.individual
                  ? notification?.sender?.individual?.name
                  : 'miCollab';
                const senderProfilePic = isSmallEnterpriseRole
                  ? notification?.sender?.smallEnterprise?.profilePicture
                  : notification?.sender?.individual
                  ? notification?.sender?.individual?.profilePicture
                  : '';
                return (
                  <div key={notification?.id + '--' + index}>
                    <div className="notification-container-wrp__notification-content__line" />
                    <div className="row align-items-center mx-0 my-3">
                      <div className="col-md-2 col-lg-2 col-sm-2">
                        {senderProfilePic !== null &&
                        senderProfilePic !== '' ? (
                          <div className="profile-round-img">
                            <img
                              src={senderProfilePic}
                              alt="user-profile"
                              className="prof-img"
                            />
                          </div>
                        ) : (
                          <div className="profile-round-img">
                            <span className="prof-txt">
                              {getProfileText(senderName)}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col-md-10 col-lg-10 col-sm-10">
                        <span className="notification-container-wrp__notification-content__username">
                          {senderName}{' '}
                        </span>
                        <span className="notification-container-wrp__notification-content__title">
                          {notification?.message}
                        </span>
                        <div className="notification-container-wrp__notification-content__time-duration">
                          {getDateTime(notification?.createdAt)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </ScrollableDiv>
          ) : (
            <div className="no-notification-section">
              <img src={NO_NOTIFICATION_IMAGE} alt="no-notification" />
              No new notification available
            </div>
          )}
          <div className="notification-container-wrp__footer">
            <CustomButton
              label="See All Notification"
              variant="textBtn"
              customPadding="0px"
              className="notification-container-wrp__footer__all-notification-btn"
              onClick={() => navigate('/allNotifications')}
            />
          </div>
        </div>
      </div>
    </YourNotificationWrapper>
  );
};
