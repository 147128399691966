import React from 'react';
import { OtherAgreementDetailsWrapper } from './style';
import CollaborationDetails from './Components/CollaborationDetails';
import BiddingDetails from './Components/BiddingDetails';
import JobDetails from './Components/JobDetails';

export default function Details(props) {
  const { details, documents, agreementType, frequentlyAskedQuestions } = props;

  return (
    <OtherAgreementDetailsWrapper>
      {agreementType === 'COLLABORATION' ? (
        <CollaborationDetails details={details} documents={documents} />
      ) : agreementType === 'BIDDING' ? (
        <BiddingDetails
          details={details}
          frequentlyAskedQuestions={frequentlyAskedQuestions}
          documents={documents}
        />
      ) : (
        <JobDetails details={details} />
      )}
    </OtherAgreementDetailsWrapper>
  );
}
