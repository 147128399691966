import { CustomButton } from 'components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
import { statusColor } from '../helper';

export default function ProjectReportCard(props) {
  const {
    title,
    profilePicture,
    reports,
    projectType,
    projectState,
    projectStatus,
    id,
    user,
    enterprise,
  } = props;
  const navigate = useNavigate();
  return (
    <ProjectReportCardWrapper>
      <div className="content-header">
        {profilePicture && profilePicture !== '' ? (
          <div className="profile-pic-class">
            <img src={profilePicture} alt="Profile" />
          </div>
        ) : (
          <div className="icon-style-class">
            <Icon name={'project'} size={24} color="#404f67" />
          </div>
        )}
        <div className="title-wrapper">
          <div
            className="title-wrapper-title"
            onClick={() => navigate(`/public/project/${id}/details`)}>
            {title}
          </div>
          <div className="title-wrapper-subtitle">
            <span
              onClick={() =>
                navigate(
                  user.role === 'INDIVIDUAL'
                    ? `/public/individual/${user?.id}/summary/basic-details`
                    : `/public/enterprise/${enterprise?.id}/details`
                )
              }
              className="title-wrapper-subtitle-text">
              {projectType === 'ENTERPRISE'
                ? enterprise?.enterpriseName
                : user?.individual?.name}
            </span>
          </div>
          <div
            className="team-report-status"
            style={{ color: statusColor(projectState) }}>
            {projectState === 'ACTIVE' ? 'PENDING' : projectState}
          </div>
        </div>
      </div>
      <div className="horizontal-line" />
      <div className="info">
        <div className="project-type">{projectType}</div>
        <div className="project-status">{projectStatus}</div>
        <div className="report-count">{reports.length} reports</div>
      </div>
      <div className="view-button">
        <CustomButton
          customPadding="10px 14px"
          label="View"
          variant="outline"
          className="w-100"
          onClick={() => navigate(`/miHintAdmin/project-reports/${id}/view`)}
        />
      </div>
    </ProjectReportCardWrapper>
  );
}

const ProjectReportCardWrapper = styled.div`
  padding: 10px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  min-width: 300px;
  & .content-header {
    display: flex;
    align-items: center;
    gap: 12px;
    & .profile-pic-class,
    .profile-pic-class img {
      width: 54px;
      height: 54px;
      border-radius: 50%;
    }
    & .icon-style-class {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      background: #e9fbf6;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .title-wrapper {
      & .title-wrapper-title {
        cursor: pointer;
      }
      & .title-wrapper-subtitle {
        & .title-wrapper-subtitle-text {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      &-title {
        font-size: 16px;
        line-height: 16px;
        color: #17191d;
        font-weight: 500;
      }
      &-subtitle {
        display: flex;
        align-items: center;
        gap: 8px;
        &-text {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: #404f67;
        }
      }
      .team-report-status {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
      }
    }
  }
  & .horizontal-line {
    margin: 10px 0px 10px 0px;
    width: 100%;
    border-top: 0.5px solid #c4c4c4;
  }
  & .info {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #404f67;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  & .info > * {
    padding: 4px 0px 4px 0px;
  }
  & .view-button {
    padding: 10px 0px 5px 0px;
  }
`;
