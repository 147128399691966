import styled from 'styled-components';

export const YourAgreementsWrapper = styled.div`
  & .main-wrapper {
    background-color: white;
    & .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      & .filters {
        display: flex;
        gap: 10px;
      }
    }
    & .no-data {
      display: grid;
      place-items: center;
      text-align: center;
      margin: 60px 0px;
    }
    & .save-btn {
      display: grid;
      place-items: center;
    }
  }
  .agreement-table {
    min-width: 45dvw;
    min-height: 40dvh;
    .agreement-list-loader {
      display: flex;
      align-self: center;
      justify-self: center;
      align-items: center;
      justify-content: center;
      color: #a2a2a2;
    }
    .agreement-table {
      overflow-x: auto;
      font-size: 8px !important;
    }
  }
`;
