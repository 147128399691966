import styled from 'styled-components';

export const AddCollaboratorCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 0.84px solid rgba(146, 157, 175, 1);
  & .collaborator-detail {
    display: flex;
    gap: 20px;
    & .image-container {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      & .image {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    & .data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      & .name {
        font-size: 15px;
        font-weight: 500;
      }
      & .role {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
`;
