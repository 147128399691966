import styled from 'styled-components';

export const OtherAgreementsWrapper = styled.div`
  & .main-wrapper {
    background-color: white;
    & .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
    & .table {
      & .agreement-list-loader {
        display: flex;
        justify-content: center;
      }
    }
  }
`;
