import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BLOCK_REPORTED_TEAM_OR_PROJECT_BY_SUPERADMIN,
  UNBLOCK_REPORTED_TEAM_OR_PROJECT_BY_SUPERADMIN,
  GET_SINGLE_REPORT_DATA,
  useAxiosWrapper,
} from 'services';
import styled from 'styled-components';
import ReportsDataCard from '../components/ReportsDataCard';
import { CustomButton, Loader, ModalPopup, Textarea } from 'components';
import { toast } from 'react-toastify';

export default function ViewReport() {
  const { type, id } = useParams();
  const [fnWrapper, context] = useAxiosWrapper();
  const [projectData, setProjectData] = useState({});
  const [reportData, setReportData] = useState([]);
  const [blockConfirmationPopup, setBlockConfirmationPopup] = useState(false);
  const [unBlockConfirmationPopup, setUnBlockConfirmationPopup] =
    useState(false);
  const [adminReason, setAdminReason] = useState('');
  const navigate = useNavigate();

  let title =
    type === 'project-reports' ? projectData?.title : projectData?.teamName;

  const getReportData = useCallback(async (type, id) => {
    let dataType = type === 'project-reports' ? 'projectId' : 'teamId';
    const res = await fnWrapper({
      url: `${GET_SINGLE_REPORT_DATA}?${dataType}=${id}`,
      method: 'GET',
      type: 'GET_LIST_OF_REPORTS',
    });

    if (res?.data?.statusCode === 200) {
      setProjectData(res?.data?.data);
      setReportData(res?.data?.data?.reports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const declineBlock = () => {
    setBlockConfirmationPopup(false);
    setAdminReason('');
  };

  const blockProjectOrTeamHandler = async (action) => {
    if (action) {
      let payload = {
        status: 'BLOCKED',
        reason: adminReason,
      };
      if (type === 'project-reports') {
        payload.projectId = id;
      } else {
        payload.teamId = id;
      }
      const res = await fnWrapper({
        url: `${BLOCK_REPORTED_TEAM_OR_PROJECT_BY_SUPERADMIN}`,
        method: 'PUT',
        type: 'BLOCK_REPORTED_TEAM_OR_PROJECT_BY_SUPERADMIN',
        payload: payload,
      });

      if (res?.data?.statusCode === 200) {
        setBlockConfirmationPopup(false);
        toast.success(
          action === true ? 'Blocked Successfully' : 'Unblocked Successfully',
          {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
      }

      navigate(
        type === 'project-reports'
          ? '/miHintAdmin/project-reports'
          : '/miHintAdmin/team-reports'
      );
    }
  };

  const unBlockProjectOrTeamHandler = async () => {
    let payload = {};
    if (type === 'project-reports') {
      payload.projectId = id;
    } else {
      payload.teamId = id;
    }
    const res = await fnWrapper({
      url: `${UNBLOCK_REPORTED_TEAM_OR_PROJECT_BY_SUPERADMIN}`,
      method: 'PUT',
      type: 'UNBLOCK_REPORTED_TEAM_OR_PROJECT_BY_SUPERADMIN',
      payload: payload,
    });

    if (res?.data?.statusCode === 200) {
      setBlockConfirmationPopup(false);
      toast.success('Unblocked Successfully', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      navigate(
        type === 'project-reports'
          ? `/miHintAdmin/project-reports`
          : `/miHintAdmin/team-reports`
      );
    }

    navigate(
      type === 'project-reports'
        ? '/miHintAdmin/project-reports'
        : '/miHintAdmin/team-reports'
    );
  };

  const btnClick = () => {
    if (isBlocked()) {
      setUnBlockConfirmationPopup(true);
    } else {
      setBlockConfirmationPopup(true);
    }
  };

  useEffect(() => {
    getReportData(type, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isBlocked = () => {
    return (
      projectData?.projectState === 'BLOCKED' ||
      projectData?.teamState === 'BLOCKED'
    );
  };

  return (
    <ViewReportWrapper>
      <div className="main-wrapper">
        {context.busy.status ? (
          <Loader loading={context.busy.status} />
        ) : (
          <>
            <div className="header">
              <div className="heading">
                <span
                  className="name"
                  onClick={() =>
                    navigate(
                      type === 'project-reports'
                        ? `/public/project/${projectData?.id}/details`
                        : `/public/team/${projectData?.id}/details`
                    )
                  }>
                  {title}
                </span>
                <div className="no-of-reports">
                  &nbsp; ({reportData?.length} Reports)
                </div>
              </div>
              <div className="action">
                <div className="action">
                  <CustomButton
                    onClick={btnClick}
                    variant="light"
                    color={isBlocked() ? 'themeSecondary' : 'themeTertiary'}
                    label={
                      isBlocked()
                        ? `Unblock ${
                            type === 'project-reports' ? 'Project' : 'Team'
                          }`
                        : `Block ${
                            type === 'project-reports' ? 'Project' : 'Team'
                          }`
                    }
                  />
                </div>
              </div>
            </div>
            <div className="reports-card">
              {reportData.map((report, index) => (
                <ReportsDataCard key={index} {...report} title={title} />
              ))}
            </div>
          </>
        )}
      </div>
      {blockConfirmationPopup && (
        <ModalPopup
          open={false}
          onClose={() => setBlockConfirmationPopup(false)}
          title={`Block ${type === 'project-reports' ? 'Project' : 'Team'}`}>
          <Textarea
            placeholder={'Type an explaination...'}
            value={adminReason}
            onChange={(e) => setAdminReason(e.target.value)}
            rows={6}
            label={`Describe your concerns for reporting this ${
              type === 'project-reports' ? 'project' : 'team'
            } to assist the Admin in resolving the matter.`}
            required
          />
          <div className="d-flex justify-content-center gap-4">
            <CustomButton
              disabled={!adminReason}
              onClick={() => blockProjectOrTeamHandler(true)}
              loading={context.busy.status}
              customPadding="16px 52px"
              label="Yes"
              color="themeSecondary"
            />
            <CustomButton
              onClick={declineBlock}
              loading={context.busy.status}
              customPadding="16px 52px"
              variant="outline"
              label="No"
              color="themeSecondary"
            />
          </div>
        </ModalPopup>
      )}
      {unBlockConfirmationPopup && (
        <ModalPopup
          open={false}
          onClose={() => setUnBlockConfirmationPopup(false)}
          title={`Unblock ${type === 'project-reports' ? 'Project' : 'Team'}`}>
          {/* <Textarea
            placeholder={'Type an explaination...'}
            value={adminReason}
            onChange={(e) => setAdminReason(e.target.value)}
            rows={6}
            label={`Describe your concerns for reporting this ${
              type === 'project-reports' ? 'project' : 'team'
            } to assist the Admin in resolving the matter.`}
            required
          /> */}
          <p className="text-center">
            {`Are you sure you want to unblock this ${
              type === 'project-reports' ? 'Project' : 'Team'
            } ?`}
          </p>
          <div className="d-flex justify-content-center gap-4">
            <CustomButton
              onClick={() => unBlockProjectOrTeamHandler()}
              loading={context.busy.status}
              customPadding="16px 52px"
              label="Yes"
              color="themeSecondary"
            />
            <CustomButton
              onClick={declineBlock}
              loading={context.busy.status}
              customPadding="16px 52px"
              variant="outline"
              label="No"
              color="themeSecondary"
            />
          </div>
        </ModalPopup>
      )}
    </ViewReportWrapper>
  );
}

const ViewReportWrapper = styled.div`
  & .main-wrapper {
    & .header {
      display: flex;
      justify-content: space-between;
      & .heading {
        display: flex;
        align-items: baseline;
        & .name {
          font-size: 26px;
          font-weight: 600;
          line-height: 39px;
          letter-spacing: 0em;
          cursor: pointer;
        }
        & .name:hover {
          text-decoration: underline;
        }
      }
      .no-of-reports-and-action {
        display: flex;
        gap: 10px;
      }
    }
    & .reports-card {
      padding-top: 20px;
    }
  }
  & .warning-text {
    font-size: 18px;
    font-weight: 400;
  }
`;
