import { AddCollaboratorCardWrapper } from './Style';
import image from '../../../../assests/images/public-bg.png';
import { CustomButton } from 'components';

export default function AddCollaboratorCard(props) {
  const { memberData, onSelectClick } = props;

  return (
    <AddCollaboratorCardWrapper>
      <div className="collaborator-detail">
        <div className="image-container">
          <img
            className="image"
            src={
              memberData?.profilePicture ? memberData?.profilePicture : image
            }
            alt="user"
          />
        </div>
        <div className="data">
          <div className="name">{memberData?.name}</div>
          <div className="role">
            {memberData?.title ? memberData?.title : 'Collaborator'}
          </div>
        </div>
      </div>
      <div className="select-btn">
        <CustomButton
          color={memberData?.selected ? 'themeGray' : 'themeSecondary'}
          variant="outline"
          label={memberData?.selected ? 'Remove' : 'Select'}
          customPadding="12px, 32px, 12px, 32px"
          onClick={() => onSelectClick(memberData?.id)}
        />
      </div>
    </AddCollaboratorCardWrapper>
  );
}
