import { CustomButton, ModalPopup, TabGroup } from 'components';
import React, { useCallback, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
import Details from './Details';
import YourApplication from './YourApplication';
import AgreementRequests from './Agreements';
import {
  CLOSE_AGREEMENT_REQUEST,
  GET_BIDDING_AND_APPLICANT_DATA,
  GET_COLLABORATION_AND_APPLICANT_DATA,
  GET_JOB_AND_APPLICANT_DETAILS,
  useAxiosWrapper,
} from 'services';
import { useEffect } from 'react';
import tokenService from 'services/token.service';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
const TABS = ['Details', 'Your Application', 'Agreement'];

export default function ViewOtherAgreement() {
  const [fnWrapper] = useAxiosWrapper();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const [details, setDetails] = useState({});
  const [questions, setQuestions] = useState({});
  const [documents, setDocuments] = useState([]);
  const [frequentlyAskedQuestions, setFrequentlyAskedQuestions] = useState({});
  const [applicantDocuments, setApplicantDocuments] = useState([]);
  const [closeAgreementPopupOpen, setCloseAgreementPopupOpen] = useState(false);
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const agreementType = searchParam.get('type');
  const agreementMemberId = searchParam.get('agreementMemberId');
  const tabSwitchHandler = (value) => {
    setActiveTab(value);
    let tab = value.toLowerCase().replace(' ', '-');
    navigate(
      `/individual/profile/other-agreement/${id}/view/${tab}?type=${agreementType}&agreementMemberId=${agreementMemberId}`
    );
  };
  const getCollaborationAndApplicantDetails = useCallback(async () => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: `${GET_COLLABORATION_AND_APPLICANT_DATA}/${id}?applicantId=${loggedInUser?.id}`,
      method: 'GET',
      type: 'GET_COLLABORATION_AND_APPLICANT_DATA',
    });
    if (res.data.statusCode === 200) {
      setDetails(res?.data?.data);
      setDocuments(res?.data?.data.documents);
      setQuestions(JSON.parse(res?.data?.data?.question));
      setApplicantDocuments(res?.data?.data?.appliedCollaboration?.documents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getBiddingAndApplicantDetails = useCallback(async () => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: `${GET_BIDDING_AND_APPLICANT_DATA}/${id}?applicantId=${loggedInUser?.id}`,
      method: 'GET',
      type: 'GET_BIDDING_AND_APPLICANT_DATA',
    });
    if (res?.data?.statusCode === 200) {
      let attachments = [];
      setDetails(res?.data?.data);
      setFrequentlyAskedQuestions(
        JSON.parse(res?.data?.data?.appliedBidding?.bidTypeFAQ)
      );
      if (
        res?.data?.data?.appliedBidding?.rulesAndDocuments !== null &&
        res?.data?.data?.appliedBidding?.rulesAndDocuments.length > 0
      ) {
        res?.data?.data?.appliedBidding?.rulesAndDocuments.forEach((e) =>
          attachments.push(e)
        );
      }
      if (res?.data?.data?.appliedBidding?.videoAttachment) {
        attachments.push(res?.data?.data?.appliedBidding?.videoAttachment);
      }
      setDocuments(attachments);
      setQuestions(res?.data?.data?.questionsToBidder);
      setApplicantDocuments(res?.data?.data?.signedDocuments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getJobAndApplicantDetails = useCallback(async () => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: `${GET_JOB_AND_APPLICANT_DETAILS}/${id}?applicantId=${loggedInUser?.id}`,
      method: 'GET',
      type: 'GET_BIDDING_AND_APPLICANT_DATA',
    });
    if (res?.data?.statusCode === 200) {
      setDetails(res?.data?.data);
      setQuestions(JSON.parse(res?.data?.data?.question));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (agreementType === 'BIDDING') {
      getBiddingAndApplicantDetails();
    } else if (agreementType === 'COLLABORATION') {
      getCollaborationAndApplicantDetails();
    } else {
      getJobAndApplicantDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBiddingAndApplicantDetails, getCollaborationAndApplicantDetails]);
  const closeAgreement = async () => {
    let data = {
      status: 'MUTUALLY_CLOSED',
    };
    const response = await fnWrapper({
      url: `${CLOSE_AGREEMENT_REQUEST}/${agreementMemberId}`,
      method: 'PUT',
      type: 'CLOSE_AGREEMENT',
      payload: data,
    });
    if (response?.data?.statusCode === 200) {
      toast.success(response?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    setCloseAgreementPopupOpen(false);
    navigate(
      '/individual/profile/agreements/other-agreements/collab-agreements'
    );
  };
  let requestStatus =
    details?.appliedCollaboration?.collaborationAgreement?.agreementMember[0]
      ?.status ||
    details?.appliedBidding?.agreementBidding[0]?.agreementMember[0].status ||
    details?.appliedJob?.jobAgreement[0].agreementMember[0].status;
  return (
    <ViewOtherAgreementWrapper>
      <div className="main-wrapper">
        <div className="header-text">
          <div className="heading">
            <span>
              <Icon
                name="arrow-back"
                color="black"
                size={25}
                onClick={() =>
                  navigate(
                    `/individual/profile/agreements/other-agreements/${
                      agreementType === 'COLLABORATION'
                        ? 'collab-agreements'
                        : agreementType === 'BIDDING'
                        ? 'bid-agreements'
                        : 'job-agreements'
                    }`
                  )
                }
              />
            </span>
            {details?.appliedCollaboration?.projects?.title ||
              details?.appliedBidding?.project?.title ||
              details?.appliedJob?.title}
          </div>
          <div>
            {requestStatus === 'SUCCESSFULLY_CLOSED' && (
              <div className="close_btn">
                <CustomButton
                  variant="light"
                  label="Close agreement"
                  customPadding="8px"
                  onClick={() => setCloseAgreementPopupOpen(true)}
                />
              </div>
            )}
          </div>
        </div>
        {closeAgreementPopupOpen && (
          <ModalPopup
            title={'Close Agreement'}
            onClose={() => setCloseAgreementPopupOpen(false)}>
            <p className="close-agreement-popup-warning">
              The agreement has been successfully closed, as both parties have
              mutually agreed, Do you want to close this agreement?
            </p>
            <div className="close-agreement-popup-btn">
              <CustomButton
                label="No"
                variant="textBtn"
                onClick={() => setCloseAgreementPopupOpen(false)}
              />
              <CustomButton label="Yes" onClick={() => closeAgreement()} />
            </div>
          </ModalPopup>
        )}
        {requestStatus === 'SUCCESSFULLY_CLOSED' && (
          <div className="warning-text">
            The agreement has been closed by the admin. To complete the process,
            please close it on your side as well
          </div>
        )}
        <div className="tabs">
          <div className="tab">
            <TabGroup
              tabLists={TABS}
              tabClick={tabSwitchHandler}
              activeTab={activeTab}
            />
          </div>
        </div>
        <div className="routes">
          <Routes>
            <Route path="">
              <Route
                path="details"
                element={
                  <Details
                    agreementType={agreementType}
                    details={details}
                    documents={documents}
                    frequentlyAskedQuestions={frequentlyAskedQuestions}
                  />
                }
              />
              <Route
                path="your-application"
                element={
                  <YourApplication
                    details={details}
                    applicantDocuments={applicantDocuments}
                    questions={questions}
                    agreementType={agreementType}
                  />
                }
              />
              <Route
                path="agreement"
                element={
                  <AgreementRequests
                    requestStatus={requestStatus}
                    details={details}
                  />
                }
              />
            </Route>
          </Routes>
        </div>
      </div>
    </ViewOtherAgreementWrapper>
  );
}
const ViewOtherAgreementWrapper = styled.div`
  & .main-wrapper {
    background-color: white;
    & .header-text {
      display: flex;
      justify-content: space-between;
      & .heading {
        display: flex;
        gap: 10px;
      }
    }
    .close-agreement-popup-warning {
      text-align: center;
    }
    .close-agreement-popup-btn {
      margin-top: 15px;
      display: flex;
      justify-content: center;
    }
    & .warning-text {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      background-color: #f4f7fc;
      padding: 10px 0px;
    }
  }
`;
