import { useEffect, useState } from 'react';
import AgreementCommunicationWrapper from './styles';
import Icon from 'utlils/Icons/Icon';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CustomButton, ScrollableDiv } from 'components';
import PlaceHolderImage from '../../../../assests/images/public-bg.png';
import { RequestMessageCard } from 'pages/IndividualDashboard/components/RequestMessageCard';
import { AddRequestModal } from './AddRequestModal';
import {
  useAxiosWrapper,
  FETCH_AGREEMENT_MEMBER_CONVERSATION,
  CREATE_NEW_AGREEMENT_REQUEST_COMMENT,
  UPDATE_AGREEMENT_REQUEST_STATUS,
  CREATE_NEW_AGREEMENT_REQUEST,
  CLOSE_AGREEMENT_REQUEST,
} from 'services';
import { CloseDealModal } from './CloseDealModal';
import { toast } from 'react-toastify';
import tokenService from 'services/token.service';

const AgreementCommunication = () => {
  const [fnWrapper] = useAxiosWrapper();
  const { agreementId } = useParams();
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const [agreementRequestsList, setAgreementRequestsList] = useState([]);
  const [isAddRequestPopUpOpen, setIsAddRequestPopUpOpen] = useState(false);
  const [isCloseDealPopUpOpen, setIsCloseDealPopUpOpen] = useState(false);
  const [isCloseDealBtnDisabled, setIsCloseDealBtnDisabled] = useState(false);
  const getAgreementRequests = async () => {
    const response = await fnWrapper({
      url: `${FETCH_AGREEMENT_MEMBER_CONVERSATION}/${searchParam.get(
        'agreementMemberId'
      )}`,
      method: 'GET',
      type: 'FETCH_AGREEMENT_MEMBER_CONVERSATION',
    });

    if (response?.data?.statusCode === 200) {
      if (
        response?.data?.data[0]?.agreementMember?.status ===
          'SUCCESSFULLY_CLOSED' ||
        response?.data?.data[0]?.agreementMember?.status ===
          'MUTUALLY_CLOSED' ||
        response?.data?.data[0]?.agreementMember?.status === 'CANCELLED'
      ) {
        setIsCloseDealBtnDisabled(true);
      }
      let requestList = response?.data?.data?.map((item) => {
        return {
          senderId: item?.userId,
          senderData: item?.user,
          message: item?.message,
          status: item?.status,
          attachments: item?.attachments,
          dueDate: item?.dueDate,
          createdAt: item?.createdAt,
          comments: item?.agreementComment,
          agreementSubRequests: item?.agreementSubRequest,
          requestId: item?.id,
          agreementRequestStatus: item?.agreementMember?.status,
        };
      });
      setAgreementRequestsList(requestList);
    }
  };

  const createAgreementRequest = async (data) => {
    let userId = tokenService.getSession()?.id;
    let formData = new FormData();
    formData.append('message', data?.message);
    if (data?.dueDate) {
      formData.append('dueDate', data?.dueDate);
    }
    formData.append('agreementId', agreementId);
    formData.append('agreementMemberId', searchParam.get('agreementMemberId'));
    formData.append('attachments', data?.attachments);
    formData.append('userId', userId);
    const response = await fnWrapper({
      url: CREATE_NEW_AGREEMENT_REQUEST,
      payload: formData,
      method: 'POST',
      type: 'CREATE_NEW_AGREEMENT_REQUEST',
    });
    if (response?.data?.statusCode === 200) {
      toast.success(response?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    setIsAddRequestPopUpOpen(false);
    getAgreementRequests();
  };

  const updateAgreementRequest = async (data) => {
    const response = await fnWrapper({
      url: `${UPDATE_AGREEMENT_REQUEST_STATUS}/${data?.requestId}`,
      payload: {
        status: data?.status,
      },
      method: 'PUT',
      type: 'UPDATE_AGREEMENT_REQUEST_STATUS',
    });
    if (response?.data?.statusCode === 200) {
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    getAgreementRequests();
  };

  const closeAgreementRequest = async (status) => {
    const response = await fnWrapper({
      url: `${CLOSE_AGREEMENT_REQUEST}/${searchParam.get('agreementMemberId')}`,
      payload: {
        status,
      },
      method: 'PUT',
      type: 'CLOSE_AGREEMENT_REQUEST',
    });
    if (response?.data?.statusCode === 200) {
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    navigate(-1);
  };

  useEffect(() => {
    getAgreementRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementId]);

  const postRequestComment = async (data) => {
    let userId = tokenService.getSession()?.id;
    const response = await fnWrapper({
      url: `${CREATE_NEW_AGREEMENT_REQUEST_COMMENT}`,
      payload: {
        message: data?.message,
        agreementRequestId: data?.id,
        userId,
      },
      method: 'POST',
      type: 'CREATE_NEW_AGREEMENT_REQUEST_COMMENT',
    });
    if (response?.data?.statusCode === 200) {
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      getAgreementRequests();
    }
  };
  return (
    <AgreementCommunicationWrapper>
      <div className="main-container">
        <div className="nav-and-info">
          <div className="nav-back">
            <div className="nav-element">
              <Icon
                name="arrow-back"
                color="black"
                size={25}
                onClick={() => navigate(-1)}
              />
            </div>
            <div className="title">{searchParam.get('memberName')}</div>
          </div>
          {!isCloseDealBtnDisabled && agreementRequestsList.length > 0 && (
            <CustomButton
              customPadding="8px"
              variant="light"
              onClick={() => setIsCloseDealPopUpOpen(true)}>
              Close this Deal
            </CustomButton>
          )}
        </div>
        <div className="send-message">
          <img className="image" src={PlaceHolderImage} alt="user" />
          <div
            className={`send-msg-btn ${
              isCloseDealBtnDisabled ? 'disabled-btn' : ''
            }`}
            onClick={() => {
              !isCloseDealBtnDisabled && setIsAddRequestPopUpOpen(true);
            }}>
            Click to add
          </div>
        </div>
        <ScrollableDiv scrollOnHeight="65dvh">
          <div className="requests-section">
            {agreementRequestsList?.length > 0 ? (
              agreementRequestsList?.map((item, index) => {
                return (
                  <RequestMessageCard
                    key={index}
                    userData={item}
                    onStatusUpdate={(data) => updateAgreementRequest(data)}
                    isThreeDotsEnabled={
                      item?.agreementRequestStatus === 'IN_PROGRESS'
                    }
                    OnComment={(data) => postRequestComment(data)}
                    getAgreementRequests={getAgreementRequests}
                    isAgreementCreator={true}
                    subRequests={item?.agreementSubRequests}
                  />
                );
              })
            ) : (
              <p className="no-request">No requests available.</p>
            )}
          </div>
        </ScrollableDiv>
      </div>
      {isAddRequestPopUpOpen ? (
        <AddRequestModal
          createAgreement={(data) => createAgreementRequest(data)}
          closePopUp={() => setIsAddRequestPopUpOpen(false)}
        />
      ) : null}
      {isCloseDealPopUpOpen ? (
        <CloseDealModal
          closeDeal={(status) => closeAgreementRequest(status)}
          closePopUp={() => setIsCloseDealPopUpOpen(false)}
        />
      ) : null}
    </AgreementCommunicationWrapper>
  );
};

export default AgreementCommunication;
