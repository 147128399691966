import styled from 'styled-components';

export const AgreementRequestsWrapper = styled.div`
  & .add-data {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    & .image-container {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      & .image {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    & .input {
      background-color: rgba(247, 246, 255, 1);
      width: 100%;
      height: 50px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      color: rgba(146, 157, 175, 1);
    }
  }
  & .approved-warning-text {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: rgba(6, 120, 0, 1);
    background-color: rgba(10, 189, 0, 0.1);
    padding: 10px 0px;
  }
  & .rejected-warning-text {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: rgba(219, 90, 75, 1);
    background-color: rgba(230, 102, 87, 0.1);
    padding: 10px 0px;
  }
  .custom-date {
    height: 52px;
    border-radius: 12px;
    border: 2px solid #e9eef4;
    padding: 17px;
    width: 100%;
  }
  & .file-upload {
    margin-top: 10px;
  }
  & .post-attachment {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 20px;

    & .img-wrapper {
      display: flex;
      width: 100%;
      word-break: break-all;

      & span {
        color: #929daf;
      }

      & .img-container {
        width: 30px;
        height: 30px;
        margin-top: 10px;
      }

      & .img-text {
        margin-top: 10px;
        text-decoration: underline;
      }

      & .remove-icon {
        padding-left: 2px;
        cursor: pointer;
        align-self: center;
      }
    }

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 5px;
    }
  }
  & .save-btn {
    display: grid;
    place-content: center;
  }
  & .no-data-found {
    display: grid;
    place-content: center;
    padding: 50px;
  }
  & .requests-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
