import React, { useCallback, useEffect, useState } from 'react';
import { OtherAgreementsWrapper } from './style';
import { CustomButton, CustomDropDown, TabGroup } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import { startCase } from 'lodash';
import Icon from 'utlils/Icons/Icon';
import { AGREEMENT_STATUS_LIST } from '../YourAgreements/constants';
import { FETCH_OTHER_AGREEMENT_LIST, useAxiosWrapper } from 'services';
import tokenService from 'services/token.service';
import AgreementsTable from '../YourAgreements/AgreementsTable';
import dayjs from 'dayjs';
import { AgreementListAction, StatusComponent } from '../YourAgreements/helper';
const TABS = ['Collab Agreements', 'Bid Agreements', 'Job Agreements'];

export default function OtherAgreements() {
  const [fnWrapper, context] = useAxiosWrapper();
  const navigate = useNavigate();
  const [agreementsList, setAgreementsList] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { agreementType } = useParams();
  const [activeTab, setActiveTab] = useState(
    startCase(agreementType.replace('-', ' '))
  );
  const tabSwitchHandler = (value) => {
    setActiveTab(value);
    let tab = value.toLowerCase().replaceAll(' ', '-');
    navigate(`/individual/profile/agreements/other-agreements/${tab}`);
  };
  const getOtherAgreementsList = useCallback(
    async (type) => {
      const loggedInUser = tokenService.getSession();
      const res = await fnWrapper({
        url: `${FETCH_OTHER_AGREEMENT_LIST}/${loggedInUser.id}?type=${type}`,
        method: 'GET',
        type: 'FETCH_OTHER_AGREEMENT_LIST',
      });
      if (res.data.statusCode === 200) {
        const agreementsListData = [];
        res?.data?.data.rows.forEach((item) => {
          let agreementData = {
            project_name: item?.agreement?.project?.title,
            created_on: dayjs(item.createdAt).format('DD MMM YYYY'),
            status: <StatusComponent status={item?.status} />,
            action: (
              <AgreementListAction
                type="otherAgreement"
                agreementType={agreementType}
                agreementMemberId={item.id}
                id={
                  item?.agreement?.collaborationId ||
                  item?.agreement?.biddingId ||
                  item?.agreement?.jobId
                }
              />
            ),
          };
          if (item?.agreement?.type === 'COLLABORATION') {
            agreementData['type'] =
              item?.agreement?.collaboration?.collaborationType;
          } else if (item?.agreement?.type === 'BIDDING') {
            agreementData['type'] = 'BIDDING';
          } else if (item?.agreement?.type === 'JOB') {
            agreementData['type'] = 'JOB';
          }
          agreementsListData.push(agreementData);
        });
        setAgreementsList(agreementsListData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTab]
  );
  useEffect(() => {
    let type =
      agreementType === 'collab-agreements'
        ? 'COLLABORATION'
        : agreementType === 'bid-agreements'
        ? 'BIDDING'
        : 'JOB';
    getOtherAgreementsList(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOtherAgreementsList]);

  return (
    <OtherAgreementsWrapper>
      <div className="main-wrapper">
        <div className="header-text">
          <p className="heading">Other Agreements</p>
        </div>
        <div className="agreement-container">
          <div className="tab">
            <TabGroup
              tabLists={TABS}
              tabClick={tabSwitchHandler}
              activeTab={activeTab}
            />
          </div>
        </div>
        <div className="actions">
          <div className="filter">
            <CustomButton
              variant="light"
              color="themeGray"
              onClick={() => setIsFilterOpen((prev) => !prev)}>
              <Icon name="filter" />
            </CustomButton>
          </div>
          <div className="status-list">
            <CustomDropDown
              optionLists={AGREEMENT_STATUS_LIST}
              // selectedItem={filterState?.status}
              // setSelectedItem={(value) => {
              //   setFilterState((prev) => ({
              //     ...prev,
              //     status: value,
              //   }));
              // }}
              width="150px"
              customStyle={{ padding: '0px 10px' }}
              placeholder="Status"
            />
          </div>
        </div>
        <div className="table">
          {context.busy.status ? (
            <div className="agreement-list-loader">
              <Icon color="#000" name="loader" size={40} />
              Loading ...
            </div>
          ) : (
            <AgreementsTable agreementsList={agreementsList} />
          )}
        </div>
      </div>
    </OtherAgreementsWrapper>
  );
}
