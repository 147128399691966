import { useState } from 'react';
import RequestMessageCardWrapper from './style';
import Icon from 'utlils/Icons/Icon';
import { ReactComponent as ThreeDots } from 'assests/images/three-dots.svg';
import { getProfileText } from 'utlils/utils';
import dayjs from 'dayjs';
import { checkForFileType } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import RequestComment from './RequestComment';
import {
  CustomButton,
  CustomFileUploadButton,
  ModalPopup,
  Textarea,
} from 'components';
import tokenService from 'services/token.service';
import SubRequestCard from './SubRequestCard';
import { CREATE_NEW_SUB_REQUEST, useAxiosWrapper } from 'services';
import { toast } from 'react-toastify';
import CommonDropDown from 'pages/enterpriseDashboard/components/commonDropDown';
import { AttachmentsTypes } from 'pages/IndividualDashboard/constants';
const dropDownOptionsCreator = [
  { id: 1, label: 'Mark as approved' },
  { id: 2, label: 'Mark as rejected' },
  { id: 3, label: 'Publish Request' },
];

const dropDownOption = [
  {
    id: 3,
    label: 'Publish Request',
  },
];
const DEFAULT_STATE = {
  message: '',
};
export const RequestMessageCard = (props) => {
  const {
    userData,
    OnComment,
    onStatusUpdate,
    isThreeDotsEnabled = true,
    isAgreementCreator = false,
    getAgreementRequests,
    subRequests = [],
  } = props;
  const [optionsOpen, setOptionOpen] = useState(false);
  const [state, setState] = useState(DEFAULT_STATE);
  const [attachment, setAttachment] = useState({});
  const [isAddSubRequestPopUpOpen, setIsAddSubRequestPopUpOpen] =
    useState(false);
  const [showSubRequests, setShowSubRequests] = useState(false);
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [popUpState, setPopUpState] = useState({
    isOpen: false,
    type: '',
  });
  const [fnWrapper] = useAxiosWrapper();
  const attachmentsHandler = async (files) => {
    if (AttachmentsTypes.includes(files[0].type)) {
      setAttachment(files[0]);
    } else {
      toast.error(`File type of ${files[0].type} is not supported.`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };
  const removeAttachmentsHandler = async (file) => {
    setAttachment({});
  };
  const onPopupClose = () => {
    setIsAddSubRequestPopUpOpen(false);
    setState(DEFAULT_STATE);
    setAttachment({});
  };
  const onChangeHandler = (field = '', value = null) => {
    setState({
      ...state,
      [field]: value,
    });
  };
  function handleOptionsClick(item) {
    switch (item.id) {
      case 1:
        handlePopUp('approve');
        break;
      case 2:
        handlePopUp('reject');
        break;
      case 3:
        openSubRequestPopup();
        break;
      default:
        break;
    }
  }
  const handlePopUp = (type = '') => {
    if (type.length > 0) {
      setPopUpState((prev) => ({
        isOpen: !prev.isOpen,
        type: type,
      }));
    } else {
      setPopUpState((prev) => ({ ...prev, isOpen: !prev.isOpen }));
    }
    setOptionOpen(false);
  };
  const getFileName = (file) => {
    const setFileName = file?.split('/');
    return setFileName[setFileName?.length - 1];
  };

  const statusUpdate = async () => {
    let data = {
      requestId: userData?.requestId,
      status: popUpState.type === 'approve' ? 'APPROVED' : 'REJECTED',
    };
    setOptionOpen(false);
    handlePopUp();
    onStatusUpdate(data);
  };

  const openSubRequestPopup = () => {
    setIsAddSubRequestPopUpOpen(true);
    setOptionOpen(false);
  };

  const createSubRequest = async () => {
    let userId = tokenService.getSession()?.id;
    let formData = new FormData();
    formData.append('message', state?.message);
    formData.append('agreementRequestId', userData?.requestId);
    formData.append('attachments', attachment);
    formData.append('userId', userId);
    const response = await fnWrapper({
      url: CREATE_NEW_SUB_REQUEST,
      payload: formData,
      method: 'POST',
      type: 'CREATE_NEW_SUB_REQUEST',
    });
    if (response?.data?.statusCode === 200) {
      toast.success(response?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    setIsAddSubRequestPopUpOpen(false);
    getAgreementRequests();
    setAttachment({});
    setState(DEFAULT_STATE);
  };
  const showHideSubRequest = () => {
    setShowSubRequests(!showSubRequests);
    getAgreementRequests();
  };
  return (
    <>
      <RequestMessageCardWrapper>
        <div className="request">
          <div className="card-header-action">
            <div className="user-info">
              <div className="profile-round-img">
                {userData?.senderData?.individual?.profilePicture ? (
                  <img
                    className="prof-img"
                    src={userData?.senderData?.individual?.profilePicture}
                    alt="user"
                  />
                ) : (
                  <span className="prof-txt">
                    {getProfileText(userData?.senderData?.individual?.name)}
                  </span>
                )}
              </div>
              <div className="user-details">
                <p className="user-name">
                  {userData?.senderData?.individual?.name}
                </p>
                <p className="time">
                  {dayjs(userData?.createdAt).format('DD MMM YYYY')}
                </p>
              </div>
            </div>
            <div className="options">
              {userData?.status !== 'PENDING' && (
                <CustomButton
                  customPadding="8px 12px"
                  color={
                    userData?.status?.toLowerCase() === 'rejected'
                      ? 'themeTertiary'
                      : 'themePrimary'
                  }
                  variant="light"
                  label={userData?.status?.toLowerCase()}
                />
              )}
              {isThreeDotsEnabled && userData?.status === 'PENDING' && (
                <div
                  className="three-dot-option"
                  onClick={() => setOptionOpen(!optionsOpen)}>
                  <ThreeDots
                    color={optionsOpen ? '#219fff' : '#c4c4c4'}
                    alt="option-dots"
                  />
                  <CommonDropDown
                    dropItem={
                      isAgreementCreator
                        ? dropDownOptionsCreator
                        : dropDownOption
                    }
                    active={optionsOpen}
                    objectKey="label"
                    onClick={(item) => {
                      handleOptionsClick(item);
                    }}
                    className="dropdown-container-request"
                    top="30px"
                    right="-30px"
                  />
                </div>
              )}
            </div>
          </div>
          <p className="user-message">{userData?.message}</p>
          <div className="post-attachment">
            {userData?.attachments ? (
              <div className="img-wrapper">
                <div className="img-container">
                  <img
                    src={checkForFileType(getFileName(userData?.attachments))}
                    alt="word"
                  />
                </div>
                <a
                  className="img-text"
                  href={userData?.attachments}
                  target="_blank"
                  rel="noreferrer">
                  {getFileName(userData?.attachments)}
                </a>
              </div>
            ) : null}
          </div>
          <div className="user-actions">
            <div className="comment-sub-request-options">
              <Icon
                name="comment-btn"
                color={isCommentSectionOpen ? '#219fff' : '#929DAF'}
                onClick={() => setIsCommentSectionOpen((prev) => !prev)}
              />
              <CustomButton
                variant="textBtn"
                color="themeSecondary"
                className="PostCardTasksBtn"
                onClick={showHideSubRequest}>
                <Icon
                  name={showSubRequests ? 'eye-slash' : 'eye-2'}
                  size={20}
                  color="#219fff"
                />
                {showSubRequests ? 'Hide Sub-request' : 'Show Sub-request'}
              </CustomButton>
            </div>

            {userData?.dueDate && (
              <p className="due-date">
                Due by:{' '}
                <span className="date">
                  {dayjs(userData?.dueDate).format('DD MMM YYYY')}
                </span>
              </p>
            )}
          </div>
          <div>
            {isCommentSectionOpen && (
              <RequestComment
                comments={userData?.comments}
                postComment={(data) =>
                  OnComment({ message: data, id: userData?.requestId })
                }
              />
            )}
          </div>
          {popUpState?.isOpen && (
            <ApproveOrRejectRequestDocsPopUp
              type={popUpState?.type}
              onClose={() => handlePopUp()}
              onStatusUpdate={() => statusUpdate()}
            />
          )}
        </div>
        <div className="sub-request-wrapper">
          {showSubRequests && (
            <div className="sub-requests">
              {subRequests.length > 0 ? (
                subRequests.map((item, index) => (
                  <SubRequestCard
                    getAgreementRequests={getAgreementRequests}
                    subRequestData={item}
                    key={index}
                    agreementMemberUserId={userData?.agreementMemberUserId}
                  />
                ))
              ) : (
                <p className="no-data">No Sub-request Available!</p>
              )}
            </div>
          )}
        </div>
        {isAddSubRequestPopUpOpen ? (
          <ModalPopup onClose={onPopupClose} title="Add sub-request">
            <Textarea
              required
              label={`Message`}
              placeholder={'Enter your message here'}
              rows={4}
              value={state.message}
              onChange={(e) => onChangeHandler('message', e.target.value)}
            />
            <div className="file-upload">
              <span className="text-style">Add Attachment</span>
              <CustomFileUploadButton
                borderWidth="0px 0px 1px 0px"
                borderStyle="solid"
                borderColor="#219FFF"
                background="none"
                fontFamily="'Poppins', 'Open Sans'"
                fontSize="15px"
                fontWeight="500"
                textColor="#219FFF"
                handleFile={(file) => attachmentsHandler(file)}
                label="Add"
                iconColor="#219FFF"
                iconName="add"
                iconSize="18"
                isMultiple={true}
              />
            </div>
            <div className="post-attachment">
              {attachment.name ? (
                <div className="img-wrapper">
                  <div className="img-container">
                    {checkForFileType(attachment.name) === 'others' ? (
                      <Icon name="attachment" />
                    ) : (
                      <img
                        src={checkForFileType(attachment.name)}
                        alt="attachment"
                      />
                    )}
                  </div>
                  <span className="img-text">{attachment.name}</span>
                  <span
                    className="remove-icon"
                    onClick={() => removeAttachmentsHandler(attachment)}>
                    <Icon color="rgba(146, 157, 175, 1)" name="small-close" />
                  </span>
                </div>
              ) : null}
            </div>
            <div className="save-btn">
              <CustomButton
                disabled={!state.message}
                label="Save"
                onClick={createSubRequest}
              />
            </div>
          </ModalPopup>
        ) : null}
      </RequestMessageCardWrapper>
    </>
  );
};

const ApproveOrRejectRequestDocsPopUp = (props) => {
  const { type, onClose, onStatusUpdate } = props;

  return (
    <ModalPopup
      open
      hasTitle
      title={type === 'approve' ? 'Approve Document' : 'Reject Document'}
      onClose={() => onClose()}>
      <div className="aprroveRejectPopUp">
        {type === 'approve' ? (
          <p>
            You are going to approve this document.
            <br /> Do you want to continue?
          </p>
        ) : (
          <p>
            You are going to reject this document.
            <br /> Do you want to continue?
          </p>
        )}
        <div className="btns">
          <CustomButton
            customPadding="16px 38px"
            variant="textBtn"
            label="No"
            onClick={() => onClose()}
          />
          <CustomButton
            customPadding="16px 38px"
            label="Yes"
            onClick={() => onStatusUpdate()}
          />
        </div>
      </div>
    </ModalPopup>
  );
};
