import React from 'react';
import { AgreementFilterWrapper } from './styles';
import { CustomButton, DropDown } from 'components';

export const AgreementFilter = ({
  filterTitle = 'Filter',
  projectList = [],
  filterOnSavehandler = () => {},
  filterOnResethandler = () => {},
}) => {
  const [currentProject, setCurrentProject] = React.useState('');

  return (
    <AgreementFilterWrapper>
      <div className="filter-title-wrapper">
        <div className="filter-title">{filterTitle}</div>
      </div>
      <DropDown
        items={projectList}
        objectKey="label"
        placeholder={'Select Project'}
        onClick={(value) => setCurrentProject(value)}
        value={currentProject}
      />
      <div className="btn-wrapper">
        <CustomButton
          className="w-100"
          customPadding="12px"
          label="Apply"
          color="themeSecondary"
          onClick={() => filterOnSavehandler(currentProject?.id)}
        />
        <CustomButton
          className="w-100"
          customPadding="12px"
          label="Reset"
          color="themeSecondary"
          variant="outline"
          onClick={() => filterOnResethandler()}
        />
      </div>
    </AgreementFilterWrapper>
  );
};
