import React from 'react';
import { YourApplicationWrapper } from './style';
import CollaborationApplication from './Components/CollaborationApplication';
import BiddingApplication from './Components/BiddingApplication';
import JobApplication from './Components/JobApplication';
export default function YourApplication(props) {
  const { details, questions, applicantDocuments, agreementType } = props;
  return (
    <YourApplicationWrapper>
      {agreementType === 'COLLABORATION' ? (
        <CollaborationApplication
          questions={questions}
          applicantDocuments={applicantDocuments}
        />
      ) : agreementType === 'BIDDING' ? (
        <BiddingApplication
          questions={questions}
          applicantDocuments={applicantDocuments}
        />
      ) : (
        <JobApplication details={details} questions={questions} />
      )}
    </YourApplicationWrapper>
  );
}
