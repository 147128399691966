import styled from 'styled-components';

const CloseDealModalWrapper = styled.div`
  padding: 12px 18px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #404f67;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .btns-group {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
  .steps-p {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
  }
`;

export default CloseDealModalWrapper;
