import React, { useState } from 'react';
import { SubRequestCardWrapper } from './style';
import image from '../../../../../assests/images/bgImgPublic.png';
import Icon from 'utlils/Icons/Icon';
import RequestComment from '../RequestComment';
import { getProfileText } from 'utlils/utils';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import {
  ACKNOWLEDGE_SUB_REQUEST,
  CREATE_NEW_SUB_REQUEST_COMMENT,
  useAxiosWrapper,
} from 'services';
import tokenService from 'services/token.service';
import { ReactComponent as ThreeDots } from 'assests/images/three-dots.svg';
import { checkForFileType } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import CommonDropDown from 'pages/enterpriseDashboard/components/commonDropDown';
import { ModalPopup, Tooltip } from 'components';
const OPTION_LIST = [{ id: 1, label: 'Raise concern' }];

export default function SubRequestCard(props) {
  const { subRequestData, getAgreementRequests, agreementMemberUserId } = props;
  const [openConcernPopup, setOpenConcernPopup] = useState(false);
  const [optionsOpen, setOptionOpen] = useState(false);
  const [isSubCommentOpen, setIsSubCommentOpen] = useState(false);
  const [showMarkAsAcknowledge, setShowMarkAsAcknowledge] = useState(false);
  const [fnWrapper] = useAxiosWrapper();
  const getFileName = (file) => {
    const setFileName = file?.split('/');
    return setFileName[setFileName?.length - 1];
  };

  const onSubRequestComment = async (data) => {
    let userId = tokenService.getSession()?.id;
    const response = await fnWrapper({
      url: `${CREATE_NEW_SUB_REQUEST_COMMENT}`,
      payload: {
        message: data?.message,
        agreementSubRequestId: data?.id,
        userId,
      },
      method: 'POST',
      type: 'CREATE_NEW_AGREEMENT_REQUEST_COMMENT',
    });
    if (response?.data?.statusCode === 200) {
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      getAgreementRequests();
    }
  };
  const onAcknowledgeSubRequest = async () => {
    const response = await fnWrapper({
      url: `${ACKNOWLEDGE_SUB_REQUEST}/${subRequestData?.id}`,
      payload: {
        status: 'ACKNOWLEDGED',
      },
      method: 'PUT',
      type: 'ACKNOWLEDGE_SUB_REQUEST',
    });
    if (response?.data?.statusCode === 200) {
      toast.success('Acknowledged successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      getAgreementRequests();
    }
  };

  function handleOptionsClick(item) {
    switch (item.id) {
      case 1:
        setOpenConcernPopup(true);
        break;
      default:
        break;
    }
  }
  return (
    <SubRequestCardWrapper>
      <div className="user-info">
        <div className="details">
          <div className="profile-round-img">
            {subRequestData?.user?.individual?.profilePicture ? (
              <img className="prof-img" src={image} alt="user" />
            ) : (
              <span className="prof-txt">
                {getProfileText(subRequestData?.user?.individual?.name)}
              </span>
            )}
          </div>
          <div className="user-details">
            <p className="user-name">
              {subRequestData?.user?.individual?.name}
            </p>
            <p className="time">
              {dayjs(subRequestData?.createdAt).format('DD MMM YYYY')}
            </p>
          </div>
        </div>
        <div className="options">
          <div
            className="three-dot-option"
            onClick={() => setOptionOpen(!optionsOpen)}>
            <ThreeDots
              color={optionsOpen ? '#219fff' : '#c4c4c4'}
              alt="option-dots"
            />
            <CommonDropDown
              dropItem={OPTION_LIST}
              active={optionsOpen}
              objectKey="label"
              onClick={(item) => {
                handleOptionsClick(item);
              }}
              top="35px"
              right="-38px"
            />
          </div>
          {openConcernPopup && (
            <ModalPopup
              title={'Raise concern'}
              onClose={() => setOpenConcernPopup(false)}>
              <p className="concern-text">
                Please comment below to raise any concerns you may have.
              </p>
            </ModalPopup>
          )}
        </div>
      </div>
      <p className="user-message">{subRequestData?.message}</p>
      <div className="post-attachment">
        {subRequestData?.attachments ? (
          <div className="img-wrapper">
            <div className="img-container">
              <img
                src={checkForFileType(getFileName(subRequestData?.attachments))}
                alt="word"
              />
            </div>
            <a
              className="img-text"
              href={subRequestData?.attachments}
              target="_blank"
              rel="noreferrer">
              {getFileName(subRequestData?.attachments)}
            </a>
          </div>
        ) : null}
      </div>
      <div className="comments-acknowledge">
        <div className="comment-sub-request-options">
          <Icon
            name="comment-btn"
            color={isSubCommentOpen ? '#219fff' : '#929DAF'}
            onClick={() => setIsSubCommentOpen((prev) => !prev)}
          />
        </div>
        {subRequestData?.status === 'ACKNOWLEDGED' ? (
          <div
            className="acknowledge"
            onMouseEnter={() => setShowMarkAsAcknowledge(true)}
            onMouseLeave={() => setShowMarkAsAcknowledge(false)}>
            {showMarkAsAcknowledge && (
              <Tooltip
                open
                placement={'top'}
                content={<div>Acknowledged</div>}></Tooltip>
            )}

            <Icon name="acknowledged" />
          </div>
        ) : (
          <>
            {
              <div
                className="acknowledge"
                onMouseEnter={() => setShowMarkAsAcknowledge(true)}
                onMouseLeave={() => setShowMarkAsAcknowledge(false)}
                onClick={
                  tokenService.getSession()?.id === agreementMemberUserId
                    ? onAcknowledgeSubRequest
                    : ''
                }>
                {showMarkAsAcknowledge && (
                  <Tooltip
                    open
                    placement={'top'}
                    content={
                      <div>
                        {tokenService.getSession()?.id === agreementMemberUserId
                          ? 'Mark as acknowledged'
                          : 'Awaiting acknowledgement'}
                      </div>
                    }></Tooltip>
                )}
                <Icon
                  disabled={
                    tokenService.getSession()?.id !== agreementMemberUserId
                  }
                  color={showMarkAsAcknowledge ? '#219FFF' : '#404F67'}
                  name="acknowledge"
                />
              </div>
            }
          </>
        )}
      </div>
      <div>
        {isSubCommentOpen && (
          <RequestComment
            comments={subRequestData?.agreementSubRequestComment}
            postComment={(data) =>
              onSubRequestComment({
                message: data,
                id: subRequestData?.id,
              })
            }
          />
        )}
      </div>
    </SubRequestCardWrapper>
  );
}
