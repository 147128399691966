import styled from 'styled-components';
export const SubRequestCardWrapper = styled.div`
  border: 1px solid #c4c4c4;
  margin: 0px 20px 20px 20px;
  border-radius: 20px;
  padding: 14px 16px;
  & .user-info {
    display: flex;
    justify-content: space-between;
    & .details {
      display: flex;
      gap: 8px;
      .user-details {
        display: flex;
        flex-direction: column;
        gap: 0px;
        line-height: 10px;
        .user-name {
          font-size: 16px;
          font-weight: 500;
          color: #040d20;
        }
        .time {
          font-size: 14px;
          font-weight: 400;
          color: #929daf;
        }
      }
      .profile-round-img {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        place-content: center;
        background-color: #d6f3f9;
        .prof-img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        .prof-txt {
          font-size: 18px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    & .options {
      & .three-dot-option {
        cursor: pointer;
        position: relative;
      }
      & .concern-text {
        font-size: 16px;
        display: grid;
        place-content: center;
        font-family: 'Poppins';
      }
    }
  }
  & .user-message {
    font-size: 14px;
    font-weight: 400;
    line-height: 21.75px;
    color: #404f67;
  }
  & .comments-acknowledge {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .acknowledge {
      display: flex;
      gap: 10px;
    }
  }
`;
