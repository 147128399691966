import styled from 'styled-components';
export const BiddingApplicationWrapper = styled.div`
  & .questions {
    & .question-heading {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 5px;
    }
    & .answer {
      margin-top: 20px;
      font-weight: 400;
      font-size: 14px;
      color: rgba(64, 79, 103, 0.7);
      padding: 20px;
      background-color: rgba(248, 248, 248, 1);
      border-radius: 16px;
    }
  }
  & .your-documents {
    margin-top: 30px;
    & .your-documents-heading {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 5px;
    }
    & .link {
      width: 100%;
      & .link-content-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  & .no-data-available {
    display: grid;
    padding: 20px 0px;
    place-content: center;
  }
`;
