import styled from 'styled-components';

export const HomeNavWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  & .homenav {
    position: relative;
    padding: 10px 5%;
    & .homenav_logo {
      width: 150px;
    }
    @media (max-width: 991px) {
      &_logo {
        width: 100px;
      }
      & .navbar-collapse {
        justify-content: space-between;
        position: absolute;
        z-index: 100;
        background-color: #ffffff;
        width: 100%;
        top: 100px;
        left: 0;
        padding-left: 10px;
      }
    }
    @media (min-width: 992px) {
      & .navbar-collapse {
        display: flex !important;
        justify-content: space-between;
        margin: 0 30px;
      }
    }
    & .homenav-items {
      display: flex;
      align-items: center;
      gap: 40px;
      @media (max-width: 1199px) {
        gap: 20px;
      }
      @media (max-width: 992px) {
        display: grid;
        align-items: center;
        gap: 16px;
        margin: 16px;
      }
      &_image-style {
        width: auto;
        height: 45px;
      }
      &_menu-item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        & .option-wrapper {
          width: max-content;
        }
        &_list {
          font-size: 18px;
          font-weight: 500;
          color: #404f67;
          cursor: pointer;
          &_active {
            color: #219fff !important;
            &::after {
              content: '';
              position: absolute;
              bottom: -5px;
              left: 30%;
              width: 40%;
              margin: auto;
              border-bottom: 2px solid #219fff;
            }
          }
          & .dropdown-icon {
            position: relative;
            top: 2px;
            left: 6px;
          }

          & .dropdown-icon.rotate-icon-clock {
            color: #219fff !important;
            transform: rotate(180deg);
            transition: 300ms transform;
          }

          &:hover {
            color: #219fff !important;
            & .nmw_menu-item_label {
              border-bottom: 2px solid #219fff;
            }
            & .dropdown-icon {
              color: #219fff !important;
            }
          }
        }
      }
    }
    & .left-half {
      display: flex;
      align-items: center;
      gap: 40px;
      @media (max-width: 1299px) {
        gap: 30px;
      }
      @media (max-width: 992px) {
        display: grid;
        gap: 16px;
        margin: 16px;
      }
      & .invest-link {
        font-weight: 400;
        text-align: center;
        font-size: 18px;
        line-height: 27px;
        color: #219fff;
        cursor: pointer;
      }
    }
  }
`;
