import { useState } from 'react';
import SendIcon from 'assests/images/send.png';
import { isMobile } from 'utlils';
import RequestCommentWrapper from './styles';
import { ScrollableDiv } from 'components';
import Icon from 'utlils/Icons/Icon';

const RequestComment = (props) => {
  const { postComment = () => {}, comments = [], isOpen = 'true' } = props;
  const [currentComment, setCurrentComment] = useState('');

  const postAComment = () => {
    postComment(currentComment);
    setCurrentComment('');
  };

  return (
    <RequestCommentWrapper>
      {isOpen && (
        <div className="comments-wrapper">
          <div className="comment-input_area">
            <div className="comment-input_box">
              <input
                value={currentComment}
                onChange={(e) => setCurrentComment(e?.target?.value)}
                className="comment-input"
                placeholder={
                  isMobile() ? 'Comment here..' : 'Type your comment here'
                }
              />
              <img
                src={SendIcon}
                alt="send"
                className="send-comment-icon"
                onClick={() => postAComment()}
              />
            </div>
          </div>
          <ScrollableDiv scrollOnHeight="18dvh">
            {comments?.length > 0 ? (
              comments?.map((comment, index) => {
                return (
                  <div className="display-comment-wrapper" key={index}>
                    <div className="display-comment-header">
                      {comment?.user?.individual?.profilePicture ? (
                        <img
                          src={comment?.user?.individual?.profilePicture}
                          alt="pfp"
                          className="commenter-profile-pic"
                        />
                      ) : (
                        <div className="pfp-text-circle">
                          <Icon
                            name="user-icon"
                            className="user-icon-class"
                            size={26}
                            color="#292D32"
                          />
                        </div>
                      )}
                      <div className="commenter-info">
                        <span className="commenter-name">
                          {comment?.user?.individual?.name}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div className="display-comment-content">
                      {comment?.message}
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className="text-center"
                style={{ fontSize: '14px', color: '#929daf' }}>
                No comments. Post the first comment now!
              </div>
            )}
          </ScrollableDiv>
        </div>
      )}
    </RequestCommentWrapper>
  );
};

export default RequestComment;
