export const INDIVIDUAL_DASHBOARD_NAV_LIST = (role) => [
  {
    id: 'home',
    title: 'Home',
    url:
      role === 'INDIVIDUAL'
        ? '/individual/profile/info/basic_details'
        : '/enterprise/profile/view/details',
    isDropdown: false,
  },
  role === 'INDIVIDUAL' && {
    id: 'explore',
    title: 'Explore',
    isDropdown: true,
    dropDownItems: [
      {
        id: 'explore_teams',
        title: 'Explore Teams',
        url: '/explore/teams/search-team',
      },
      {
        id: 'explore_projects',
        title: 'Explore Projects',
        url: '/explore/projects/search-project',
      },
      {
        id: 'explore_jobs',
        title: 'Explore Jobs',
        url: '/explore/jobs/search-job',
      },
      {
        id: 'explore_networks',
        title: 'Explore Networks',
        url: '/explore/networks/search-people',
      },
      {
        id: 'explore_groups',
        title: 'Explore Groups',
        url: '/explore/groups/search-groups',
      },
    ],
  },
  {
    id: 'messages',
    title: 'Messages',
    url:
      role === 'INDIVIDUAL'
        ? '/individual/messages/conversation/all'
        : '/enterprise/messages/conversation/all',
    isDropdown: false,
    isIcon: true,
    iconName: 'message',
    iconColor: '#2AC173',
  },
];

export const IndividualProfileOptions = [
  {
    type: 'basic_details',
    iconName: 'user',
    title: 'Basic Details',
  },
  {
    type: 'education',
    iconName: 'education',
    title: 'Education',
  },
  {
    type: 'professional_details',
    iconName: 'professional',
    title: 'Professional Details',
  },
  {
    type: 'skills_and_technology',
    iconName: 'technology',
    title: 'Skills/Technology',
  },
  {
    type: 'achievements',
    iconName: 'achievements',
    title: 'Achievements',
  },
  {
    type: 'license_certifications',
    iconName: 'license',
    title: 'License & Certifications ',
  },
];

export const INDIVIDUAL_PROFILE_OPTIONS = [
  {
    id: 'basic_details',
    icon: { name: 'user' },
    title: 'Basic Details',
  },
  {
    id: 'education',
    icon: { name: 'education' },
    title: 'Education',
  },
  {
    id: 'professional_details',
    icon: { name: 'professional' },
    title: 'Professional Details',
  },
  {
    id: 'skills_and_technology',
    icon: { name: 'technology' },
    title: 'Skills/Technology',
  },
  {
    id: 'achievements',
    icon: { name: 'achievements' },
    title: 'Achievements',
  },
  {
    id: 'license_certifications',
    icon: { name: 'license' },
    title: 'License & Certifications ',
  },
];

export const individualInfoData = [
  {
    type: 'info',
    iconName: 'info',
    title: 'Info',
    url: '/individual/profile/info/basic_details',
    hasSubMenu: false,
  },
  {
    type: 'personal',
    iconName: 'user',
    title: 'Personal',
    url: '/individual/profile/personal/teams',
    hasSubMenu: false,
  },
  {
    type: 'organization',
    iconName: 'project',
    title: 'Organization',
    url: '',
    hasSubMenu: true,
    elements: [
      {
        type: 'organization/enterprise',
        title: 'Enterprise',
        url: '/individual/profile/organization/enterprise',
      },
      {
        type: 'organization/institution',
        title: 'Institution',
        url: '/individual/profile/organization/institution',
      },
    ],
  },
  {
    type: 'groups',
    iconName: 'people',
    title: 'Groups',
    url: '/individual/profile/groups',
    hasSubMenu: false,
  },
];
export const rightSidebarData = [
  {
    type: 'to-do',
    url: '/individual/task-management',
    iconName: 'deadlines',
    title: 'To Do',
    hasSubMenu: false,
  },
  {
    type: 'feeds',
    url: '/individual/profile/feeds',
    iconName: 'publications',
    title: 'Feeds',
    hasSubMenu: false,
  },
  {
    type: 'applications',
    iconName: 'applications',
    title: 'Applications',
    hasSubMenu: false,
  },
  {
    type: 'invitations',
    iconName: 'invitations',
    title: 'Invitations',
    hasSubMenu: false,
  },
  {
    type: 'reference',
    iconName: 'reference',
    title: 'Reference',
    hasSubMenu: false,
  },
  {
    type: 'agreements',
    iconName: 'agreements',
    url: '/individual/profile/agreements/your-agreements',
    title: 'Agreements',
    hasSubMenu: true,
    elements: [
      {
        type: 'agreements/your-agreements',
        title: 'Your Agreements',
        url: '/individual/profile/agreements/your-agreements/collab-agreements',
      },
      {
        type: 'agreements/other-agreements',
        title: 'Other Agreements',
        url: '/individual/profile/agreements/other-agreements/collab-agreements',
      },
    ],
  },
];
export const progressDataCreatePersonalTeam = [
  {
    id: 1,
    title: 'Information',
    url: '/individual/profile/personal/teams/create/information',
  },
  {
    id: 2,
    title: 'Member',
    url: '/individual/profile/personal/teams/create/inviteMember',
  },
  {
    id: 3,
    title: 'Done',
    url: '/individual/profile/personal/teams/create/success',
  },
];

export const AttachmentsTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/ms-excel',
  'application/msword',
  'application/pdf',
  'application/vnd.ms-excel',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const VideoAttachmentsTypes = [
  'video/mp4',
  'video/mpeg',
  'video/quicktime',
  'video/x-ms-wmv',
];

export const TYPES = {
  TEAM_DASHBOARD: 'TEAM_DASHBOARD',
  PERSONAL: 'PERSONAL',
  ENTERPRISE: 'ENTERPRISE',
  ENTERPRISE_DASHBOARD: 'ENTERPRISE_DASHBOARD',
};
