import React from 'react';
import { CollaborationDetailsWrapper } from './style';
import { CustomButton } from 'components';
import Icon from 'utlils/Icons/Icon';

export default function CollaborationDetails(props) {
  const { details, documents } = props;
  return (
    <CollaborationDetailsWrapper>
      <div className="about-heading">About this Collaboration</div>
      <div className="about">
        {details?.appliedCollaboration?.collaborationDescription}
      </div>
      <br />
      <div className="offering-heading">Collaboration offerings</div>
      <div className="offering">
        {details?.appliedCollaboration?.collaborationOfferings}
      </div>
      <div className="details">
        <div className="data">
          <div className="data-heading">Collaboration type</div>
          <CustomButton
            variant="light"
            color="themeSecondary"
            label={details?.appliedCollaboration?.collaborationType}
          />
        </div>
        <div className="data">
          <div className="data-heading">Collaboration category</div>
          <CustomButton
            variant="light"
            color="themeSecondary"
            label={details?.appliedCollaboration?.collaborationCategory}
          />
        </div>
        <div className="data">
          <div className="data-heading">Goal of collaboration</div>
          <CustomButton
            variant="light"
            color="themeSecondary"
            label={details?.appliedCollaboration?.collaborationGoal}
          />
        </div>
      </div>
      <div className="requirements">
        <div className="requirements-heading">Collaboration requirements</div>
        <div className="requirement-data">
          <div className="info">
            The following are some of the requirements for effective
            collaboration:
          </div>
          <div className="points">
            {details?.appliedCollaboration?.collaborationRequirements}
          </div>
        </div>
      </div>
      <div className="documents">
        <div className="documents-heading">Rule and documents</div>
        {documents &&
          documents.length > 0 &&
          documents.map((document, index) => (
            <a href={document} className="link" key={index + 1}>
              <div className="link-content-wrapper">
                <div className="icon-name">
                  <Icon name="attachment" />
                  <span>
                    {document.split('/').slice(-1)[0].split('_')[0]}.
                    {document.split('.').slice(-1)}
                  </span>
                </div>
                <CustomButton variant="textBtn">
                  Download
                  <Icon name="download-box" color="#0ABD00" size={16} />
                </CustomButton>
              </div>
            </a>
          ))}
      </div>
    </CollaborationDetailsWrapper>
  );
}
