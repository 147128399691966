import React, { useCallback, useState, useEffect } from 'react';
import { YourAgreementsWrapper } from './style';
import { startCase } from 'lodash';
import dayjs from 'dayjs';
import {
  CustomButton,
  CustomDropDown,
  DropDown,
  ModalPopup,
  TabGroup,
} from 'components';
import Icon from 'utlils/Icons/Icon';
import {
  AgreementListAction,
  agreementTypeSetter,
  StatusComponent,
} from './helper';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FETCH_EXISTING_PROJECTS,
  GET_PROJECT_COLLABORATION,
  CREATE_NEW_AGREEMENT,
  FETCH_AGREEMENTS_LIST,
  GET_PROJECT_BIDDING_LIST,
  GET_TEAMS_BY_USER_ID,
  FETCH_PROJECTS_BASED_ON_TEAM,
  FETCH_JOBS_BASED_ON_PROJECT_ID,
  useAxiosWrapper,
} from 'services';
import { AgreementFilter } from 'pages/IndividualDashboard/components/agreementFilter';
import tokenService from 'services/token.service';
import AgreementsTable from './AgreementsTable';
import {
  AGREEMENT_STATUS_LIST,
  DEFAULT_STATE,
  AGREEMENT_OPTIONS,
  TABS,
} from './constants';
import { toast } from 'react-toastify';

export default function YourAgreements() {
  const { agreementType } = useParams();

  const DEFAULT_FILTER_STATE = {
    status: AGREEMENT_STATUS_LIST[0],
    projectId: '',
    type: agreementTypeSetter(agreementType),
  };

  const [fnWrapper] = useAxiosWrapper();
  const [projectList, setProjectList] = useState([]);
  const [collabList, setCollabList] = useState([]);
  const [biddingList, setBiddingList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [filterState, setFilterState] = useState(DEFAULT_FILTER_STATE);
  const [agreementsList, setAgreementsList] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(
    startCase(agreementType.replace('-', ' '))
  );
  const getAgreementType = (value) => {
    let type =
      value === TABS[0]
        ? 'COLLABORATION'
        : value === TABS[1]
        ? 'BIDDING'
        : value === TABS[2]
        ? 'JOB'
        : '';
    return type;
  };
  const navigate = useNavigate();
  const tabSwitchHandler = (value) => {
    let type = getAgreementType(value);

    setFilterState((prev) => ({
      ...prev,
      type,
    }));
    setActiveTab(value);
    let tab = value.toLowerCase().replaceAll(' ', '-');
    setIsFilterOpen(false);
    navigate(`/individual/profile/agreements/your-agreements/${tab}`);
  };
  const [openCreateNewAgreementPopup, setOpenCreateNewAgreementPopup] =
    useState(false);
  const [agreementState, setAgreementState] = useState(DEFAULT_STATE);
  const onChangeHandler = (field = '', value = null) => {
    setAgreementState({
      ...agreementState,
      [field]: value,
    });
  };
  const onClosePopup = () => {
    setOpenCreateNewAgreementPopup(false);
    setAgreementState(DEFAULT_STATE);
  };

  const getAgreementsList = useCallback(async () => {
    setIsLoading(true);
    const userId = tokenService.getSession()?.id;
    const url = `${FETCH_AGREEMENTS_LIST}/${userId}?status=${filterState?.status?.value}&projectId=${filterState?.projectId}&type=${filterState?.type}`;

    const res = await fnWrapper({
      url: url,
      method: 'GET',
      type: 'FETCH_PROJECTS_LIST',
    });

    if (res?.data?.statusCode === 200) {
      const agreementsListData = [];
      res?.data?.data.forEach((item) => {
        let agreementData = {
          project_name: item?.project?.title,
          created_on: dayjs(item.createdAt).format('DD MMM YYYY'),
          status: <StatusComponent status={item?.status} />,
          action: (
            <AgreementListAction agreementType={agreementType} id={item?.id} />
          ),
        };
        if (item?.type === 'COLLABORATION') {
          agreementData['type'] = item?.collaboration?.collaborationType;
        } else if (item?.type === 'BIDDING') {
          agreementData['type'] = 'BIDDING';
        } else if (item?.type === 'JOB') {
          agreementData['type'] = 'JOB';
        }
        agreementsListData.push(agreementData);
      });
      setAgreementsList(agreementsListData);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  const getProjectList = useCallback(async () => {
    const url = `${FETCH_EXISTING_PROJECTS}?type=personal`;

    const res = await fnWrapper({
      url: url,
      method: 'GET',
      type: 'FETCH_PROJECTS_LIST',
    });

    if (res?.data?.statusCode === 200) {
      const projectTableList = [];
      res?.data?.data.forEach((item) => {
        const newProjectData = {
          id: `${item.id}`,
          label: `${item.title}`,
          value: `${item.id}`,
          projectStatus: item.projectStatus,
        };
        projectTableList.push(newProjectData);
      });
      setProjectList(
        projectTableList.filter((data) => data.projectStatus === 'PUBLISHED')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProjectListBasedOnTeam = useCallback(async (value) => {
    const url = `${FETCH_PROJECTS_BASED_ON_TEAM}/${value}`;

    const res = await fnWrapper({
      url: url,
      method: 'GET',
      type: 'FETCH_PROJECTS_BASED_ON_TEAM',
    });

    if (res?.data?.statusCode === 200) {
      const projectTableList = [];
      res?.data?.data.forEach((item) => {
        const newProjectData = {
          id: `${item?.projects?.id}`,
          label: `${item?.projects?.title}`,
          value: `${item?.projects?.id}`,
          projectStatus: item?.projects?.projectStatus,
        };
        projectTableList.push(newProjectData);
      });
      setProjectList(
        projectTableList.filter((data) => data.projectStatus === 'PUBLISHED')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTeamsList = useCallback(async () => {
    const userId = tokenService.getSession()?.id;
    const url = `${GET_TEAMS_BY_USER_ID}/${userId}?type=personal`;

    const res = await fnWrapper({
      url: url,
      method: 'GET',
      type: 'GET_TEAMS_BY_USER_ID',
    });

    if (res?.data?.statusCode === 200) {
      const teamsList = [];
      res?.data?.data.forEach((item) => {
        const newTeamData = {
          id: `${item.id}`,
          label: `${item.teamName}`,
          value: `${item.id}`,
        };
        teamsList.push(newTeamData);
      });
      setTeamList(teamsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCollabList = useCallback(async (projectId) => {
    const url = `${GET_PROJECT_COLLABORATION}?isOpen=true&projectId=${projectId}`;

    const res = await fnWrapper({
      url: url,
      method: 'GET',
      type: 'GET_PROJECT_COLLABORATION',
    });

    if (res?.data?.statusCode === 200) {
      const collaborationList = [];
      res?.data?.data.forEach((item) => {
        const newCollabData = {
          id: `${item.id}`,
          label: `${item.collaborationType}`,
          value: `${item.id}`,
        };
        collaborationList.push(newCollabData);
      });

      setCollabList(collaborationList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getJobsList = useCallback(async (projectId) => {
    const url = `${FETCH_JOBS_BASED_ON_PROJECT_ID}/${projectId}`;

    const res = await fnWrapper({
      url: url,
      method: 'GET',
      type: 'FETCH_JOBS_BASED_ON_PROJECT_ID',
    });

    if (res?.data?.statusCode === 200) {
      const jobsList = [];
      res?.data?.data.forEach((item) => {
        const newJobData = {
          id: `${item.id}`,
          label: `${item.title}`,
          value: `${item.id}`,
        };
        jobsList.push(newJobData);
      });

      setJobList(jobsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBiddingList = useCallback(async (projectId) => {
    const url = `${GET_PROJECT_BIDDING_LIST}/${projectId}?isOpen=true`;

    const res = await fnWrapper({
      url: url,
      method: 'GET',
      type: 'GET_PROJECT_BIDDING_LIST',
    });

    if (res?.data?.statusCode === 200) {
      const biddingList = [];
      res?.data?.data.forEach((item) => {
        const newCollabData = {
          id: `${item.id}`,
          label: `${item.biddingType}`,
          value: `${item.id}`,
        };
        biddingList.push(newCollabData);
      });

      setBiddingList(biddingList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = async () => {
    let payload = {
      collaborationId: null,
      projectId: agreementState?.selectedProject?.id,
      biddingId: null,
      jobId: null,
      teamId: null,
    };
    if (agreementType === 'collab-agreements') {
      payload['agreementType'] = 'COLLABORATION';
      payload['collaborationId'] = agreementState?.selectCollaboration?.id;
    } else if (agreementType === 'bid-agreements') {
      payload['agreementType'] = 'BIDDING';
      payload['biddingId'] = agreementState?.selectBid?.id;
    } else if (agreementType === 'job-agreements') {
      payload['agreementType'] = 'JOB';
      payload['teamId'] = agreementState?.selectTeam?.id;
      payload['jobId'] = agreementState?.selectJob?.id;
    }
    const res = await fnWrapper({
      url: CREATE_NEW_AGREEMENT,
      method: 'POST',
      payload,
      type: 'CREATE_NEW_AGREEMENT',
    });

    if (res?.data?.statusCode === 200) {
      toast.success(res?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setOpenCreateNewAgreementPopup(false);
      setAgreementState(DEFAULT_STATE);
      getAgreementsList();
    }
  };

  const TeamDropDownSelect = (value = '') => {
    setAgreementState((prev) => ({
      selectTeam: value,
      selectBid: {},
      selectCollaboration: {},
      selectJob: {},
      selectedProject: {},
    }));
    getProjectListBasedOnTeam(value?.id);
  };

  const projectDropDownSelect = (value = '') => {
    setAgreementState((prev) => ({
      ...prev,
      selectBid: {},
      selectCollaboration: {},
      selectJob: {},
      selectedProject: value,
    }));
    if (agreementType === 'collab-agreements') {
      getCollabList(value?.id);
    } else if (agreementType === 'bid-agreements') {
      getBiddingList(value?.id);
    } else if (agreementType === 'job-agreements') {
      getJobsList(value?.id);
      //
    }
  };

  const applyAgreementFilter = (id = '') => {
    setFilterState((prev) => ({
      ...prev,
      projectId: id,
    }));
    getAgreementsList();
  };

  const resetAgreementFilter = () => {
    setFilterState(DEFAULT_FILTER_STATE);
  };

  useEffect(() => {
    getAgreementsList();
  }, [agreementType, filterState, getAgreementsList]);

  useEffect(() => {
    if (agreementType === 'job-agreements') {
      getTeamsList();
    }
    getProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementType]);

  const isCreateButtonDisabled = () => {
    if (agreementType === 'collab-agreements') {
      return (
        !agreementState?.selectedProject?.id ||
        !agreementState?.selectCollaboration?.id
      );
    } else if (agreementType === 'bid-agreements') {
      return (
        !agreementState?.selectedProject?.id || !agreementState?.selectBid?.id
      );
    } else if (agreementType === 'job-agreements') {
      return (
        !agreementState?.selectedProject?.id ||
        !agreementState?.selectTeam?.id ||
        !agreementState?.selectJob?.id
      );
    }
  };

  return (
    <YourAgreementsWrapper>
      <div className="main-wrapper">
        <div className="header-text">
          <p className="heading">Your Agreements</p>
        </div>
        <div className="agreement-container">
          <div className="tab">
            <TabGroup
              tabLists={TABS}
              tabClick={tabSwitchHandler}
              activeTab={activeTab}
            />
          </div>
        </div>
        <div className="actions">
          <CustomButton
            onClick={() => setOpenCreateNewAgreementPopup(true)}
            color="themeSecondary"
            variant="light">
            <Icon name="add" color="#219FFF" size={20} /> New Agreement
          </CustomButton>
          <div className="filters">
            <div>
              <CustomButton
                variant="light"
                color="themeGray"
                onClick={() => setIsFilterOpen((prev) => !prev)}>
                <Icon name="filter" />
              </CustomButton>
              {isFilterOpen && (
                <AgreementFilter
                  filterOnSavehandler={applyAgreementFilter}
                  filterOnResethandler={resetAgreementFilter}
                  filterTitle="Agreement Filter"
                  projectList={projectList}
                />
              )}
            </div>
            <CustomDropDown
              optionLists={AGREEMENT_STATUS_LIST}
              selectedItem={filterState?.status}
              setSelectedItem={(value) => {
                setFilterState((prev) => ({
                  ...prev,
                  status: value,
                }));
              }}
              width="150px"
              customStyle={{ padding: '0px 10px' }}
              placeholder="Status"
            />
          </div>
        </div>
        <div className="agreement-table">
          {isLoading ? (
            <div className="agreement-list-loader">
              <Icon color="#000" name="loader" size={40} />
              Loading ...
            </div>
          ) : (
            <AgreementsTable agreementsList={agreementsList} />
          )}
        </div>
        {openCreateNewAgreementPopup && (
          <ModalPopup
            open={false}
            onClose={onClosePopup}
            title={'New Agreement'}>
            <DropDown
              disabled
              items={AGREEMENT_OPTIONS}
              objectKey="label"
              label={'Select active collaboration type'}
              placeholder={'Active collaboration type'}
              value={
                agreementType === 'collab-agreements'
                  ? AGREEMENT_OPTIONS[0]
                  : agreementType === 'bid-agreements'
                  ? AGREEMENT_OPTIONS[1]
                  : AGREEMENT_OPTIONS[2]
              }
            />
            {agreementType === 'job-agreements' && (
              <DropDown
                required
                items={teamList}
                objectKey="label"
                label={'Select Team'}
                placeholder={'Select Team'}
                onClick={(value) => TeamDropDownSelect(value)}
                value={agreementState.selectTeam}
              />
            )}
            <DropDown
              required
              disabled={
                agreementType === 'job-agreements' &&
                !agreementState?.selectTeam?.id
              }
              items={projectList}
              objectKey="label"
              label={'Select Project'}
              placeholder={'Select Project'}
              onClick={(value) => projectDropDownSelect(value)}
              value={agreementState.selectedProject}
            />
            {agreementType === 'collab-agreements' && (
              <DropDown
                required
                items={collabList}
                disabled={!agreementState?.selectedProject?.id}
                objectKey="label"
                label={'Select active collaboration type'}
                placeholder={'Active collaboration type'}
                onClick={(value) =>
                  onChangeHandler('selectCollaboration', value)
                }
                value={agreementState?.selectCollaboration}
              />
            )}
            {agreementType === 'bid-agreements' && (
              <DropDown
                required
                disabled={!agreementState?.selectedProject?.id}
                items={biddingList}
                objectKey="label"
                label={'Select active bidding type'}
                placeholder={'Active bidding type'}
                onClick={(value) => onChangeHandler('selectBid', value)}
                value={agreementState.selectBid}
              />
            )}
            {agreementType === 'job-agreements' && (
              <DropDown
                required
                disabled={!agreementState?.selectedProject?.id}
                items={jobList}
                objectKey="label"
                label={'Select active Job post'}
                placeholder={'Select Job Post'}
                onClick={(value) => onChangeHandler('selectJob', value)}
                value={agreementState.selectJob}
              />
            )}
            <div className="save-btn">
              <CustomButton
                disabled={isCreateButtonDisabled()}
                label="Create Agreement"
                color="themeSecondary"
                onClick={submitHandler}
              />
            </div>
          </ModalPopup>
        )}
      </div>
    </YourAgreementsWrapper>
  );
}
