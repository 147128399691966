import styled from 'styled-components';
export const TeamPublicWrapper = styled.div`
  .main-container {
    min-height: 100vh;
    background-color: #f4f7fc;
    & .layout-container {
      padding: 40px 8% 20px 8%;
      & .layout-upper-container {
        display: grid;
        grid-template-columns: auto minmax(420px, 450px);
        grid-gap: 40px;
        @media (max-width: 1299px) {
          display: block;
        }
        & .upper-container-child {
          border-radius: 12px;
          padding: 30px;
          background-color: white;
          @media (max-width: 1299px) {
            margin: 25px 0;
          }
        }
      }
      @media (max-width: 1600px) {
        padding: 40px 6% 20px 6%;
      }
      @media (max-width: 992px) {
        padding: 40px 4% 20px 4%;
      }

      & .layout-lower-container {
        margin-top: 40px;

        display: grid;
        grid-template-columns: minmax(200px, 220px) auto;
        grid-gap: 40px;
        @media (max-width: 1199px) {
          display: block;
        }
        & .sub-container {
          @media (max-width: 1199px) {
            margin: 25px 0;
          }
          & .main-wrapper {
            border-radius: 12px;
            padding: 30px;
            background-color: white;
            position: relative;
            & .heading {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 700;
              font-size: 25px;
              color: #17191d;
              border-bottom: 1px solid #e9eef4;
              padding-bottom: 20px;
              margin-bottom: 55px;

              & span {
                font-weight: 600;
                font-size: 23px;
                color: #404f67;
              }
            }

            & .no-data-class {
              width: 50%;
              margin: 50px auto;
              text-align: center;
              color: #404f67;
            }

            & .projects-list {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              grid-gap: 20px;

              @media (min-width: 900px) and (max-width: 1499px) {
                grid-template-columns: repeat(2, 1fr);
              }

              @media (max-width: 899px) {
                display: block;

                & .team-card-class {
                  margin-top: 12px;
                }
              }
            }

            & .content {
              & .cards-container {
                display: flex;
                flex-wrap: wrap;
                gap: 24px;
                @media (max-width: 699px) {
                  flex-direction: column;
                }
              }
              & .people-cards-container {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 16px;
                @media (max-width: 699px) {
                  grid-template-columns: auto;
                }
              }
              & .opening-cards-container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;

                @media (min-width: 900px) and (max-width: 1499px) {
                  grid-template-columns: repeat(2, 1fr);
                }

                @media (max-width: 899px) {
                  grid-template-columns: 1fr;
                }
              }
              & .no-data-class {
                width: 50%;
                margin: 50px auto;
                text-align: center;
                color: #404f67;
              }
            }

            .content-class {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              padding-bottom: 32px;

              & .content-title {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 21px;
                color: #404f67;
              }

              & .content-value {
                width: 100%;
                min-height: 56px;
                text-align: left;
                padding: 16px;
                background: #f4f7fc;
                color: #404f67;
                border-radius: 10px;
                word-wrap: break-word;
              }
            }
          }
        }
      }
    }
  }
  .radio-btn-class {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    font-size: 16px;
    margin-bottom: 14px;
  }
  .team-blocked-popup {
    text-align: center;
    color: #404f67;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    .blocked-icon {
      width: 200px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 180px;
      border: 20px solid #cb2103;
      color: #cb2103;
      border-radius: 100%;
      margin-bottom: 20px;
    }
    span {
      font-weight: 600;
      color: red;
    }
  }
`;
