import styled from 'styled-components';

const AgreementCommunicationWrapper = styled.div`
  --send-msg-container-height: 50px;
  background-color: white;
  padding: 20px;
  .main-container {
    font-family: Poppins;
    line-height: 37.5px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .nav-and-info {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-content: center;
      .nav-back {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 6px;
        .nav-element {
          height: 100%;
        }
        .title {
          font-size: 25px;
          font-weight: 700;
          vertical-align: middle;
        }
      }
    }
    .send-message {
      display: flex;
      align-items: center;
      flex-direction: row;
      min-height: var(--send-msg-container-height);
      gap: 12px;
      flex-wrap: wrap;
      .image {
        width: 45px;
        height: 45px;
        border-radius: 100%;
      }
      .image-container {
        width: 40px;
        height: var(--send-msg-container-height);
        border-radius: 100%;
        background-color: #f7f6ff;
        overflow: hidden;
      }
      .send-msg-btn {
        cursor: pointer;
        height: var(--send-msg-container-height);
        display: flex;
        align-items: center;
        width: 90%;
        font-size: 15px;
        font-weight: 400;
        line-height: 21.75px;
        background-color: #f7f6ff;
        color: #929daf;
        border-radius: 32px;
        padding: 0 16px;
      }
      .disabled-btn {
        cursor: not-allowed;
      }
    }
    .requests-section {
      position: relative;
      padding: 12px 0px;
      display: flex;
      flex-direction: column;
      gap: 18px;
      line-height: 21.75px;
      font-family: Open Sans;
      min-height: 40vh;
      .no-request {
        align-self: center;
        justify-self: center;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: #404f67;
      }
    }
  }
`;

export default AgreementCommunicationWrapper;
