import React from 'react';
import { JobApplicationWrapper } from './style';

export default function JobApplication(props) {
  const { details, questions } = props;
  return (
    <JobApplicationWrapper>
      <div className="questions">
        {questions &&
          Object.keys(questions)[0] !== '' &&
          Object.keys(questions).map((question) => (
            <>
              <div className="question-heading">{question}</div>
              <div className="answer">{questions[question]}</div>
            </>
          ))}
      </div>
      <br />
      <div className="apply-with-heading">Apply with</div>
      <div className="apply-with">
        {details?.appliedJob?.applyWith &&
          details?.appliedJob?.applyWith.map((list, index) => (
            <span key={index + 1}>
              {list}
              {index !== details?.appliedJob?.applyWith.length - 1 && (
                <span>,&nbsp;</span>
              )}
            </span>
          ))}
      </div>
    </JobApplicationWrapper>
  );
}
