import React, { useEffect, useState } from 'react';
import { AgreementRequestsWrapper } from './style';
import userImage from '../../../../../../assests/images/user.png';
import {
  CustomButton,
  CustomFileUploadButton,
  ModalPopup,
  Textarea,
} from 'components';
import { AttachmentsTypes } from 'pages/IndividualDashboard/constants';
import { toast } from 'react-toastify';
import Icon from 'utlils/Icons/Icon';
import { checkForFileType } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import tokenService from 'services/token.service';
import {
  CREATE_NEW_AGREEMENT_REQUEST,
  CREATE_NEW_AGREEMENT_REQUEST_COMMENT,
  FETCH_AGREEMENT_MEMBER_CONVERSATION,
  UPDATE_AGREEMENT_REQUEST_STATUS,
  useAxiosWrapper,
} from 'services';
import { RequestMessageCard } from 'pages/IndividualDashboard/components/RequestMessageCard';
import { useSearchParams } from 'react-router-dom';
const DEFAULT_STATE = {
  message: '',
};
export default function AgreementRequests(props) {
  const { details, requestStatus } = props;
  const [fnWrapper] = useAxiosWrapper();
  const [searchParam] = useSearchParams();
  const agreementMemberId = searchParam.get('agreementMemberId');
  const loggedInUser = tokenService.getSession();
  const [openRequestPopup, setOpenRequestPupup] = useState(false);
  const [state, setState] = useState(DEFAULT_STATE);
  const [agreementRequestsList, setAgreementRequestsList] = useState([]);
  const [attachment, setAttachment] = useState({});
  const attachmentsHandler = async (files) => {
    if (AttachmentsTypes.includes(files[0].type)) {
      setAttachment(files[0]);
    } else {
      toast.error(`File type of ${files[0].type} is not supported.`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };
  const removeAttachmentsHandler = async (file) => {
    setAttachment({});
  };
  const onChangeHandler = (field = '', value = null) => {
    setState({
      ...state,
      [field]: value,
    });
  };
  const onPopupClose = () => {
    setOpenRequestPupup(false);
    setState(DEFAULT_STATE);
    setAttachment({});
  };

  const createAgreementRequest = async () => {
    let agreementId =
      searchParam.get('type') === 'COLLABORATION'
        ? details?.appliedCollaboration?.collaborationAgreement?.id
        : searchParam.get('type') === 'BIDDING'
        ? details?.appliedBidding?.agreementBidding[0]?.id
        : details?.appliedJob?.jobAgreement[0]?.id;
    let userId = loggedInUser.id;
    let formData = new FormData();
    formData.append('message', state?.message);
    formData.append('agreementId', agreementId);
    formData.append('agreementMemberId', agreementMemberId);
    formData.append('attachments', attachment);
    formData.append('userId', userId);
    const response = await fnWrapper({
      url: CREATE_NEW_AGREEMENT_REQUEST,
      payload: formData,
      method: 'POST',
      type: 'CREATE_NEW_AGREEMENT_REQUEST',
    });
    if (response?.data?.statusCode === 200) {
      toast.success(response?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    setOpenRequestPupup(false);
    getAgreementRequests();
    setState(DEFAULT_STATE);
    setAttachment({});
  };
  const getAgreementRequests = async () => {
    const response = await fnWrapper({
      url: `${FETCH_AGREEMENT_MEMBER_CONVERSATION}/${agreementMemberId}`,
      method: 'GET',
      type: 'FETCH_AGREEMENT_MEMBER_CONVERSATION',
    });

    if (response?.data?.statusCode === 200) {
      let requestList = response?.data?.data?.map((item) => {
        return {
          senderId: item?.userId,
          senderData: item?.user,
          message: item?.message,
          status: item?.status,
          attachments: item?.attachments,
          dueDate: item?.dueDate,
          createdAt: item?.createdAt,
          comments: item?.agreementComment,
          agreementSubRequests: item?.agreementSubRequest,
          requestId: item?.id,
          agreementStatus: item?.agreementMember?.status,
          agreementMemberUserId: item?.agreementMember?.memberUserId,
        };
      });
      setAgreementRequestsList(requestList);
    }
  };
  const updateAgreementRequest = async (data) => {
    const response = await fnWrapper({
      url: `${UPDATE_AGREEMENT_REQUEST_STATUS}/${data?.requestId}`,
      payload: {
        status: data?.status,
      },
      method: 'PUT',
      type: 'UPDATE_AGREEMENT_REQUEST_STATUS',
    });
    if (response?.data?.statusCode === 200) {
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    getAgreementRequests();
  };
  const postRequestComment = async (data) => {
    let userId = tokenService.getSession()?.id;
    const response = await fnWrapper({
      url: `${CREATE_NEW_AGREEMENT_REQUEST_COMMENT}`,
      payload: {
        message: data?.message,
        agreementRequestId: data?.id,
        userId,
      },
      method: 'POST',
      type: 'CREATE_NEW_AGREEMENT_REQUEST_COMMENT',
    });
    if (response?.data?.statusCode === 200) {
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      getAgreementRequests();
    }
  };
  let disabled = !state.message;
  useEffect(() => {
    getAgreementRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let status =
    searchParam.get('type') === 'COLLABORATION'
      ? details?.appliedCollaboration?.collaborationAgreement
          ?.agreementMember[0]?.status
      : searchParam.get('type') === 'BIDDING'
      ? details?.appliedBidding?.agreementBidding[0].agreementMember[0].status
      : details?.appliedJob?.jobAgreement[0].agreementMember[0].status;
  return (
    <AgreementRequestsWrapper>
      {(status === 'IN_PROGRESS' || status === 'PENDING') && (
        <div className="add-data">
          <div className="image-container">
            <img
              className="image"
              src={
                details?.collaborationApplicant?.individual?.profilePicture
                  ? details?.collaborationApplicant?.individual?.profilePicture
                  : userImage
              }
              alt=""
            />
          </div>
          <div className="input" onClick={() => setOpenRequestPupup(true)}>
            Click here to add
          </div>
          {openRequestPopup && (
            <ModalPopup onClose={onPopupClose} title="Add request">
              <Textarea
                required
                label={`Message for collaborator`}
                placeholder={'Enter your message here'}
                rows={4}
                value={state.message}
                onChange={(e) => onChangeHandler('message', e.target.value)}
              />
              <div className="file-upload">
                <span className="text-style">Add Attachment</span>
                <CustomFileUploadButton
                  borderWidth="0px 0px 1px 0px"
                  borderStyle="solid"
                  borderColor="#219FFF"
                  background="none"
                  fontFamily="'Poppins', 'Open Sans'"
                  fontSize="15px"
                  fontWeight="500"
                  textColor="#219FFF"
                  handleFile={(file) => attachmentsHandler(file)}
                  label="Add"
                  iconColor="#219FFF"
                  iconName="add"
                  iconSize="18"
                  isMultiple={true}
                />
              </div>
              <div className="post-attachment">
                {attachment.name ? (
                  <div className="img-wrapper">
                    <div className="img-container">
                      {checkForFileType(attachment.name) === 'others' ? (
                        <Icon name="attachment" />
                      ) : (
                        <img
                          src={checkForFileType(attachment.name)}
                          alt="attachment"
                        />
                      )}
                    </div>
                    <span className="img-text">{attachment.name}</span>
                    <span
                      className="remove-icon"
                      onClick={() => removeAttachmentsHandler(attachment)}>
                      <Icon color="rgba(146, 157, 175, 1)" name="small-close" />
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="save-btn">
                <CustomButton
                  disabled={disabled}
                  label="Save"
                  onClick={createAgreementRequest}
                />
              </div>
            </ModalPopup>
          )}
        </div>
      )}
      {requestStatus === 'MUTUALLY_CLOSED' && (
        <div className="approved-warning-text">
          The agreement has been successfully closed, as both parties have
          mutually agreed and executed the necessary steps to finalize its
          closure
        </div>
      )}
      {requestStatus === 'CANCELLED' && (
        <div className="rejected-warning-text">
          The agreement has been terminated by the admin and cannot be
          finalized. If you need assistance or have any questions, please
          contact Mihint.
        </div>
      )}
      {agreementRequestsList?.length === 0 ? (
        <div className="no-data-found">
          <p>No requests available</p>
        </div>
      ) : (
        <div className="requests-section">
          {agreementRequestsList?.length > 0 &&
            agreementRequestsList?.map((item, index) => {
              return (
                <RequestMessageCard
                  key={index}
                  userData={item}
                  isThreeDotsEnabled={
                    status === 'IN_PROGRESS' || status === 'PENDING'
                  }
                  getAgreementRequests={getAgreementRequests}
                  onStatusUpdate={(data) => updateAgreementRequest(data)}
                  OnComment={(data) => postRequestComment(data)}
                  subRequests={item?.agreementSubRequests}
                />
              );
            })}
        </div>
      )}
    </AgreementRequestsWrapper>
  );
}
