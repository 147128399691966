import React from 'react';
import { BiddingDetailsWrapper } from './style';
import { CustomButton } from 'components';
import Icon from 'utlils/Icons/Icon';

export default function BiddingDetails(props) {
  const { details, frequentlyAskedQuestions, documents } = props;
  return (
    <BiddingDetailsWrapper>
      <div className="about-heading">About this Bidding</div>
      <div className="about">{details?.appliedBidding?.biddingDescription}</div>
      <br />
      <div className="offering-heading">Bidding offerings</div>
      <div className="offering">
        {details?.appliedBidding?.biddingOfferings}
      </div>
      <div className="details">
        <div className="data">
          <div className="data-heading">Bidding type</div>
          <CustomButton
            variant="light"
            color="themeSecondary"
            label={details?.appliedBidding?.biddingType}
          />
        </div>
        <div className="data">
          <div className="data-heading">Bidding category</div>
          <CustomButton
            variant="light"
            color="themeSecondary"
            label={details?.appliedBidding?.biddingCategory}
          />
        </div>
      </div>
      <div className="FQs">
        {frequentlyAskedQuestions &&
          Object.keys(frequentlyAskedQuestions).map((fnq) => (
            <div style={{ marginTop: '30px' }}>
              <div className="question-heading">{fnq}</div>
              <div className="answer">{frequentlyAskedQuestions[fnq]}</div>
            </div>
          ))}
      </div>
      {documents.length > 0 && (
        <div className="documents">
          <div className="documents-heading">Attachments</div>
          {documents.map((document, index) => (
            <a href={document} className="link" key={index + 1}>
              <div className="link-content-wrapper">
                <div className="icon-name">
                  <Icon name="attachment" />
                  <span>
                    {document.split('/').slice(-1)[0].split('_')[0]}.
                    {document.split('.').slice(-1)}
                  </span>
                </div>
                <CustomButton variant="textBtn">
                  Download
                  <Icon name="download-box" color="#0ABD00" size={16} />
                </CustomButton>
              </div>
            </a>
          ))}
        </div>
      )}
    </BiddingDetailsWrapper>
  );
}
