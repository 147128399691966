export const createNotificationRedirects = (id, role) => {
  let profile = role === 'INDIVIDUAL' ? 'individual' : 'enterprise';
  const notificationRedirects = {
    joinDepartmentRequestOnCreate: {
      aim: 'Department Joining request for individual user during create',
      type: 'JOIN_DEPARTMENT_REQUEST_ON_CREATE',
      url: `/${profile}/profile/info/basic_details?invitationTab=enterprise`,
    },
    joinDepartmentRequestOnUpdate: {
      aim: 'Department joining request for individual user during update',
      type: 'JOIN_DEPARTMENT_REQUEST_ON_UPDATE',
      url: `/${profile}/profile/info/basic_details?invitationTab=enterprise`,
    },
    joinConnectionRequest: {
      aim: 'When individual user receive connection request',
      type: 'JOIN_CONNECTION_REQUEST',
      url: '/explore/networks/requests',
    },
    joinGroupRequest: {
      aim: 'When group admin send group joining request to network',
      type: 'JOIN_GROUP_REQUEST',
      url: `/${profile}/profile/info/basic_details?invitationTab=personal`,
    },
    acceptJoinGroupRequest: {
      aim: 'When a individual user request to join a group',
      type: 'ACCEPT_JOIN_GROUP_REQUEST',
      url: `/${profile}/profile/groups/${id?.groupId}/requests/recieved`,
    },
    acceptConnectionRequest: {
      aim: 'When user accepts the connection request',
      type: 'ACCEPT_CONNECTION_REQUEST',
      url: `/explore/networks/your-network`,
    },
    joinTeamRequest: {
      aim: 'Admin or superadmin invite user to join team',
      type: 'JOIN_TEAM_REQUEST',
      url: `/${profile}/profile/info/basic_details?invitationTab=enterprise`,
    },
    joinTeamRequestOnCreate: {
      aim: 'When team admin invite individual at creating team',
      type: 'JOIN_TEAM_REQUEST_ON_CREATE',
      url: `/${profile}/profile/info/basic_details?invitationTab=personal`,
    },
    acceptTeamInvitation: {
      aim: 'When invited team member accept the request to',
      type: 'ACCEPT_TEAM_INVITATION',
      url: `/${profile}/team-dashboard/${id?.teamId}/members`,
    },
    rejectTeamInvitation: {
      aim: 'When invited team member reject the request to',
      type: 'REJECT_TEAM_INVITATION',
      url: `/${profile}/team-dashboard/${id?.teamId}/members`,
    },
    acceptDepartmentInvitation: {
      aim: 'When individual user accept the department request (warning: due to error rejected case also comes with same type)',
      type: 'ACCEPT_DEPARTMENT_JOIN_REQUEST',
      url: `/${profile}/departments/view/${id?.departmentId}/published-problems`,
    },
    joinProjectRequestTeam: {
      aim: 'Request to link a project with a team',
      type: 'JOIN_PROJECT_REQUEST_FOR_TEAM_ON_CREATE',
      url: `/${profile}/team-dashboard/${id?.teamId}/requests`,
    },
    applyJobRequest: {
      aim: 'When individual user apply for a job post',
      type: 'APPLY_JOB_REQUEST',
      url: `/${profile}/team-dashboard/${id?.teamId}/career/posted-jobs`,
    },
    joinProjectRequest: {
      aim: 'When one project gets linked with another project',
      type: 'JOIN_PROJECT_REQUEST_FOR_PROJECT_ON_CREATE',
      url: `/${profile}/profile/personal/projects/${id?.linkProjectId}/view/details`,
    },
    joinProjectRequestOnUpdate: {
      aim: 'On project update if user add new project with their project',
      type: 'JOIN_PROJECT_REQUEST_FOR_PROJECT_ON_UPDATE',
      url: `/${profile}/profile/personal/projects/${id?.linkProjectId}/view/details`,
    },
    joinJobRequest: {
      aim: 'When job applicant get onboarded',
      type: 'JOIN_JOB_REQUEST',
      url: `/explore/jobs/my-jobs/applied-jobs`,
    },
    accpetProjectTeamJoiningInfo: {
      aim: 'Notify individual whose project request accepted by team admin',
      type: 'ACCEPT_PROJECT_JOIN_REQUEST',
      url: `/${profile}/profile/personal/projects/${id?.projectId}/view/details`,
    },
    rejectProjectTeamJoiningInfo: {
      aim: 'Team admin reject the project joining request',
      type: 'REJECT_PROJECT_JOIN_REQUEST',
      url: `/${profile}/profile/personal/projects/${id?.projectId}/view/linked-teams`,
    },
    acceptProjectRequest: {
      aim: 'Team admin get notified when project invitation getrs accpeted and team become part of project',
      type: 'ACCPET_PROJECT_JOIN_REQUEST_FOR_TEAM',
      url: `/${profile}/team-dashboard/${id?.teamId}/projects`,
    },
    createPublicationForGroup: {
      aim: 'Group member created publication in the group',
      type: 'CREATE_PUBLICATION_FOR_GROUP',
      url: `/${profile}/profile/groups/${id?.groupId}/view/publications`,
    },
    createPublicationForTeam: {
      aim: 'When team member create a publication in the team',
      type: 'CREATE_PUBLICATION_FOR_TEAM',
      url: `/${profile}/team-dashboard/${id?.teamId}/publications`,
    },
    createDepartmentTask: {
      aim: 'Department task assign by super admin to a department',
      type: 'ASSIGN_DEPARTMENT_TASK_ON_CREATE',
      url: `/${profile}/profile/organization/enterprise/department/${id?.assignedToDeptId}/tasks`,
    },
    createPost: {
      aim: 'When a post is created in a project stream in a personal or enterprise project',
      type: 'POST_CREATE',
      url:
        profile === 'individual'
          ? `/${profile}/profile/personal/projects/${id?.projectId}/view/streams?stream=0&stage=${id?.stageNumber}&post=${id?.postId}`
          : `/${profile}/departments/view/${id?.sentDeptId}/projects/${id?.projectId}/view/streams?stream=0&stage=${id?.stageNumber}&post=${id?.postId}`,
    },
    createPostComment: {
      aim: 'When a comment is created on a post in a project stream in a personal or enterprise project',
      type: 'POST_COMMENT_ON_CREATE',
      url:
        profile === 'individual'
          ? id?.departmentId
            ? `/${profile}/profile/organization/enterprise/department/${id?.departmentId}/projects/${id?.projectId}/view/streams`
            : `/${profile}/profile/personal/projects/${id?.projectId}/view/streams`
          : id?.departmentId
          ? `/${profile}/departments/view/${id?.departmentId}/projects/${id?.projectId}/view/streams`
          : ``,
    },
    createPostCommentForAdmin: {
      aim: 'When a comment is created on a post, send notification to linked Teams admins',
      type: 'POST_TASK_COMMENT_UNDER_PROJECT_FOR_TEAM_ADMINS',
      url: `/${profile}/profile/personal/projects/${id?.projectId}/view/streams`,
    },
    createProjectTaskToTeam: {
      aim: 'When a task is created in a project stream in a personal or enterprise project',
      type: 'ASSIGN_PROJECT_TASK_TO_TEAM_ON_CREATE',
      url: `/${profile}/team-dashboard/${id?.teamId}/tasks/project-tasks`,
    },
    assignDeptTaskToTeam: {
      aim: 'Notify all department admins when a department task is assigned',
      type: 'ASSIGN_DEPARTMENT_TASK_FROM_TEAM_TO_DEP_MEMBERS',
      url: `/${profile}/team-dashboard/${id?.teamId}/tasks/department-tasks`,
    },
    assignDeptTaskToYourTeam: {
      aim: 'notify all team admins when their team is assigned a department task',
      type: 'ASSIGN_DEPARTMENT_TASK_FROM_TEAM_TO_TEAM_MEMBERS',
      url: `/${profile}/team-dashboard/${id?.teamId}/tasks/department-tasks`,
    },
    commentOnTaskTeam: {
      aim: 'Notify all team admins when a new comment is added to a project task',
      type: 'POST_TASK_COMMENT_UNDER_PROJECT_FOR_TEAM_ADMINS',
      url:
        profile === 'individual'
          ? id?.departmentId
            ? `/${profile}/profile/organization/enterprise/department/${id?.departmentId}/projects/${id?.projectId}/view/streams`
            : `/${profile}/profile/personal/projects/${id?.projectId}/view/streams`
          : id?.departmentId
          ? `/${profile}/departments/view/${id?.departmentId}/projects/${id?.projectId}/view/streams`
          : ``,
    },
    assignProjectTaskToMember: {
      aim: 'Notify the member whom the task was assigned',
      type: 'ASSIGN_PROJECT_TASK_TO_MEMBER',
      url: `/${profile}/task-management?taskType=${id?.taskCategory}&taskTypeValueTitle=${id?.projectName}&taskTypeValue=${id?.projectId}&taskId=${id?.taskId}`,
    },
    assignDeptTaskToMember: {
      aim: 'Notify the member whom the department task was assigned',
      type: 'ASSIGN_DEPARTMENT_TASK_TO_MEMBER',
      url: `/${profile}/task-management?taskType=${id?.taskCategory}&taskTypeValueTitle=${id?.departmentName}&taskTypeValue=${id?.assignedToDeptId}&taskId=${id?.taskId}`,
    },
    commentOnDeptTask: {
      aim: 'Notify department admins when new comment added to department task',
      type: 'POST_TASK_COMMENT_UNDER_DEPARTMENT_FOR_TEAM_ADMINS',
      url: `/${profile}/task-management?taskType=${id?.taskCategory}&taskTypeValueTitle=${id?.departmentName}&taskTypeValue=${id?.assignedToDeptId}&taskId=${id?.taskId}`,
    },
    commentOnDeptTaskMember: {
      aim: 'Notify user to whom the deptartment task was assigned',
      type: 'POST_TASK_COMMENT_UNDER_DEPARTMENT_FOR_ASSIGN_MEMBERS',
      url: `/${profile}/task-management?taskType=${id?.taskCategory}&taskTypeValueTitle=${id?.departmentName}&taskTypeValue=${id?.assignedToDeptId}&taskId=${id?.taskId}`,
    },
    commentOnProjectTask: {
      aim: 'Notify team admins when a new comment is added to a project task',
      type: 'POST_TASK_COMMENT_UNDER_PROJECT_FOR_ASSIGN_MEMBERS',
      url: `/${profile}/task-management?taskType=${id?.taskCategory}&taskTypeValueTitle=${id?.projectName}&taskTypeValue=${id?.projectId}&taskId=${id?.taskId}`,
    },
    teamApprovalForDepartmentAdmin: {
      aim: 'Team approval request to super admin and admin',
      type: 'TEAM_APPROVAL_REQUEST_FOR_DEPARTMENT_ADMIN',
      url:
        role === 'INDIVIDUAL'
          ? `/${profile}/profile/organization/enterprise/department/${id?.departmentId}/teams`
          : `/${profile}/departments/view/${id?.departmentId}/teams`,
    },
    approveTeamRequestOfDepartmentBySuperAdmin: {
      aim: 'Department Team approval accepted by department admin or super admin',
      type: 'APPROVED_TEAM_REQUEST_BY_SUPERADMIN',
      url: `/${profile}/team-dashboard/${id?.teamId}/members`,
    },
    joinTeamRequestBySuperAdmin: {
      aim: 'Admin or super admin invite individual to join department team',
      type: 'JOIN_TEAM_REQUEST_BY_SUPERADMIN',
      url: `/${profile}/profile/info/basic_details?invitationTab=enterprise`,
    },
    blockedTeamBySuperAdmin: {
      aim: 'Reported team blocked by superadmin',
      type: 'BLOCKED_TEAM_BY_SUPERADMIN',
      url: `/public/team/${id?.teamId}/details`,
    },
    blockedProjectBySuperAdmin: {
      aim: 'Reported project blocked by superadmin',
      type: 'BLOCKED_PROJECT_BY_SUPERADMIN',
      url: `/public/project/${id?.projectId}/details`,
    },
    blockedEnterpriseTeamBySuperAdmin: {
      aim: 'Reported enterprise team blocked by superadmin',
      type: 'BLOCKED_ENTEPRISE_TEAM_BY_SUPERADMIN',
      url: `/public/team/${id?.teamId}/details`,
    },
    blockedEnterpriseProjectBySuperAdmin: {
      aim: 'Reported enterprise project blocked by superadmin',
      type: 'BLOCKED_ENTERPRISE_PROJECT_BY_SUPERADMIN',
      url: `/public/project/${id?.projectId}/details`,
    },
    unblockEnterpriseProjectBySuperAdmin: {
      aim: 'Reported enterprise project unblocked by superadmin',
      type: 'UNBLOCKED_ENTERPRISE_PROJECT_BY_SUPERADMIN',
      url: `/public/project/${id?.projectId}/details`,
    },
    unblockEnterpriseTeamBySuperAdmin: {
      aim: 'Reported enterprise team unblocked by superadmin',
      type: 'UNBLOCKED_ENTEPRISE_TEAM_BY_SUPERADMIN',
      url: `/public/team/${id?.teamId}/details`,
    },
    unblockTeamBySuperAdmin: {
      aim: 'Reported team unblocked by superadmin',
      type: 'UNBLOCKED_TEAM_BY_SUPERADMIN',
      url: `/public/team/${id?.teamId}/details`,
    },
    unblockProjectBySuperAdmin: {
      aim: 'Reported proejct unblocked by superadmin',
      type: 'UNBLOCKED_PROJECT_BY_SUPERADMIN',
      url: `/public/project/${id?.projectId}/details`,
    },
    individualCollaborationRequest: {
      aim: 'Collaboration request by a user for your project',
      type: 'INDIVIDUAL_COLLABORATE_ON_PROJECT',
      url: `/individual/profile/personal/projects/${id?.projectId}/view/collab-bids/collaboration/applicants/${id?.collaborationId}`,
    },
    individualBidRequest: {
      aim: 'Bid request by a user for your project',
      type: 'INDIVIDUAL_BID_ON_PROJECT',
      url: `/individual/profile/personal/projects/${id?.projectId}/view/collab-bids/bidding/applicants/${id?.biddingId}`,
    },
    addCollaboratorInAgreement: {
      aim: 'User get added into a agreement as a collaborator',
      type: `INDIVIDUAL_AGREEMENT_MEMBER_ADDED`,
      url: `/individual/profile/other-agreement/${id?.redirectId}/view/details?type=${id?.agreementType}&agreementMemberId=${id?.agreementMemberId}`,
    },
    agreementRequestCreated: {
      aim: 'Collaborator created a request in your agreement',
      type: 'INDIVIDUAL_AGREEMENT_REQUEST_CREATED',
      url:
        id?.redirectType === 'YOUR_AGREEMENT'
          ? `/individual/profile/agreements/communication/request/${id?.agreementId}?memberName=${id?.agreementMemberName}&agreementMemberId=${id?.agreementMemberId}`
          : `/individual/profile/other-agreement/${id?.redirectId}/view/agreement?type=${id?.agreementType}&agreementMemberId=${id?.agreementMemberId}`,
    },
    agreementReqeustStatusChange: {
      aim: 'User approve or reject the request status',
      type: 'INDIVIDUAL_AGREEMENT_REQUEST_STATUS_CHANGE',
      url: `/individual/profile/other-agreement/${id?.redirectId}/view/agreement?type=${id?.agreementType}&agreementMemberId=${id?.agreementMemberId}`,
    },
    agreementSubRequestCreated: {
      aim: 'Sub request created by user',
      type: 'INDIVIDUAL_AGREEMENT_SUB_REQUEST_CREATED',
      url:
        id?.redirectType === 'YOUR_AGREEMENT'
          ? `/individual/profile/agreements/communication/request/${id?.agreementId}?memberName=${id?.agreementMemberName}&agreementMemberId=${id?.agreementMemberId}`
          : `/individual/profile/other-agreement/${id?.redirectId}/view/agreement?type=${id?.agreementType}&agreementMemberId=${id?.agreementMemberId}`,
    },
    agreementSubRequestAcknowledged: {
      aim: 'Sub request acknowledged by user',
      type: 'INDIVIDUAL_AGREEMENT_SUB_REQUEST_ACKNOWLEDGED',
      url: `/individual/profile/agreements/communication/request/${id?.agreementId}?memberName=${id?.agreementMemberName}&agreementMemberId=${id?.agreementMemberId}`,
    },
  };
  return notificationRedirects;
};
