import styled from 'styled-components';

export const TeamPublicCardWrapper = styled.div`
  position: relative;
  & .upper-image-container {
    background: ${({ bgImgSrc }) => `url(${bgImgSrc})`}, #4d496f;
    border-radius: 16px;
    position: relative;
    height: 205px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  & .content-container {
    display: grid;
    grid-template-columns: minmax(140px, 160px) auto;
    gap: 25px;
    @media (max-width: 567px) {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
    & .sub-container {
      min-height: 200px;
      & .level-wrapper {
        position: relative;
        padding: 0 10px;
        margin: 0 20px;
        & .level--sub-wrapper {
          position: absolute;
          top: -50px;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 20px;
          & .profile-img-wrapper {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            border: 3px solid white;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            & img {
              width: 135px;
              height: 135px;
              border-radius: 50%;
            }
            & .profile-icon {
              border-radius: 50%;
              background: #e3e2ed;
              height: 135px;
              width: 135px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          & .level-btn {
            max-width: 160px;
            min-width: 140px;
            & span {
              font-weight: 600;
              font-size: 16px;
              color: #219fff;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            background: #219fff33;
            padding: 10px;
          }
        }
      }
      & .info-wrapper {
        padding: 0 10px;
        & .upper-half {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 0 15px 0;
          border-bottom: 2px solid #ccd2dd;
          @media (max-width: 1499px) and (min-width: 1300px) {
            flex-direction: column;
            align-items: flex-start;
          }
          @media (max-width: 700px) {
            flex-direction: column;
            align-items: flex-start;
          }
          & .buttons-wrapper {
            display: grid;
            grid-template-columns: repeat(
              ${({ numBtns }) => `${numBtns}`},
              1fr
            );
            gap: 20px;
            align-items: center;
            @media (max-width: 700px) {
              display: flex;
              flex-direction: column;
              width: 100%;
              align-items: end;
            }
          }
          & .title-class {
            & .title-name {
              font-style: normal;
              font-weight: 700;
              font-size: 30px;
              line-height: 41px;
              color: #0c162d;
              padding-bottom: 8px;
            }
            & .sub-title-wrapper {
              display: flex;
              align-items: center;
              gap: 10px;
              & .sub-title-name {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                color: #17191d;
              }
            }
          }
        }
        & .lower-half {
          padding: 15px 10px 0 0;
          display: grid;
          grid-template-columns: repeat(2, auto);
          @media (max-width: 700px) {
            display: block;
          }
          & .info-item {
            padding: 10px;
            padding-left: 0;
            display: flex;
            align-items: center;
            color: #929daf;
            gap: 15px;
          }
        }
      }
    }
  }
  & .options {
    position: absolute;
    top: 20px;
    right: 20px;
    .three-dots {
      cursor: pointer;
    }
  }
`;
