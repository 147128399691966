import styled from 'styled-components';

export const CollaborationDetailsWrapper = styled.div`
  & .about-heading {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
  }
  & .about {
    font-weight: 400;
    font-size: 16px;
    color: rgba(64, 79, 103, 0.8);
  }
  & .offering-heading {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
  }
  & .offering {
    font-weight: 400;
    font-size: 16px;
    color: rgba(64, 79, 103, 0.8);
  }
  & .details {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    & .data {
      & .data-heading {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 5px;
      }
    }
  }
  & .requirements {
    margin-top: 30px;
    & .requirements-heading {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 5px;
    }
    & .points {
      display: flex;
      margin-top: 15px;
      flex-direction: column;
      gap: 10px;
      padding: 0px 15px;
    }
  }
  & .documents {
    margin-top: 30px;
    & .documents-heading {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 5px;
    }
    & .link {
      width: 100%;
      & .link-content-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  & .term-and-conditions {
    margin-top: 30px;
    & .link {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;
