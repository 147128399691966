export const NOTIFICATION_TYPE = {
  ACCEPT_CONNECTION_REQUEST: 'ACCEPT_CONNECTION_REQUEST',
  ACCEPT_PROJECT_REQUEST: 'ACCEPT_PROJECT_REQUEST',
  ACCEPT_TEAM_REQUEST: 'ACCEPT_TEAM_REQUEST',
  ACCEPT_DEPARTMENT_JOIN_REQUEST: 'ACCEPT_DEPARTMENT_JOIN_REQUEST',
  ACCEPT_TEAM_INVITATION: 'ACCEPT_TEAM_INVITATION',
  ACCEPT_JOIN_GROUP_REQUEST: 'ACCEPT_JOIN_GROUP_REQUEST',
  ACCEPT_PROJECT_JOIN_REQUEST: 'ACCEPT_PROJECT_JOIN_REQUEST',
  ACCPET_PROJECT_JOIN_REQUEST_FOR_TEAM: 'ACCPET_PROJECT_JOIN_REQUEST_FOR_TEAM',
  APPLY_JOB_REQUEST: 'APPLY_JOB_REQUEST',
  APPROVED_TEAM_REQUEST_BY_SUPERADMIN: 'APPROVED_TEAM_REQUEST_BY_SUPERADMIN',
  ASSIGN_TASK_TO_MEMBER: 'ASSIGN_TASK_TO_MEMBER',
  ASSIGN_DEPARTMENT_TASK_ON_CREATE: 'ASSIGN_DEPARTMENT_TASK_ON_CREATE',
  ASSIGN_TASK_TO_TEAM: 'ASSIGN_TASK_TO_TEAM',
  CREATE_PROJECT_TASK: 'CREATE_PROJECT_TASK',
  CREATE_PUBLICATION_FOR_GROUP: 'CREATE_PUBLICATION_FOR_GROUP',
  CREATE_PUBLICATION_FOR_TEAM: 'CREATE_PUBLICATION_FOR_TEAM',
  JOIN_CONNECTION_REQUEST: 'JOIN_CONNECTION_REQUEST',
  JOIN_GROUP_REQUEST: 'JOIN_GROUP_REQUEST',
  JOIN_TEAM_REQUEST_BY_SUPERADMIN: 'JOIN_TEAM_REQUEST_BY_SUPERADMIN',
  JOIN_PROJECT_REQUEST_FOR_PROJECT_ON_CREATE:
    'JOIN_PROJECT_REQUEST_FOR_PROJECT_ON_CREATE',
  JOIN_PROJECT_REQUEST_FOR_PROJECT_ON_UPDATE:
    'JOIN_PROJECT_REQUEST_FOR_PROJECT_ON_UPDATE',
  JOIN_DEPARTMENT_REQUEST_ON_CREATE: 'JOIN_DEPARTMENT_REQUEST_ON_CREATE',
  JOIN_DEPARTMENT_REQUEST_ON_UPDATE: 'JOIN_DEPARTMENT_REQUEST_ON_UPDATE',
  JOIN_PROJECT_REQUEST_FOR_TEAM_ON_CREATE:
    'JOIN_PROJECT_REQUEST_FOR_TEAM_ON_CREATE',
  JOIN_JOB_REQUEST: 'JOIN_JOB_REQUEST',
  JOIN_PROJECT_REQUEST: 'JOIN_PROJECT_REQUEST',
  JOIN_TEAM_REQUEST: 'JOIN_TEAM_REQUEST',
  JOIN_TEAM_REQUEST_ON_CREATE: 'JOIN_TEAM_REQUEST_ON_CREATE',
  PROJECT_JOINING_INFO: 'PROJECT_JOINING_INFO',
  REJECT_PROJECT_REQUEST: 'REJECT_PROJECT_REQUEST',
  REJECT_TEAM_REQUEST: 'REJECT_TEAM_REQUEST',
  REMOVE_DEPARTMENT_REQUEST: 'REMOVE_DEPARTMENT_REQUEST',
  REMOVE_PROJECT_REQUEST: 'REMOVE_PROJECT_REQUEST',
  REMOVE_TEAM_REQUEST: 'REMOVE_TEAM_REQUEST',
  REMOVE_TEAM_MEMBERS: 'REMOVE_TEAM_MEMBERS',
  REJECT_TEAM_INVITATION: 'REJECT_TEAM_INVITATION',
  REJECT_PROJECT_JOIN_REQUEST: 'REJECT_PROJECT_JOIN_REQUEST',
  REJECT_DEPARTMENT_JOIN_REQUEST: 'REJECT_DEPARTMENT_JOIN_REQUEST',
  REJECT_TEAM_REQUEST_BY_SUPERADMIN: 'REJECT_TEAM_REQUEST_BY_SUPERADMIN',
  TASK_COMMENT: 'TASK_COMMENT',
  TEAM_APPROVAL_REQUEST_FOR_DEPARTMENT_ADMIN:
    'TEAM_APPROVAL_REQUEST_FOR_DEPARTMENT_ADMIN',
  BLOCKED_TEAM_BY_SUPERADMIN: 'BLOCKED_TEAM_BY_SUPERADMIN',
  BLOCKED_PROJECT_BY_SUPERADMIN: 'BLOCKED_PROJECT_BY_SUPERADMIN',
  BLOCKED_ENTEPRISE_TEAM_BY_SUPERADMIN: 'BLOCKED_ENTEPRISE_TEAM_BY_SUPERADMIN',
  BLOCKED_ENTERPRISE_PROJECT_BY_SUPERADMIN:
    'BLOCKED_ENTERPRISE_PROJECT_BY_SUPERADMIN',
  UNBLOCKED_TEAM_BY_SUPERADMIN: 'UNBLOCKED_TEAM_BY_SUPERADMIN',
  UNBLOCKED_PROJECT_BY_SUPERADMIN: 'UNBLOCKED_PROJECT_BY_SUPERADMIN',
  UNBLOCKED_ENTEPRISE_TEAM_BY_SUPERADMIN:
    'UNBLOCKED_ENTEPRISE_TEAM_BY_SUPERADMIN',
  UNBLOCKED_ENTERPRISE_PROJECT_BY_SUPERADMIN:
    'UNBLOCKED_ENTERPRISE_PROJECT_BY_SUPERADMIN',
  INDIVIDUAL_COLLABORATE_ON_PROJECT: 'INDIVIDUAL_COLLABORATE_ON_PROJECT',
  INDIVIDUAL_BID_ON_PROJECT: 'INDIVIDUAL_BID_ON_PROJECT',
};

export const NOTIFICATION_ICON = {
  APPLY_JOB_REQUEST: {
    name: 'man-in-circle',
    size: 20,
    color: '#0ABD00',
    background: {
      type: 'rounded',
      color: '#0ABD001A',
      size: 28,
    },
  },
  ACCEPT_CONNECTION_REQUEST: {
    name: 'user-tick',
    size: 20,
    color: '#0ABD00',
    background: {
      type: 'rounded',
      color: '#0ABD001A',
      size: 28,
    },
  },
  ACCEPT_PROJECT_REQUEST: {
    name: 'project',
    size: 20,
    color: '#0ABD00',
    background: {
      type: 'rounded',
      color: '#0ABD001A',
      size: 28,
    },
  },
  ACCEPT_PROJECT_JOIN_REQUEST: {
    name: 'project',
    size: 20,
    color: '#0ABD00',
    background: {
      type: 'rounded',
      color: '#0ABD001A',
      size: 28,
    },
  },
  ACCPET_PROJECT_JOIN_REQUEST_FOR_TEAM: {
    name: 'project',
    size: 20,
    color: '#0ABD00',
    background: {
      type: 'rounded',
      color: '#0ABD001A',
      size: 28,
    },
  },
  ACCEPT_TEAM_REQUEST: {
    name: 'people',
    size: 20,
    color: '#0ABD00',
    background: {
      type: 'rounded',
      color: '#0ABD001A',
      size: 28,
    },
  },
  ACCEPT_TEAM_INVITATION: {
    name: 'people',
    size: 20,
    color: '#0ABD00',
    background: {
      type: 'rounded',
      color: '#0ABD001A',
      size: 28,
    },
  },
  ACCEPT_DEPARTMENT_JOIN_REQUEST: {
    name: 'people',
    size: 20,
    color: '#0ABD00',
    background: {
      type: 'rounded',
      color: '#0ABD001A',
      size: 28,
    },
  },
  ACCEPT_JOIN_GROUP_REQUEST: {
    name: 'people',
    size: 20,
    color: '#0ABD00',
    background: {
      type: 'rounded',
      color: '#0ABD001A',
      size: 28,
    },
  },
  TEAM_APPROVAL_REQUEST_FOR_DEPARTMENT_ADMIN: {
    name: 'people',
    size: 20,
    color: '#0ABD00',
    background: {
      type: 'rounded',
      color: '#0ABD001A',
      size: 28,
    },
  },
  APPROVED_TEAM_REQUEST_BY_SUPERADMIN: {
    name: 'people',
    size: 20,
    color: '#0ABD00',
    background: {
      type: 'rounded',
      color: '#0ABD001A',
      size: 28,
    },
  },
  JOIN_TEAM_REQUEST_BY_SUPERADMIN: {
    name: 'people',
    size: 20,
    color: '#0ABD00',
    background: {
      type: 'rounded',
      color: '#0ABD001A',
      size: 28,
    },
  },
  JOIN_CONNECTION_REQUEST: {
    name: 'user-add',
    size: 20,
    color: '#219FFF',
    background: {
      type: 'rounded',
      color: '#219FFF1A',
      size: 28,
    },
  },
  JOIN_GROUP_REQUEST: {
    name: 'user-add',
    size: 20,
    color: '#219FFF',
    background: {
      type: 'rounded',
      color: '#219FFF1A',
      size: 28,
    },
  },
  JOIN_PROJECT_REQUEST: {
    name: 'element-plus',
    size: 20,
    color: '#219FFF',
    background: {
      type: 'rounded',
      color: '#219FFF1A',
      size: 28,
    },
  },
  JOIN_PROJECT_REQUEST_FOR_PROJECT_ON_CREATE: {
    name: 'element-plus',
    size: 20,
    color: '#219FFF',
    background: {
      type: 'rounded',
      color: '#219FFF1A',
      size: 28,
    },
  },
  JOIN_PROJECT_REQUEST_FOR_PROJECT_ON_UPDATE: {
    name: 'element-plus',
    size: 20,
    color: '#219FFF',
    background: {
      type: 'rounded',
      color: '#219FFF1A',
      size: 28,
    },
  },
  JOIN_TEAM_REQUEST: {
    name: 'box-notification',
    size: 20,
    color: '#219FFF',
    background: {
      type: 'rounded',
      color: '#219FFF1A',
      size: 28,
    },
  },
  JOIN_TEAM_REQUEST_ON_CREATE: {
    name: 'people',
    size: 20,
    color: '#219FFF',
    background: {
      type: 'rounded',
      color: '#219FFF1A',
      size: 28,
    },
  },
  JOIN_DEPARTMENT_REQUEST_ON_CREATE: {
    name: 'box-notification',
    size: 20,
    color: '#219FFF',
    background: {
      type: 'rounded',
      color: '#219FFF1A',
      size: 28,
    },
  },
  CREATE_PUBLICATION_FOR_GROUP: {
    name: 'box-notification',
    size: 20,
    color: '#219FFF',
    background: {
      type: 'rounded',
      color: '#219FFF1A',
      size: 28,
    },
  },
  CREATE_PUBLICATION_FOR_TEAM: {
    name: 'box-notification',
    size: 20,
    color: '#219FFF',
    background: {
      type: 'rounded',
      color: '#219FFF1A',
      size: 28,
    },
  },
  JOIN_DEPARTMENT_REQUEST_ON_UPDATE: {
    name: 'box-notification',
    size: 20,
    color: '#219FFF',
    background: {
      type: 'rounded',
      color: '#219FFF1A',
      size: 28,
    },
  },
  JOIN_PROJECT_REQUEST_FOR_TEAM_ON_CREATE: {
    name: 'box-notification',
    size: 20,
    color: '#219FFF',
    background: {
      type: 'rounded',
      color: '#219FFF1A',
      size: 28,
    },
  },
  JOIN_JOB_REQUEST: {
    name: 'brief-case',
    size: 20,
    color: '#219FFF',
    background: {
      type: 'rounded',
      color: '#219FFF1A',
      size: 28,
    },
  },
  REJECT_PROJECT_REQUEST: {
    name: 'reject-v2',
    size: 20,
    color: '#F53D6B',
    background: {
      type: 'rounded',
      color: '#FEECF1',
      size: 28,
    },
  },
  REJECT_TEAM_REQUEST: {
    name: 'reject-v2',
    size: 20,
    color: '#F53D6B',
    background: {
      type: 'rounded',
      color: '#FEECF1',
      size: 28,
    },
  },
  REMOVE_TEAM_REQUEST: {
    name: 'reject-v2',
    size: 20,
    color: '#F53D6B',
    background: {
      type: 'rounded',
      color: '#FEECF1',
      size: 28,
    },
  },
  REJECT_TEAM_INVITATION: {
    name: 'reject-v2',
    size: 20,
    color: '#F53D6B',
    background: {
      type: 'rounded',
      color: '#FEECF1',
      size: 28,
    },
  },
  REJECT_PROJECT_JOIN_REQUEST: {
    name: 'reject-v2',
    size: 20,
    color: '#F53D6B',
    background: {
      type: 'rounded',
      color: '#FEECF1',
      size: 28,
    },
  },
  REJECT_DEPARTMENT_JOIN_REQUEST: {
    name: 'reject-v2',
    size: 20,
    color: '#F53D6B',
    background: {
      type: 'rounded',
      color: '#FEECF1',
      size: 28,
    },
  },
  REMOVE_TEAM_MEMBERS: {
    name: 'reject-v2',
    size: 20,
    color: '#F53D6B',
    background: {
      type: 'rounded',
      color: '#FEECF1',
      size: 28,
    },
  },
  REJECT_TEAM_REQUEST_BY_SUPERADMIN: {
    name: 'reject-v2',
    size: 20,
    color: '#F53D6B',
    background: {
      type: 'rounded',
      color: '#FEECF1',
      size: 28,
    },
  },
  REMOVE_PROJECT_REQUEST: {
    name: 'reject-v2',
    size: 20,
    color: '#F53D6B',
    background: {
      type: 'rounded',
      color: '#FEECF1',
      size: 28,
    },
  },
  REMOVE_DEPARTMENT_REQUEST: {
    name: 'reject-v2',
    size: 20,
    color: '#F53D6B',
    background: {
      type: 'rounded',
      color: '#FEECF1',
      size: 28,
    },
  },
  PROJECT_JOINING_INFO: {
    name: 'star',
    size: 20,
    color: '#FE9320',
    background: {
      type: 'rounded',
      color: '#FE93201A',
      size: 28,
    },
  },
  TASK_COMMENT: {
    name: 'message-icon',
    size: 20,
    color: '#FE9320',
    background: {
      type: 'rounded',
      color: '#FE93201A',
      size: 28,
    },
  },
  CREATE_PROJECT_TASK: {
    name: 'task',
    size: 20,
    color: '#FE9320',
    background: {
      type: 'rounded',
      color: '#FE93201A',
      size: 28,
    },
  },
  ASSIGN_TASK_TO_MEMBER: {
    name: 'task',
    size: 20,
    color: '#FE9320',
    background: {
      type: 'rounded',
      color: '#FE93201A',
      size: 28,
    },
  },
  ASSIGN_DEPARTMENT_TASK_ON_CREATE: {
    name: 'task',
    size: 20,
    color: '#FE9320',
    background: {
      type: 'rounded',
      color: '#FE93201A',
      size: 28,
    },
  },
  ASSIGN_TASK_TO_TEAM: {
    name: 'task',
    size: 20,
    color: '#FE9320',
    background: {
      type: 'rounded',
      color: '#FE93201A',
      size: 28,
    },
  },
  BLOCKED_TEAM_BY_SUPERADMIN: {
    name: 'lock-two',
    size: 20,
    color: '#F53D6B',
    background: {
      type: 'rounded',
      color: '#FEECF1',
      size: 28,
    },
  },
  BLOCKED_PROJECT_BY_SUPERADMIN: {
    name: 'lock-two',
    size: 20,
    color: '#F53D6B',
    background: {
      type: 'rounded',
      color: '#FEECF1',
      size: 28,
    },
  },
  BLOCKED_ENTEPRISE_TEAM_BY_SUPERADMIN: {
    name: 'lock-two',
    size: 20,
    color: '#F53D6B',
    background: {
      type: 'rounded',
      color: '#FEECF1',
      size: 28,
    },
  },
  BLOCKED_ENTERPRISE_PROJECT_BY_SUPERADMIN: {
    name: 'lock-two',
    size: 20,
    color: '#F53D6B',
    background: {
      type: 'rounded',
      color: '#FEECF1',
      size: 28,
    },
  },
  UNBLOCKED_TEAM_BY_SUPERADMIN: {
    name: 'unlock',
    size: 20,
    color: '#407BFF',
    background: {
      type: 'rounded',
      color: '#E9F6FF',
      size: 28,
    },
  },
  UNBLOCKED_PROJECT_BY_SUPERADMIN: {
    name: 'unlock',
    size: 20,
    color: '#407BFF',
    background: {
      type: 'rounded',
      color: '#E9F6FF',
      size: 28,
    },
  },
  UNBLOCKED_ENTEPRISE_TEAM_BY_SUPERADMIN: {
    name: 'unlock',
    size: 20,
    color: '#407BFF',
    background: {
      type: 'rounded',
      color: '#E9F6FF',
      size: 28,
    },
  },
  UNBLOCKED_ENTERPRISE_PROJECT_BY_SUPERADMIN: {
    name: 'unlock',
    size: 20,
    color: '#407BFF',
    background: {
      type: 'rounded',
      color: '#E9F6FF',
      size: 28,
    },
  },
  INDIVIDUAL_BID_ON_PROJECT: {
    name: 'judge',
    size: 20,
    color: '#407BFF',
    background: {
      type: 'rounded',
      color: '#E9F6FF',
      size: 28,
    },
  },
  INDIVIDUAL_COLLABORATE_ON_PROJECT: {
    name: 'celo',
    size: 20,
    color: '#407BFF',
    background: {
      type: 'rounded',
      color: '#E9F6FF',
      size: 25,
    },
  },
};
