import React from 'react';
import { CollaborationApplicationWrapper } from './style';
import Icon from 'utlils/Icons/Icon';
import { CustomButton } from 'components';

export default function CollaborationApplication(props) {
  const { questions, applicantDocuments } = props;
  return (
    <CollaborationApplicationWrapper>
      <div className="details">
        {questions &&
          Object.keys(questions).map((question) => (
            <>
              <div className="question-heading">{question}</div>
              <div className="answer">{questions[question]}</div>
            </>
          ))}
      </div>
      <div className="your-documents">
        <div className="your-documents-heading">Your Documents</div>
        {applicantDocuments &&
          applicantDocuments.length > 0 &&
          applicantDocuments.map((document, index) => (
            <a href={document} className="link" key={index + 1}>
              <div className="link-content-wrapper">
                <div className="icon-name">
                  <Icon name="attachment" />
                  <span>
                    {document.split('/').slice(-1)[0].split('_')[0]}.
                    {document.split('.').slice(-1)}
                  </span>
                </div>
                <CustomButton variant="textBtn">
                  Download
                  <Icon name="download-box" color="#0ABD00" size={16} />
                </CustomButton>
              </div>
            </a>
          ))}
      </div>
    </CollaborationApplicationWrapper>
  );
}
