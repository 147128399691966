import React from 'react';
import { CustomTable, ScrollableDiv } from 'components';
import { AGREEMENT_TABLE_HEADERS } from '../constants';
import { AgreementTableWrapper } from './style';

const AgreementsTable = (props) => {
  const { agreementsList } = props;
  return (
    <AgreementTableWrapper>
      {agreementsList?.length > 0 ? (
        <ScrollableDiv scrollOnHeight="378px">
          <CustomTable
            className="agreement-table"
            rows={agreementsList}
            headers={AGREEMENT_TABLE_HEADERS}
          />
        </ScrollableDiv>
      ) : (
        <div className="no-data">
          No records available. <br /> Create a New Agreement
        </div>
      )}
    </AgreementTableWrapper>
  );
};

export default AgreementsTable;
