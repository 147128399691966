import { useCallback, useEffect, useState } from 'react';
import { getData } from 'country-list';
import { Box, Loader } from 'components';
import { FETCH_BASIC_DETAILS, useAxiosWrapper } from 'services';
import './basicDetailsView.scss';
import TokenService from 'services/token.service';

export const BasicDetailsView = () => {
  const [state, setState] = useState({
    fullName: '',
    lastName: '',
    country: '',
    state: '',
    city: '',
    email: '',
    contact: '',
    languages: '',
    linkedInLink: '',
    linkTeamsUpLink: '',
    link: [],
    about: '',
  });
  const [fnWrapper, context] = useAxiosWrapper();
  const countryList = getData();

  const getBasicDetailsInfo = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_BASIC_DETAILS}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_BASIC_INFO',
    });

    if (res.data.statusCode === 200) {
      const response = res.data.data;
      let [firstName, lastName] = response.name.trim().split(' ');
      let linkedInLinkHasHttps =
        response?.linkedInLink?.split('//')[0] === 'https:';
      setState({
        fullName: firstName,
        lastName: lastName,
        contact: response.contact,
        email: response.email,
        languages: response.languages,
        country: countryList.filter(
          (country) => country.code === response.country
        )[0]?.name,
        state: response?.state,
        city: response?.city,
        linkedInLink: !linkedInLinkHasHttps
          ? response?.linkedInLink?.length > 0
            ? `https://${response?.linkedInLink}`
            : 'NA'
          : response?.linkedInLink,
        link: response.link.length ? response.link : [''],
        linkTeamsUpLink: response.linkTeamsUpLink,
        about: response.about,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBasicDetailsInfo();
  }, [getBasicDetailsInfo]);

  return (
    <>
      {context.busy.status ? (
        <Loader loading={context.busy.status} />
      ) : (
        <div className="row mb-2 profile-view-container">
          <div className="row">
            <div className="col-xl-6 col-xxl-5 col-sm-6 col-12">
              <Box mt="10px">
                <p className="label">First Name</p>
                <p className="value">
                  {state.fullName ? state.fullName : 'NA'}
                </p>
              </Box>
            </div>
            <div className="col-xl-6 col-xxl-4 col-sm-6 col-12">
              <Box mt="10px">
                <p className="label">Last Name</p>
                <p className="value">
                  {state.lastName ? state.lastName : 'NA'}
                </p>
              </Box>
            </div>
            <div className="col-xl-6 col-xxl-3 col-sm-6 col-12">
              <Box mt="10px">
                <p className="label">Contact Number</p>
                <p className="value">{state.contact ? state.contact : 'NA'}</p>
              </Box>
            </div>
            <div className="col-xl-6 col-xxl-5 col-sm-6 col-12">
              <Box mt="10px">
                <p className="label">Email</p>
                <p className="value">{state?.email}</p>
              </Box>
            </div>
            <div className="col-xl-6 col-xxl-4 col-sm-6 col-12">
              <Box mt="10px">
                <p className="label">Country</p>
                <p className="value">{state.country ? state.country : 'NA'}</p>
              </Box>
            </div>
            <div className="col-xl-6 col-xxl-3 col-sm-6 col-12">
              <Box mt="10px">
                <p className="label">State</p>
                <p className="value">{state.state ? state.state : 'NA'}</p>
              </Box>
            </div>
            <div className="col-xl-6 col-xxl-5 col-sm-6 col-12">
              <Box mt="10px">
                <p className="label">City</p>
                <p className="value">{state.city ? state.city : 'NA'}</p>
              </Box>
            </div>
            <div className="col-xl-6 col-xxl-3 col-sm-6 col-12">
              <Box mt="10px">
                <p className="label">Languages</p>
                <p className="value">
                  {state.languages ? state.languages : 'NA'}
                </p>
              </Box>
            </div>
            <div className="col-12">
              <Box mt="10px">
                <p className="label">miCollab Link</p>
                <a
                  className="value"
                  href={state.linkTeamsUpLink && state.linkTeamsUpLink}
                  target="_blank"
                  rel="noreferrer">
                  {state.linkTeamsUpLink ? state.linkTeamsUpLink : 'NA'}
                </a>
              </Box>
            </div>
            <div className="col-12">
              <Box mt="10px">
                <p className="label">LinkedIn Account Link</p>
                <a
                  className="value"
                  href={state.linkedInLink && state.linkedInLink}
                  target="_blank"
                  rel="noreferrer">
                  {state.linkedInLink ? state.linkedInLink : 'NA'}
                </a>
              </Box>
            </div>
            {state.link.map((ele, index) => {
              return (
                <div className="col-12" key={index}>
                  <Box mt="10px">
                    <p className="label">Link {index + 1}</p>
                    <a
                      className="value"
                      href={ele && ele}
                      target="_blank"
                      rel="noreferrer">
                      {ele ? ele : 'NA'}
                    </a>
                  </Box>
                </div>
              );
            })}
            <div className="col-12">
              <Box mt="30px">
                <Box mb="22px">
                  <h5 className="label">About me</h5>
                </Box>
                <p className="value">{state.about ? state.about : 'NA'}</p>
              </Box>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
