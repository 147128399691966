import { CustomButton, Loader, Pagination } from 'components';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import createInitialTeams from '../../../assests/images/createInitialTeams.png';
import TeamReportCard from '../components/TeamReportCards';
import Icon from 'utlils/Icons/Icon';
import ReportFilter from '../components/Filter';
import { REPORT_STATUS_TYPES } from '../constants';

const DEFAULT_FILTER_STATE = {
  startDate: '',
  endDate: '',
  status: REPORT_STATUS_TYPES[0],
};

export default function TeamReports(props) {
  const { ReportsArrayList, reportsData, fetchReports, context } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  let pageSize = 6;
  const [filterState, setFilterState] = useState(DEFAULT_FILTER_STATE);

  const projectFilterOnChangeHandler = (field = '', value = null) => {
    setFilterState({
      ...filterState,
      [field]: value,
    });
  };

  const FilterSaveHandler = (type) => {
    if (type === 'Apply') {
      fetchReports('team', currentPage, pageSize, filterState);
      setIsFilterOpen(false);
    } else if (type === 'Cancel') {
      fetchReports('team', currentPage, pageSize, DEFAULT_FILTER_STATE);
      setFilterState(DEFAULT_FILTER_STATE);
      setIsFilterOpen(false);
    }
  };

  useEffect(() => {
    fetchReports('team', currentPage, pageSize, filterState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <TeamReportsWrapper>
      <div className="main-wrapper">
        <div className="header">
          <div className="heading">{'Team reports'}</div>
          <div className="filter">
            <CustomButton
              className="w-100"
              variant="light"
              color={isFilterOpen ? 'themeSecondary' : 'themeGray'}
              onClick={() => setIsFilterOpen(!isFilterOpen)}>
              <Icon
                name="filter"
                size={20}
                className="filter-arrow"
                color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#929DAF'}
              />
              Filter
            </CustomButton>
          </div>
        </div>
        {isFilterOpen && (
          <ReportFilter
            FilterSaveHandler={FilterSaveHandler}
            state={filterState}
            projectFilterOnChangeHandler={projectFilterOnChangeHandler}
            filterTitle={'Team Report Filter'}
          />
        )}
        {context.busy.status ? (
          <Loader loading={context.busy.status} />
        ) : (
          <>
            <div className="cards">
              {ReportsArrayList.length ? (
                ReportsArrayList.map((data, index) => (
                  <TeamReportCard key={index} {...data} />
                ))
              ) : (
                <div className="no-data-container">
                  <div className="image">
                    <img src={createInitialTeams} alt="" width="320px" />
                  </div>
                  <div className="no-data-text">
                    Oh, looks like no reported teams are present at this moment!
                  </div>
                </div>
              )}
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={
                reportsData.currentPage ? reportsData.currentPage : 0
              }
              totalCount={reportsData.totalItems ? reportsData.totalItems : 0}
              pageSize={pageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </>
        )}
      </div>
    </TeamReportsWrapper>
  );
}

const TeamReportsWrapper = styled.div`
  & .main-wrapper {
    position: relative;
    & .header {
      display: flex;
      justify-content: space-between;
      & .heading {
        font-size: 26px;
        font-weight: 600;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    & .cards {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      padding-top: 20px;
      & .no-data-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & .image {
          margin-top: 50px;
        }
        & .no-data-text {
          margin-top: 30px;
          text-align: center;
        }
      }
    }
    & .pagination-bar {
      justify-content: center;
      padding-top: 20px;
    }
  }
`;
