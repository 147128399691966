export const statusColor = (status) => {
  switch (status) {
    case 'ACTIVE':
      return '#ffae18';
    case 'BLOCKED':
      return '#ff3737';
    default:
      return '#404F67';
  }
};
