import { TeamPublicWrapper } from './style';
import { useState, useEffect, useCallback } from 'react';
import { Route, Routes, useParams, useNavigate } from 'react-router-dom';
import {
  FETCH_USER_PROFILE,
  FETCH_TEAM_PUBLIC_PROFILE_DETAILS,
  useAxiosWrapper,
  REPORT_TEAM_OR_PROJECT,
} from 'services';
import TokenService from 'services/token.service';
import { getProfileText } from 'utlils/utils';
import { TeamPublicDetails } from './TeamPublicDetails';
import { TeamPublicMembers } from './TeamPublicMembers';
import { TeamPublicProjects } from './TeamPublicProjects';
import { TeamPublicPublications } from './TeamPublicPublications';
import { TeamPublicOpenings } from './TeamPublicOpenings';
import { TeamPublicCard } from './Components';
import { capitalizeFirstLetter } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import {
  CustomButton,
  ModalPopup,
  NavbarBrand1,
  Sidebar,
  Textarea,
  ScrollableDiv,
} from 'components';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import { INDIVIDUAL_DASHBOARD_NAV_LIST } from 'pages/IndividualDashboard/constants';
import Icon from 'utlils/Icons/Icon';
import { getTeamPublicLeftSideData } from './constants';
import { toast } from 'react-toastify';
import { checkForSpacesInStartEnd } from 'helpers';
import { ReportOptions } from 'constants/constants';

const DEFAULT_TEAM_INFO = {
  teamName: '',
  teamType: '',
  teamOwner: '',
  profileImg: '',
  teamCategory: '',
  teamMembers: 0,
};

export const DEFAULT_TEAM_DETAILS = {
  'Team Topic': '',
  Description: '',
};

const dropMenuItems = [
  {
    id: 0,
    label: 'Report',
  },
];
export const TeamPublic = ({ currentLocale, handleLocaleChange }) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [userProfile, setUserProfile] = useState({});
  const { teamId } = useParams();
  const [teamInfo, setTeamInfo] = useState(DEFAULT_TEAM_INFO);
  const [teamDetails, setTeamDetails] = useState(DEFAULT_TEAM_DETAILS);
  const [isNotLoggedInUser, setNotLoggedInUser] = useState(false);
  const [leftActiveItem, setLeftActiveItem] = useState('');
  const [reportReasonPopup, setReportReasonPopup] = useState(false);
  const [reasonMessage, setReasonMessage] = useState('');
  const [isDotsActive, setIsDotsActive] = useState(false);
  const [radioType, setRadioType] = useState(ReportOptions[0].value);
  const [showBlockedPopup, setShowBlockedPopup] = useState(false);
  const loggedInUser = TokenService.getSession();
  const navigate = useNavigate();

  const setLeftSidebar = (type) => {
    setLeftActiveItem(type);
  };

  const getUserProfile = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUserProfile({
        ...response,
        profileText: getProfileText(
          response?.role === 'INDIVIDUAL'
            ? response?.name
            : response?.enterpriseName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTeamDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAM_PUBLIC_PROFILE_DETAILS}/${teamId}`,
      method: 'GET',
      type: 'FETCH_TEAM_PUBLIC_PROFILE_DETAILS',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      const isBlocked = response?.teamState === 'BLOCKED';
      setShowBlockedPopup(isBlocked);
      const item = {
        teamName: response?.teamName,
        teamType: capitalizeFirstLetter(response?.type?.title),
        teamCategory: response?.teamCategory,
        teamOwner:
          response?.enterprise !== null
            ? response?.enterprise?.enterpriseName
            : response?.user?.individual?.name,
        profileImg: '',
        teamMembers: response?.teamMembers?.length,
      };
      setTeamInfo(item);
      setTeamDetails({
        'Team Topic': response?.topic,
        Description: response?.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loggedInUser = TokenService.getSession();
    if (loggedInUser) {
      setNotLoggedInUser(false);
      getUserProfile(loggedInUser);
    } else {
      setNotLoggedInUser(true);
    }
  }, [getUserProfile]);

  useEffect(() => {
    getTeamDetails();
  }, [getTeamDetails, teamId]);

  const btns = [
    // {
    //   label: 'Request Link',
    //   bgColor: '#fff',
    //   color: '#219fff',
    //   iconName: 'link',
    //   buttonStyle: {
    //     padding: '0 20px',
    //   },
    //   onClick: () => {
    //     // editBtnHandler(!isEditPopupOpen);
    //     // updateOnProfileEdit(false);
    //   },
    // },
  ];

  const infoItems = [
    {
      label: 'Website',
      value: teamInfo.teamType,
      iconName: 'tag-user',
    },
    {
      label: 'Members',
      value: `${teamInfo.teamMembers} Members`,
      iconName: 'people',
    },
  ];

  const dotsHandler = () => {
    setIsDotsActive(!isDotsActive);
  };

  const onChangeReasonMessagehandler = (e) => {
    setReasonMessage(e.target.value);
  };

  function handleOptionsClick(item) {
    switch (item.id) {
      case 0:
        setIsDotsActive(false);
        setReportReasonPopup(true);
        break;
      default:
    }
  }

  const submitReasonHandler = async () => {
    const loggedInUser = TokenService.getSession();
    let reason;
    if (radioType === 'Other Reason') {
      reason = reasonMessage;
    } else {
      reason = radioType;
    }
    const payload = {
      reportedByUserId: loggedInUser.id,
      teamId: teamId,
      reason,
    };
    const res = await fnWrapper({
      url: REPORT_TEAM_OR_PROJECT,
      method: 'POST',
      type: 'REPORT_TEAM_OR_PROJECT',
      payload: payload,
    });
    if (res?.data?.statusCode === 200) {
      toast.success('Report submitted successfully', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setReportReasonPopup(false);
    }
    setRadioType(ReportOptions[0].value);
    setReasonMessage('');
  };

  function closeReasonPopup() {
    setReasonMessage('');
    setReportReasonPopup(false);
  }

  const disablePopUp = () => {
    if (radioType === 'Other Reason' && reasonMessage.length < 3) {
      return true;
    } else return false;
  };

  return (
    <>
      <NavbarBrand1
        currentLocale={currentLocale}
        handleLocaleChange={handleLocaleChange}
        userProfile={userProfile}
        navMenu={INDIVIDUAL_DASHBOARD_NAV_LIST(userProfile?.role)}
      />
      <TeamPublicWrapper>
        <div className="main-container">
          <div className="layout-container">
            <div className="layout-upper-container">
              <div className="upper-container-child">
                <TeamPublicCard
                  isNotLoggedInUser={isNotLoggedInUser}
                  isDotsActive={isDotsActive}
                  handleOptionsClick={handleOptionsClick}
                  dotsHandler={dotsHandler}
                  dropMenuItems={dropMenuItems}
                  teamName={teamInfo.teamName}
                  teamOwner={teamInfo.teamOwner}
                  teamCategory={teamInfo.teamCategory}
                  profileImgSrc={teamInfo.profileImg}
                  buttons={btns}
                  infoItems={infoItems}
                />
              </div>
              <div className="upper-container-child">
                <div className="d-flex align-items-center">
                  <Icon name="brief-case" color="#404f67" size={24} />
                  <span className="px-2" style={{ color: '#404f67' }}>
                    <strong>Hiring</strong>
                  </span>
                </div>
                <div className="d-flex justify-content-center align-items-center h-100 flex-column">
                  <Icon name="warning" size={40} color="#929daf" />
                  <div className="py-3 text-center">
                    It looks like they are not hiring at the moment
                  </div>
                </div>
              </div>
            </div>
            <div className="layout-lower-container">
              <div className="sub-container">
                <Sidebar
                  sidebarData={getTeamPublicLeftSideData(teamId)}
                  activeType={leftActiveItem}
                  setActiveType={setLeftSidebar}
                />
              </div>
              <div className="sub-container">
                <Routes>
                  <Route path="">
                    <Route
                      element={
                        <TeamPublicDetails
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          teamDetails={teamDetails}
                        />
                      }
                      path="details/*"
                    />
                    <Route
                      element={
                        <TeamPublicMembers userRole={userProfile?.role} />
                      }
                      path="members/*"
                    />
                    <Route
                      element={
                        <TeamPublicProjects
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                        />
                      }
                      path="projects/*"
                    />
                    <Route
                      element={
                        <TeamPublicPublications
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                        />
                      }
                      path="publications/*"
                    />
                    <Route
                      element={
                        <TeamPublicOpenings
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          isNotLoggedInUser={isNotLoggedInUser}
                        />
                      }
                      path="openings/*"
                    />
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
        {reportReasonPopup && (
          <ModalPopup
            open={false}
            onClose={closeReasonPopup}
            title="Don't want to see this">
            <br />
            <ScrollableDiv scrollOnHeight="300px">
              <p>Please provide a reason for reporting this team</p>
              <RadioGroup
                currChecked={radioType}
                handleChange={(e) => setRadioType(e.target.value)}
                customClassName={'radio-btn-class'}
                labels={ReportOptions}
              />
              {radioType === 'Other Reason' && (
                <Textarea
                  placeholder={'Type your reason here....'}
                  value={reasonMessage}
                  onChange={(e) => onChangeReasonMessagehandler(e)}
                  rows={6}
                  label={'State your reason'}
                  required
                />
              )}
            </ScrollableDiv>
            <br />
            <CustomButton
              onClick={submitReasonHandler}
              disabled={
                disablePopUp() || checkForSpacesInStartEnd(reasonMessage)
              }
              loading={context.busy.status}
              className="w-100 mt-2"
              label="Submit"
              color="themeSecondary"
            />
          </ModalPopup>
        )}
        {showBlockedPopup && (
          <ModalPopup
            popupWrapperClass="team-blocked-popup-wrapper"
            open={false}
            hasCloseIcon={false}
            title="Team Blocked Due to Multiple Issues">
            <div className="team-blocked-popup">
              <Icon name="reject" size={100} />
              <p>
                This team has been <span>Blocked</span> due to multiple issues
                and concerns that have arisen. The decision to block the team
                was made after careful consideration of various factors to
                ensure the integrity and safety of our platform. If you still
                want to proceed, please reach out to our support team for
                further assistance.
              </p>
              <CustomButton
                color="themeSecondary"
                onClick={() =>
                  navigate(
                    loggedInUser.role === 'SMALL_ENTERPRISE'
                      ? '/enterprise/profile/view/details'
                      : `/explore/teams/search-team`
                  )
                }
                label="Continue"
              />
            </div>
          </ModalPopup>
        )}
      </TeamPublicWrapper>
    </>
  );
};
