import styled from 'styled-components';
export const JobApplicationWrapper = styled.div`
  & .questions {
    & .question-heading {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 5px;
    }
    & .answer {
      margin-top: 20px;
      font-weight: 400;
      font-size: 14px;
      color: rgba(64, 79, 103, 0.7);
      padding: 20px;
      background-color: rgba(248, 248, 248, 1);
      border-radius: 16px;
    }
  }
  & .apply-with-heading {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
  }
  & .apply-with {
    font-weight: 400;
    font-size: 16px;
    color: rgba(64, 79, 103, 0.8);
    display: flex;
  }
`;
