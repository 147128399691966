import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NavbarBrand1, Sidebar } from 'components';
import { MIHINT_ADMIN_DASHBOARD_NAV_LIST, leftSidebarData } from './constants';
import { WaitList } from './WaitList';
import { MiHintAdminWrapper } from './styles';
import ProjectReports from './ProjectReport';
import TeamReports from './TeamReport';
import { GET_LIST_OF_REPORTS, useAxiosWrapper } from 'services';
import ViewReport from './ViewReport';

export const MiHintAdmin = ({ currentLocale, handleLocaleChange }) => {
  const USER_DETAILS = {
    name: 'ADMIN',
    role: 'MIHINT_SUPERADMIN',
  };
  const [leftActiveItem, setLeftActiveItem] = useState('');
  const [fnWrapper, context] = useAxiosWrapper();
  const [reportsData, setReportsData] = useState({});
  const [ReportsArrayList, setReportsArrayList] = useState([]);

  const fetchReports = async (type, currentPage, pageSize, filter) => {
    const status_param =
      filter.status.value.length > 0 ? `&status=${filter.status.value}` : '';
    const res = await fnWrapper({
      url: `${GET_LIST_OF_REPORTS}?type=${type}&page=${currentPage}&size=${pageSize}&startDate=${filter.startDate}&endDate=${filter.endDate}${status_param}`,
      method: 'GET',
      type: 'GET_LIST_OF_REPORTS',
    });

    if (res.data.statusCode === 200) {
      setReportsData(res.data.data);
      setReportsArrayList(res.data.data.items);
    }
  };

  const setLeftSidebar = (type) => {
    setLeftActiveItem(type);
  };

  return (
    <>
      <MiHintAdminWrapper>
        <NavbarBrand1
          currentLocale={currentLocale}
          handleLocaleChange={handleLocaleChange}
          navMenu={[MIHINT_ADMIN_DASHBOARD_NAV_LIST(USER_DETAILS?.role)]}
          userProfile={USER_DETAILS}
          hasNotificationBar={false}
        />
        <div className="individual-dashboard-container">
          <div className="layout-container">
            <div className="layout-sub-container">
              <div className="sub-container-child">
                <div style={{ marginTop: '1.5rem' }}>
                  <Sidebar
                    sidebarData={leftSidebarData}
                    activeType={leftActiveItem}
                    setActiveType={setLeftSidebar}
                  />
                </div>
              </div>
              <div className="sub-container-child">
                <Routes>
                  <Route path="">
                    <Route element={<WaitList />} path="/waitlist" />
                    <Route
                      element={
                        <ProjectReports
                          context={context}
                          fetchReports={fetchReports}
                          reportsData={reportsData}
                          ReportsArrayList={ReportsArrayList}
                        />
                      }
                      path="/project-reports"
                    />
                    <Route
                      element={
                        <TeamReports
                          context={context}
                          fetchReports={fetchReports}
                          reportsData={reportsData}
                          ReportsArrayList={ReportsArrayList}
                        />
                      }
                      path="/team-reports"
                    />
                    <Route path=":type/:id/view" element={<ViewReport />} />
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </MiHintAdminWrapper>
    </>
  );
};
