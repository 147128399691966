import arrowCircle from '../../../../assests/images/arrow-circle-right.png';
import { useNavigate } from 'react-router-dom';

export const agreementTypeSetter = (agreementType) => {
  switch (agreementType) {
    case 'collab-agreements':
      return 'COLLABORATION';
    case 'bid-agreements':
      return 'BIDDING';
    case 'job-agreements':
      return 'JOB';
    default:
      return 'COLLABORATION';
  }
};

export const AgreementListAction = ({
  id,
  agreementType,
  type,
  agreementMemberId,
}) => {
  const navigate = useNavigate();
  return (
    <img
      style={{ cursor: 'pointer' }}
      src={arrowCircle}
      alt="arrow-right"
      onClick={() =>
        navigate(
          type === 'otherAgreement'
            ? `/individual/profile/other-agreement/${id}/view/details?type=${agreementTypeSetter(
                agreementType
              )}&agreementMemberId=${agreementMemberId}`
            : `/individual/profile/agreement/${id}/view?type=${agreementTypeSetter(
                agreementType
              )}`
        )
      }
    />
  );
};

export const StatusComponent = ({ status }) => {
  const upperCaseStatus = status?.toUpperCase();
  switch (upperCaseStatus) {
    case 'PENDING':
      return <span style={{ color: '#929DAF' }}>Pending</span>;
    case 'SUCCESSFULLY_CLOSED':
      return <span style={{ color: '#0ABD00' }}>Closed</span>;
    case 'ONGOING':
      return <span style={{ color: '#219FFF' }}>Ongoing</span>;
    case 'CANCELLED':
      return <span style={{ color: '#bd1f00' }}>Cancelled</span>;
    case 'IN_PROGRESS':
      return <span style={{ color: '#219FFF' }}>In progress</span>;
    default:
      return (
        <span style={{ color: '#929DAF' }}>
          {status?.replace(/_/g, ' ')?.toLowerCase()}
        </span>
      );
  }
};
