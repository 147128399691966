export const TABS = ['Collab Agreements', 'Bid Agreements', 'Job Agreements'];

export const DEFAULT_STATE = {
  selectedProject: {},
  selectCollaboration: {},
  selectBid: {},
  selectTeam: {},
  selectJob: {},
};

export const AGREEMENT_OPTIONS = [
  { label: 'Collaboration', value: 'Collaboration' },
  { label: 'Bid', value: 'Bid' },
  { label: 'Job', value: 'Job' },
];

export const AGREEMENT_STATUS_LIST = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Closed',
    value: 'SUCCESSFULLY_CLOSED',
  },
  {
    label: 'Ongoing',
    value: 'ONGOING',
  },
];

export const AGREEMENT_TABLE_HEADERS = [
  {
    title: 'Project',
    sortable: false,
    sortKey: 'project_name',
  },
  {
    title: 'Type',
    sortable: false,
    sortKey: 'type',
  },
  {
    title: 'Created On',
    sortable: true,
    sortKey: 'created_on',
  },
  {
    title: 'Status',
    sortable: false,
    sortKey: 'status',
  },
  {
    title: '',
    sortable: false,
    sortKey: 'action',
  },
];
