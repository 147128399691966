import HomeJourney1 from '../../assests/images/homepage/journey-image-1.png';
import HomeJourney2 from '../../assests/images/homepage/journey-image-2.png';
import HomeJourney3 from '../../assests/images/homepage/journey-image-3.png';
import HomeJourney4 from '../../assests/images/homepage/journey-image-4.png';
import HomeJourney5 from '../../assests/images/homepage/journey-image-5.png';
import exploreTeam from '../../assests/images/homepage/explore-team.png';
import explorePeople from '../../assests/images/homepage/explore-people.png';
import personalTeam from '../../assests/images/homepage/personal-team.png';
import enterpriseTeam from '../../assests/images/homepage/enterprise-team.png';
import collaboration1 from '../../assests/images/homepage/collaboration-1.png';
import collaboration2 from '../../assests/images/homepage/collaboration-2.png';
import collaboration3 from '../../assests/images/homepage/collaboration-3.png';
import careerJob1 from '../../assests/images/homepage/career-job-1.png';
import careerJob2 from '../../assests/images/homepage/career-job-2.png';
import bidding1 from '../../assests/images/homepage/bidding-1.png';
import bidding2 from '../../assests/images/homepage/bidding-2.png';
import bidding3 from '../../assests/images/homepage/bidding-3.png';

export const HOME_PAGE_DATA = {
  navData: {
    components: [
      {
        id: 'home',
        title: 'Home',
        url: '/',
        isDropdown: false,
      },
      {
        id: 'features',
        title: 'Features',
        url: '/features',
        isDropdown: false,
      },
      {
        id: 'solutions',
        title: 'Solutions',
        url: '/solutions',
        isDropdown: false,
      },
      {
        id: 'explore',
        title: 'Explore',
        isDropdown: true,
        dropDownItems: [
          {
            id: 'explore_teams',
            title: 'Explore Teams',
            url: '/explore/teams/search-team',
          },
          {
            id: 'explore_projects',
            title: 'Explore Projects',
            url: '/explore/projects/search-project',
          },
          {
            id: 'explore_jobs',
            title: 'Explore Jobs',
            url: '/explore/jobs/search-job',
          },
          {
            id: 'explore_networks',
            title: 'Explore Networks',
            url: '/explore/networks/search-people',
          },
        ],
      },
      {
        id: 'pricing',
        title: 'Pricing',
        url: '/pricing',
        isDropdown: false,
      },
    ],
    primaryButton: {
      title: 'Login',
      action: '/login/selectOrganization',
    },
    secondaryButton: {
      title: 'SignUp',
      action: '/signup/selectOrganization',
    },
  },
  heroContent: {
    heading:
      'Create a source of truce for your Ideas, Teams, and Projects with us',
    subHeading: 'Let’s work on the next Big Idea',
    primaryButton: {
      title: 'Get Started',
      icon: 'arrow-forward',
      action: '/signup/selectOrganization',
    },
    capsules: [
      {
        title: 'Hint',
        icon: 'search',
        color: '#FFA412',
        bgColor: '#FDEACD',
      },
      {
        title: 'Idea',
        icon: 'search',
        color: '#FFB800',
        bgColor: '#FFFFAD',
      },
      {
        title: 'Team',
        icon: 'search',
        color: '#0ABD00',
        bgColor: '#CCEFCA',
      },
      {
        title: 'Project',
        icon: 'search',
        color: '#219FFF',
        bgColor: '#D0E9FD',
      },
      {
        title: 'Product',
        icon: 'search',
        color: '#404F67',
        bgColor: '#E0E2E3',
      },
    ],
  },
  journeyContent: {
    header_icon: 'puzzle',
    heading: 'How to Get Started',
    sub_heading: 'Jump start Your Journey',
    capsules: [
      {
        id: 'section-1',
        imagePath: HomeJourney1,
        title:
          'Identify the core Hint: Revealing the Path to Product Development Success',
        content:
          'When embarking on the journey of developing a product, a well-placed hint can be a game-changer. Embrace the power of hints as you navigate the intricacies of product development, allowing them to fuel your creativity and lead you towards success. Remember, sometimes the smallest hint can unlock a world of possibilities.',
      },
      {
        id: 'section-2',
        imagePath: HomeJourney2,
        title:
          'From Hints to Hits: Unleashing the Power of Idea Conversion in Product Development',
        content:
          'Convert your identified hints into ideas for developing a dream product. Do this by carefully analyzing the hints and considering various possibilities, you can generate innovative concepts that have the potential to become successful products.',
      },
      {
        id: 'section-3',
        imagePath: HomeJourney3,
        title:
          'Synergy Unleashed: The Power of Collaboration in Product Development',
        content:
          'Create a team to brainstorm on the selected ideas can greatly enhance the creative process and bring diverse perspectives to the table. This team can include Project Managers, Designers, Developers, Engineers, Researchers and others.',
      },
      {
        id: 'section-4',
        imagePath: HomeJourney4,
        title:
          'InnovationX: Transforming Ideas into Tangible and Marketable Solutions',
        content:
          'After the ideation and brainstorming phase, the next step is to create a project plan that will give shape to your idea and set the foundation for its implementation.Stay flexible and open to adjustments throughout the process to ensure the best possible outcome.',
      },
      {
        id: 'section-5',
        imagePath: HomeJourney5,
        title: 'From Concept to Reality :)',
        content:
          'Hurray, you’ve come so far. After going through the following approach, your product is now ready for deployment and use.',
      },
    ],
  },
  modernFeatureContent: {
    header_icon: 'star-filled',
    subHeading: 'Features that make people love us',
    mainHeading: 'Most Unique & Modern Features',
  },
  modernFeature1: {
    action: '/signup/selectOrganization',
    header_icon: 'search',
    heading: 'Explore your Network & Work on big ideas together',
    items: [
      {
        heading: 'Explore People',
        description: 'Connect with People working on related Projects.',
        imgSrc: explorePeople,
      },
      {
        heading: 'Explore Team',
        description: 'Connect to a team with the right People and Projects.',
        imgSrc: exploreTeam,
      },
      {
        heading: 'Explore Project',
        description: 'Connect to Projects for their Purpose and Achievements.',
        imgSrc: HomeJourney4,
      },
    ],
  },
  modernFeature2: {
    action: '/signup/selectOrganization',
    header_icon: 'people',
    heading: 'The most effective technique to manage your team',
    items: [
      {
        heading: 'Personal Team',
        description:
          'Create your teams with members from all around the world.',
        imgSrc: personalTeam,
      },
      {
        heading: 'Enterprise Team',
        description: 'Create Internal Teams through Enterprise members',
        imgSrc: enterpriseTeam,
      },
    ],
  },
  modernFeature3: {
    action: '/signup/selectOrganization',
    header_icon: 'command',
    heading: 'Collaboration you’ll love for the jobs you don’t',
    items: [
      {
        heading: '',
        description: 'Collaboration environment between Teams',
        imgSrc: collaboration1,
      },
      {
        heading: '',
        description: 'Internal collaboration environment for teams',
        imgSrc: collaboration2,
      },
      {
        heading: '',
        description:
          'Collaboration environment between the enterprise departments',
        imgSrc: collaboration3,
      },
    ],
  },
  modernFeature4: {
    action: '/signup/selectOrganization',
    header_icon: 'brief-case',
    heading: 'Jobs are always managed on one platform',
    items: [
      {
        heading: '',
        description: 'Project-based full-time/ Freelance hiring',
        imgSrc: careerJob1,
      },
      {
        heading: '',
        description: 'Project-based hiring',
        imgSrc: careerJob2,
      },
    ],
  },
  modernFeature5: {
    action: '/signup/selectOrganization',
    header_icon: 'judge',
    heading: 'Bid on Projects with More Easy & More Efficient way',
    items: [
      {
        heading: '',
        description: 'Merge different projects',
        imgSrc: bidding1,
      },
      {
        heading: '',
        description: 'Update former projects',
        imgSrc: bidding2,
      },
      {
        heading: '',
        description: 'Bid on projects and evolve into your own project',
        imgSrc: bidding3,
      },
    ],
  },
  allFeatures: {
    header_icon: 'cube',
    subHeading: 'And that’s not all…',
    heading: 'Hire, Manage and Collaborate all in one place with miCollab.',
    items: [
      {
        iconName: 'programmingArrows',
        heading: 'Networking',
        description: [
          'Explore People- Connect with People working on related Projects',
          'Explore Teams- Connect to a team with the right People and Projects',
          'Explore Projects- Connect to Projects for their Purpose and Achievements',
        ],
        actionTitle: 'Explore',
        action: '/signup/selectOrganization',
      },
      {
        iconName: 'group-user',
        heading: 'Teams',
        description: [
          'Personal teams: Create your teams with members from all around the world',
          'Enterprise teams: Create Internal Teams through Enterprise members',
        ],
        actionTitle: 'Create Your Team',
        action: '/signup/selectOrganization',
      },
      {
        iconName: 'command',
        heading: 'Collaborations',
        description: [
          'Collaboration environment between Teams',
          'Internal collaboration environment for teams',
          'Collaboration environment between the enterprise departments',
        ],
        actionTitle: 'Start Collaborations',
        action: '/signup/selectOrganization',
      },
      {
        iconName: 'setting',
        heading: 'Development',
        description: [
          'Create a Project cycle in stages',
          'Create and involve the story of Projects [Streams]',
          'Agile Task management and personal task assignment',
        ],
        actionTitle: 'Create Project',
        action: '/signup/selectOrganization',
      },
      {
        iconName: 'connect-flow',
        heading: 'Expand Team',
        description: [
          'Project-based full-time/ Freelance hiring',
          'Project-based hiring',
        ],
        actionTitle: 'Post a Job',
        action: '/signup/selectOrganization',
      },
      {
        iconName: 'judge',
        heading: 'Bid and evolve Projects',
        description: [
          'Merge different projects',
          'Update former projects',
          'Bid on projects and evolve into your own project',
        ],
        actionTitle: 'Bid on Projects',
        action: '/signup/selectOrganization',
      },
    ],
  },
  actionHomeCard: {
    heading: 'Enterprise Team',
    description: [
      'Collaboration environment between the enterprise departments',
      'Collaboration environment between the enterprise departments',
      'Collaboration environment between the enterprise departments',
    ],
    imgSrc:
      'https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg',
    footerText: 'Start Collaboration',
  },
  subFooterCard: {
    heading: 'Revolutionize Your Workflow',
    description:
      'Unleash the Power of Next-Gen Idea and Project Management with miCollab',
    action: '/signup/selectOrganization',
  },
  footerData: {
    copyrightYear: '2024 miCollab Technologies LLC',
    components: [
      {
        id: 'privacy_policy',
        title: 'Privacy Policy',
        url: '/policies/privacy_policy',
        isDropdown: false,
      },
      {
        id: 'terms_and_conditions',
        title: 'Terms and Conditions',
        url: '/policies/terms_and_conditions',
        isDropdown: false,
      },
      {
        id: 'language',
        title: 'Language',
        isDropdown: true,
        dropDownItems: [
          {
            id: 'english',
            title: 'English',
          },
          {
            id: 'english',
            title: 'English',
          },
        ],
      },
    ],
  },
};
