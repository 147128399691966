import styled from 'styled-components';

const AddRequestModalWrapper = styled.div`
  .post-update-popup {
    position: relative;
    padding: 8px 4px;

    .popup-comment {
      .pop-up-textarea {
        width: 100%;
        min-height: 120px;
        background: #f4f7fc;
        border-radius: 4px;
        border: none;
        padding: 10px;
        :focus {
          outline: none;
        }
      }
    }

    .popup-attachment {
      margin: 20px 0px;
      display: flex;
      align-items: flex-start;
      gap: 20px;
    }

    .post-attachment {
      margin-bottom: 30px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px 20px;

      .img-wrapper {
        display: flex;
        width: 100%;
        word-break: break-all;

        span {
          color: #929daf;
        }

        .img-container {
          width: 30px;
          height: 30px;
          margin-top: 10px;
        }

        .img-text {
          margin-top: 10px;
          text-decoration: underline;
        }

        .remove-icon {
          padding-left: 2px;
          cursor: pointer;
          align-self: center;
        }
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        gap: 5px;
      }
    }

    .popup-button {
      margin: 0px auto;
      align-items: center;
      width: 150px;
    }
  }
  .task-due-date-wrapper {
    margin-top: 16px;
    height: 100px;
  }

  .task-due-date-label {
    font-weight: 600;
    font-size: 14px;
    color: #404f67;
  }

  .task-due-date {
    cursor: pointer;
    height: 52px;
    border-radius: 12px;
    border: 2px solid #e9eef4;
    padding: 17px;
    width: 100%;
  }

  .task-due-date:focus {
    outline: none;
  }
`;

export default AddRequestModalWrapper;
