import React, { useState, useEffect } from 'react';
import { ViewAgreementWrapper } from './Style';
import Icon from 'utlils/Icons/Icon';
import { CustomButton, ModalPopup } from 'components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CollaboratorCard from 'pages/IndividualDashboard/components/collaboratorCard';
import {
  FETCH_AGREEMENT_DETAILS,
  REMOVE_AGREEMENT_MEMBER,
  CLOSE_AGREEMENT,
  useAxiosWrapper,
} from 'services';
import { AddCollaboratorPopUp } from 'pages/IndividualDashboard/components/AddCollaboratorsPopUp';
import { Input } from 'components';
import { toast } from 'react-toastify';

const DEFAULT_AGREEMENT_DATA_STATE = {
  title: '',
  type: '',
  status: '',
};

export default function ViewAgreement() {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const [fnWrapper] = useAxiosWrapper();
  const [existingAgreementMembers, setExistingAgreementMembers] = useState([]);
  const [IsCloseBtnDisabled, setIsCloseBtnDisabled] = useState(true);
  const [agreementData, setAgreementData] = useState(
    DEFAULT_AGREEMENT_DATA_STATE
  );
  const [openAddCollaboratorPopup, setOpenAddCollaboratorPopup] =
    useState(false);
  const [agreementMembers, setAgreementMembers] = useState([]);
  const [filteredAgreementMembers, setFilteredAgreementMembers] = useState([]);
  const [searchText, setSearchText] = useState('');

  const [closeAgreementPopUp, setCloseAgreementPopUp] = useState(false);

  const getAgreementDetails = async () => {
    const res = await fnWrapper({
      url: `${FETCH_AGREEMENT_DETAILS}/${id}`,
      method: 'GET',
      type: 'FETCH_AGREEMENT_DETAILS',
    });
    if (res?.data?.statusCode === 200) {
      let agreementData = {};
      let agreementMembersData = [];
      switch (searchParams.get('type')) {
        case 'COLLABORATION':
          agreementData = {
            ...agreementData,
            collaborationId: res?.data?.data?.collaborationId,
            title: res?.data?.data?.project?.title,
            type: res?.data?.data?.collaboration?.collaborationType,
            status: res?.data?.data?.status,
          };
          if (res?.data?.data?.agreementMember?.length > 0) {
            let agreementMembers = res?.data?.data?.agreementMember.map(
              (item) => {
                return {
                  ...item?.collaboratorUser,
                  agreementMemberId: item?.id,
                  status: item?.status,
                };
              }
            );
            setAgreementMembers(agreementMembers);
            setFilteredAgreementMembers(agreementMembers);
            setIsCloseBtnDisabled(
              res?.data?.data?.agreementMember.some(
                (item) =>
                  item?.status !== 'MUTUALLY_CLOSED' &&
                  item.status !== 'CANCELLED'
              )
            );
          }
          agreementMembersData = res?.data?.data?.agreementMember?.map(
            (item) => {
              return item?.collaboratorUser?.id;
            }
          );
          setExistingAgreementMembers(agreementMembersData);
          return setAgreementData(agreementData);
        case 'BIDDING':
          agreementData = {
            ...agreementData,
            biddingId: res?.data?.data?.biddingId,
            title: res?.data?.data?.project?.title,
            type: res?.data?.data?.bidding?.biddingType,
            status: res?.data?.data?.status,
          };
          if (res?.data?.data?.agreementMember?.length > 0) {
            let agreementMembers = res?.data?.data?.agreementMember.map(
              (item) => {
                return {
                  ...item?.collaboratorUser,
                  agreementMemberId: item?.id,
                  status: item?.status,
                };
              }
            );
            setAgreementMembers(agreementMembers);
            setFilteredAgreementMembers(agreementMembers);
            setIsCloseBtnDisabled(
              res?.data?.data?.agreementMember.some(
                (item) =>
                  item?.status !== 'MUTUALLY_CLOSED' &&
                  item.status !== 'CANCELLED'
              )
            );
          }
          agreementMembersData = res?.data?.data?.agreementMember?.map(
            (item) => {
              return item?.collaboratorUser?.id;
            }
          );
          setExistingAgreementMembers(agreementMembersData);
          return setAgreementData(agreementData);
        case 'JOB':
          agreementData = {
            ...agreementData,
            jobId: res?.data?.data?.jobId,
            title: res?.data?.data?.job?.title,
            type: res?.data?.data?.job?.jobType,
            status: res?.data?.data?.status,
          };
          if (res?.data?.data?.agreementMember?.length > 0) {
            let agreementMembers = res?.data?.data?.agreementMember.map(
              (item) => {
                return {
                  ...item?.collaboratorUser,
                  agreementMemberId: item?.id,
                  status: item?.status,
                };
              }
            );
            setAgreementMembers(agreementMembers);
            setFilteredAgreementMembers(agreementMembers);
            setIsCloseBtnDisabled(
              res?.data?.data?.agreementMember.some(
                (item) =>
                  item?.status !== 'MUTUALLY_CLOSED' &&
                  item.status !== 'CANCELLED'
              )
            );
          }
          agreementMembersData = res?.data?.data?.agreementMember?.map(
            (item) => {
              return item?.collaboratorUser?.id;
            }
          );
          setExistingAgreementMembers(agreementMembersData);
          return setAgreementData(agreementData);
        default:
          return setAgreementData();
      }
    }
  };
  // this function can be used for both close and re-open a agreement
  const closeAgreement = async () => {
    const response = await fnWrapper({
      url: `${CLOSE_AGREEMENT}/${id}`,
      method: 'PUT',
      type: 'CLOSE_AGREEMENT',
    });
    if (response?.data?.statusCode === 200) {
      toast.success(response?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    setCloseAgreementPopUp(false);
    getAgreementDetails();
  };

  const removeAgreementMember = async (memberId) => {
    const response = await fnWrapper({
      url: `${REMOVE_AGREEMENT_MEMBER}/${memberId}`,
      method: 'PUT',
      type: 'REMOVE_AGREEMENT_MEMBER',
    });

    if (response?.data?.statusCode === 200) {
      toast.success(response?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    setFilteredAgreementMembers([]);
    setAgreementMembers([]);
    getAgreementDetails();
  };

  const onAddCollaborator = async () => {
    getAgreementDetails();
  };

  const searchTextChange = (value) => {
    setSearchText(value);
    if (value.length > 0) {
      let filteredList = agreementMembers.filter((item) =>
        item?.individual?.name.includes(value)
      );
      setFilteredAgreementMembers(filteredList);
    } else {
      setFilteredAgreementMembers(agreementMembers);
    }
  };

  useEffect(() => {
    getAgreementDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ViewAgreementWrapper>
      <div className="main-wrapper">
        <div className="heading">
          <div className="heading-data">
            <Icon
              name="arrow-back"
              color="black"
              size={25}
              onClick={() => navigate(-1)}
            />
            <div className="heading-text">{agreementData?.title}</div>
            <div>
              <span className="type">({agreementData?.type})</span>
            </div>
          </div>
          {agreementData?.status === 'PENDING' ||
          agreementData?.status === 'ONGOING' ? (
            <div className="add-collaborator-button">
              <CustomButton
                variant="light"
                disabled={IsCloseBtnDisabled}
                label="
              Close Agreement"
                onClick={() => setCloseAgreementPopUp(true)}
              />
            </div>
          ) : (
            <CustomButton
              color="themeGray"
              variant="light"
              label="Re-open agreement"
              onClick={closeAgreement}
            />
          )}
        </div>
        <div className="search-container">
          <Input
            iconLeft={true}
            iconName={'search'}
            placeholder={`Search by Name`}
            value={searchText}
            onChange={(e) => searchTextChange(e?.target?.value)}
          />
          <CustomButton
            color="themeSecondary"
            variant="light"
            onClick={() => setOpenAddCollaboratorPopup(true)}>
            <Icon name="add" size={16} color="#219FFF" />
            <Icon name="user" size={26} color="#219FFF" />
          </CustomButton>
        </div>
        <div className="content">
          {agreementMembers?.length > 0 ? (
            filteredAgreementMembers?.length > 0 ? (
              filteredAgreementMembers?.map((member, index) => (
                <CollaboratorCard
                  key={index}
                  memberData={member}
                  removeMember={(id) => removeAgreementMember(id)}
                />
              ))
            ) : (
              <p className="no-data">No search results.</p>
            )
          ) : (
            <p className="no-data">No collaborators have been added.</p>
          )}
        </div>
      </div>
      {openAddCollaboratorPopup && (
        <AddCollaboratorPopUp
          agreementStatus={agreementData?.status}
          collaborationId={agreementData?.collaborationId}
          jobId={agreementData?.jobId}
          biddingId={agreementData?.biddingId}
          existingMembersList={existingAgreementMembers}
          setOpenAddCollaboratorPopup={setOpenAddCollaboratorPopup}
          agreementType={searchParams.get('type')}
          onAddCollaborator={onAddCollaborator}
        />
      )}
      {closeAgreementPopUp ? (
        <ModalPopup
          open
          hasTitle
          title="Close Agreement"
          onClose={() => setCloseAgreementPopUp(false)}>
          <div className="close-agreement-modal">
            <p>Are you sure you want to close this agreement?</p>
            <div className="btn-wrapper">
              <CustomButton
                customPadding="16px 38px"
                variant="textBtn"
                onClick={() => setCloseAgreementPopUp(false)}
                label="No"
              />
              <CustomButton
                customPadding="16px 38px"
                label="Yes"
                onClick={() => closeAgreement()}
              />
            </div>
          </div>
        </ModalPopup>
      ) : null}
    </ViewAgreementWrapper>
  );
}
