import { useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate, useLocation } from 'react-router-dom';
import { Country } from 'country-state-city';
import { checkForSpacesInStartEnd, isValidEmail, isValidName } from 'helpers';
import Icon from 'utlils/Icons/Icon';
import {
  Box,
  CustomButton,
  CountryCityState,
  Input,
  PhoneNumberInputBox,
  Textarea,
  Title,
} from 'components';
import {
  FETCH_BASIC_DETAILS,
  CREATE_BASIC_DETAILS,
  useAxiosWrapper,
} from 'services';
import TokenService from 'services/token.service';

const DEFAULTSTATE = {
  firstName: '',
  lastName: '',
  country: null,
  state: null,
  city: null,
  email: '',
  contact: '',
  languages: '',
  linkedInLink: '',
  linkTeamsUpLink: '',
  link: [''],
  about: '',
  error: {},
};

export const CreateBasicDetails = ({
  title,
  setCurrType,
  nextType,
  isEditEvent,
  type,
  setIsUserInfoUpdated,
}) => {
  let navigate = useNavigate();
  const [fnWrapper, context] = useAxiosWrapper();
  const [state, setState] = useState(DEFAULTSTATE);
  const use_location = useLocation();
  const {
    firstName,
    lastName,
    email,
    country,
    city,
    languages,
    contact,
    linkedInLink,
    linkTeamsUpLink,
    link,
    about,
    error,
  } = state;

  const addItem = (e) => {
    const temp = { ...state };
    temp.link.push('');
    setState(temp);
  };

  const delItem = (index) => {
    const temp = { ...state };
    temp.link.splice(index, 1);
    setState(temp);
  };

  const onPhoneInputBlur = () => {
    setState({
      ...state,
      error: {
        ...error,
        contact:
          (!contact || !isValidPhoneNumber(contact)) &&
          'Please enter a valid mobile phone number.',
      },
    });
  };
  const getBasicDetails = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_BASIC_DETAILS}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_BASIC_INFO',
    });

    if (res.data.statusCode === 200) {
      const response = res.data.data;
      let name = response.name.trim().split(' ');
      let firstName = name.slice(0, name.length - 1).join(' ');
      let lastName = name[name.length - 1];
      const country = response.country
        ? Country.getCountryByCode(response.country)
        : '';

      setState({
        firstName: name.length > 1 ? firstName : name[0],
        lastName: name.length > 1 ? lastName : '',
        contact: response.contact,
        email: response.email,
        languages: response.languages || '',
        country: country
          ? {
              name: country.name,
              value: country.isoCode,
            }
          : '',
        state: response?.state
          ? {
              name: response?.state,
              value: response?.stateCode,
            }
          : '',
        city: response.city
          ? {
              name: response.city,
              value: response.city,
            }
          : '',
        link: response.link.length ? response.link : [''],
        linkedInLink: response.linkedInLink || '',
        linkTeamsUpLink: response.linkTeamsUpLink || '',
        about: response.about || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBasicDetails();
  }, [getBasicDetails]);

  const onSave = async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: CREATE_BASIC_DETAILS,
      method: 'POST',
      type: 'CREATE_BASIC_DETAILS',
      payload: {
        userId: loggedInUser.id,
        name: `${firstName} ${lastName}`,
        contact: contact,
        email: email,
        country: country?.value,
        state: state?.state?.name,
        stateCode: state?.state?.value,
        city: city?.value,
        languages: languages,
        linkedInLink: linkedInLink,
        linkTeamsUpLink: linkTeamsUpLink,
        link: link,
        about: about,
      },
    });

    if (res.data.statusCode === 200) {
      if (isEditEvent) {
        if (
          use_location.state !== null &&
          use_location.state.from === 'public_page'
        ) {
          navigate(use_location.state.redirectTo);
        } else {
          navigate(`/individual/profile/info/basic_details`);
        }
        setIsUserInfoUpdated(true);
      } else {
        setCurrType(nextType);
      }
    }
  };

  const handleInputChange = (field = '', value = null, index) => {
    //Rest the state and city so can't select different state and country
    if (field === 'country') {
      setState((state) => ({
        ...state,
        [field]: value,
        state: null,
        city: null,
      }));
      return;
    }
    if (field === 'state') {
      setState((state) => ({ ...state, [field]: value, city: null }));
      return;
    }
    if (field !== 'link') {
      setState({
        ...state,
        [field]: field === 'contact' ? value || '' : value,
        error: {
          ...error,
          [field]:
            (field === 'firstName' &&
              !isValidName(value) &&
              'Please enter a valid first name') ||
            (field === 'lastName' &&
              !isValidName(value) &&
              'Please enter a valid last name') ||
            (field === 'contact' &&
              (!contact || !isValidPhoneNumber(value || '')) &&
              'Please enter a valid mobile phone number.') ||
            (field === 'country' && !country && 'Please select country'),
        },
      });
    } else if (field === 'link') {
      const temp = { ...state };
      temp.link[index] = value;
      setState(temp);
    }
  };

  /* Disable button */
  const disabled =
    !email ||
    !isValidEmail(email) ||
    !firstName ||
    checkForSpacesInStartEnd(firstName) ||
    !contact ||
    !isValidPhoneNumber(contact) ||
    !state?.state ||
    !country ||
    !city;

  return (
    <div className="basic-detail-container">
      <Title
        title={
          <FormattedMessage id="app.BasicDetails" defaultMessage={title} />
        }
      />
      {/*  body */}
      <div className="row">
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px">
            <Input
              label="First Name"
              iconLeft={true}
              iconName="user"
              placeholder="First Name"
              type="text"
              required
              value={firstName}
              onChange={(value) =>
                handleInputChange('firstName', value.target.value)
              }
              hasError={error?.firstName}
              errorMessage={error?.firstName}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px">
            <Input
              label="Last Name"
              iconLeft={true}
              iconName="user"
              placeholder="Last Name"
              type="text"
              value={lastName}
              onChange={(value) =>
                handleInputChange('lastName', value.target.value)
              }
              hasError={error?.lastName}
              errorMessage={error?.lastName}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px">
            <Input
              label="Email"
              iconLeft={true}
              iconName="sms"
              placeholder="Email"
              type="email"
              required
              disabled
              value={email}
              onChange={(value) =>
                handleInputChange('email', value.target.value)
              }
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px">
            <PhoneNumberInputBox
              label={'Contact Number'}
              placeholder="Contact Number"
              required
              value={contact}
              onChange={(value) => handleInputChange('contact', value)}
              onBlur={onPhoneInputBlur}
              hasError={error?.contact}
              errorMessage={error?.contact}
            />
          </Box>
        </div>
        {/* <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px">
            <DropDown
              label="Country"
              items={countryList}
              objectKey="name"
              placeholder="Select"
              onClick={(item) => {
                handleInputChange('country', {
                  name: !isMobile()
                    ? truncateString(item.name, 16)
                    : truncateString(item.name, 30),
                  value: item.code,
                });
              }}
              value={country}
              hasSearchBox
              required
            />
          </Box>
        </div> */}
        {/* <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px">
            <Input
              label="City"
              placeholder="Enter city"
              type="text"
              value={city}
              onChange={(value) =>
                handleInputChange('city', value.target.value)
              }
            />
          </Box>
        </div> */}
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px">
            <Input
              label="Languages"
              iconLeft={true}
              iconName="language"
              placeholder="Languages"
              type="text"
              value={languages}
              onChange={(value) =>
                handleInputChange('languages', value.target.value)
              }
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px">
            <Input
              label="miCollab Link(if any)"
              iconLeft={true}
              iconName="link-team-up"
              placeholder="miCollab Link(if any)"
              type="text"
              error={true}
              errorMessage="Please enter valid link"
              value={linkTeamsUpLink}
              onChange={(value) =>
                handleInputChange('linkTeamsUpLink', value.target.value)
              }
            />
          </Box>
        </div>
        <CountryCityState
          className="col-md-6 col-lg-6 col-xl-4 col-sm-12"
          isCountryRequired
          isCityRequired
          isStateRequired
          onCountrySelect={(item) => handleInputChange('country', item)}
          onCitySelect={(item) => handleInputChange('city', item)}
          onStateSelect={(item) => handleInputChange('state', item)}
          selectedCountry={country}
          selectedState={state?.state}
          selectedCity={city}
        />
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px" display="flex" alignItems="center" colGap="5px">
            <Input
              label={`LinkedIn Profile Link`}
              iconLeft={true}
              iconName="linkedIn"
              placeholder="www.linkedin.com/your_name"
              type="text"
              iconColor="#949597"
              value={linkedInLink}
              onChange={(value) =>
                handleInputChange('linkedInLink', value.target.value)
              }
            />
          </Box>
        </div>
        {link.map((item, index) => (
          <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12" key={index}>
            <Box mt="10px" display="flex" alignItems="center" colGap="5px">
              <Input
                label={`Website Link ${index + 1}`}
                iconLeft={true}
                iconName="global"
                placeholder="Website, Github, Dribble..."
                type="text"
                value={item}
                onChange={(value) =>
                  handleInputChange('link', value.target.value, index)
                }
              />
              {index !== 0 ? (
                <Box cursor="pointer">
                  <Icon name="delete" onClick={() => delItem(index)} />
                </Box>
              ) : (
                <Box cursor="pointer">
                  <Icon name="add" onClick={() => addItem()} color="#0ABD00" />
                </Box>
              )}
            </Box>
          </div>
        ))}

        <div className="col-md-12 col-lg-12 col-sm-12">
          <Box mt="10px">
            <Textarea
              rows={4}
              cols={50}
              label="About me"
              placeholder="About me"
              value={about}
              onChange={(value) => {
                handleInputChange('about', value.target.value);
              }}
            />
          </Box>
        </div>
      </div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="15px"
        mt="30px">
        <Box width="120px">
          <CustomButton
            className="w-100"
            label="Save"
            onClick={onSave}
            disabled={disabled}
            loading={context.busy.status}
          />
        </Box>
        <Box width="120px">
          <CustomButton
            className="w-100"
            variant="textBtn"
            color="themeGray"
            onClick={() => {
              setCurrType(nextType);
              navigate(`/individual/profile/${type}/${nextType}`);
            }}
            loading={context.busy.status}>
            Next
            <Icon name="arrow-forward" />
          </CustomButton>
        </Box>
      </Box>
    </div>
  );
};
