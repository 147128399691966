import styled from 'styled-components';

const RequestCommentWrapper = styled.div`
  .comments-wrapper {
    margin: 0px 8px;
    .comment-input_area {
      margin-top: 15px;
      display: flex;
      align-items: center;
      gap: 20px;
      padding-bottom: 25px;

      .pfp-alt-circle {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        background-color: #d6f3f9;
        display: flex;
        align-items: center;
        justify-content: center;
        .pfp-alt {
          border-radius: 50%;
          font-weight: bold;
        }
      }

      .comment-input_box {
        width: 100%;
        padding: 2px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #e9eef4;
        border-radius: 60px;

        .comment-input {
          flex-grow: 1;
          color: #929daf;
          font-size: 14px;
          font-weight: 400;
          border: none;
          background: none;
          padding: 0 10px;
          &:focus {
            outline: none;
          }
          @media (max-width: 576px) {
            width: 100%;
          }
        }
        .send-comment-icon {
          &:hover {
            cursor: pointer;
          }
        }
        @media (max-width: 576px) {
          width: 85%;
        }
      }
      @media (max-width: 576px) {
        gap: 2px;
      }
    }
    @media (max-width: 576px) {
      margin: 0 2px;
    }
    .display-comment-wrapper {
      padding: 15px;
      margin: 10px 0;
      background: #e9eef4;
      border-radius: 10px;
      .display-comment-header {
        display: flex;
        align-items: center;
        gap: 12px;
        @media (max-width: 576px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
        .commenter-info {
          flex-grow: 1;
          display: flex;
          align-items: center;
          gap: 20px;
          justify-content: flex-start;
          .pfp-text-circle {
            border-radius: 50%;
            width: 45px;
            height: 45px;
            background-color: #d6f3f9;
            display: flex;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            align-items: center;
            justify-content: center;
            .pfp-text {
              border-radius: 50%;
              font-weight: bold;
            }
            .user-icon-class {
              height: 100%;
              width: 100%;
            }
          }
          .commenter-pic {
            object-fit: cover;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            background: #c4c4c4;
          }
          .commenter-name {
            color: #17191d;
            font-weight: 600;
            font-size: 14px;
          }
        }
        .comment-options {
          display: flex;
          height: 100%;
          justify-content: flex-end;
          align-items: center;
          gap: 20px;
          color: '#929daf';
          font-size: 12px;
          @media (max-width: 576px) {
            width: 100%;
          }
        }

        .commenter-profile-pic {
          object-fit: cover;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          background: #c4c4c4;
        }
      }
      .display-comment-content {
        padding: 6px 4px 4px 4px;
        color: #929daf;
      }
    }
  }
`;

export default RequestCommentWrapper;
