import React, { useState } from 'react';
import styled from 'styled-components';
import UserImg from 'assests/images/user.png';
import { useNavigate, useParams } from 'react-router-dom';
import { getDateTime } from 'utlils/utils';
import Icon from 'utlils/Icons/Icon';
export default function ReportsDataCard(props) {
  const { user, updatedAt, title, reason } = props;
  const { type } = useParams();
  const [expandReport, setExpandReport] = useState(false);
  const navigate = useNavigate();
  return (
    <ReportsDataCardWrapper>
      <div className="user-data-container">
        <div className="user-data">
          <div className="image-container">
            <img
              className="image"
              src={
                user?.individual?.profilePicture
                  ? user?.individual?.profilePicture
                  : UserImg
              }
              alt="img"
            />
          </div>
          <div className="info">
            <div className="details">
              <span
                className="name"
                onClick={() =>
                  navigate(
                    user.smallEnterprise === null
                      ? `/public/individual/${user?.id}/summary/basic-details`
                      : `/public/enterprise/${user?.smallEnterprise?.id}/details`
                  )
                }>
                {user.smallEnterprise === null
                  ? user?.individual?.name
                  : user?.smallEnterprise?.enterpriseName}
              </span>{' '}
              has reported {title} &nbsp;
              {type === 'project-reports' ? 'project' : 'team'}.
              <div className="updated-at">{getDateTime(updatedAt)}</div>
            </div>
          </div>
        </div>
        <div className="expand">
          <Icon
            name={expandReport ? 'arrow-circle-up' : 'arrow-circle-down'}
            color="#404F67"
            style={{ cursor: 'pointer' }}
            size="36"
            onClick={() => {
              setExpandReport(!expandReport);
            }}
          />
        </div>
      </div>
      {expandReport && (
        <div className="reason">
          <span className="reason-text">Reason:</span> {reason}
        </div>
      )}
    </ReportsDataCardWrapper>
  );
}

const ReportsDataCardWrapper = styled.div`
  margin-bottom: 10px;
  & .user-data-container {
    padding: 20px;
    border: 1px solid #929daf;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    & .user-data {
      display: flex;
      gap: 10px;
      & .image-container {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & .image {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
      & .info {
        & .details {
          font-size: 16px;
          & .name {
            font-weight: 600;
            cursor: pointer;
          }
          & .updated-at {
            color: #929daf;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
  & .reason {
    background-color: #d3dbe7;
    padding: 10px 30px 10px 30px;
    margin: 0px 30px 10px 30px;
    border-radius: 0px 0px 20px 20px;
    & .reason-text {
      font-weight: 600;
    }
  }
`;
