import React from 'react';
import { CollaboratorCardWrapper } from './style';
import image from '../../../../assests/images/public-bg.png';
import Icon from 'utlils/Icons/Icon';
import { useNavigate, useParams } from 'react-router-dom';
import { StatusComponent } from 'pages/IndividualDashboard/IndividualAgreements/YourAgreements/helper';
export default function CollaboratorCard({ memberData, removeMember }) {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <CollaboratorCardWrapper>
      <div className="wrapper">
        <div
          className="user-detail"
          onClick={() => {
            navigate(
              `/individual/profile/agreements/communication/request/${id}?memberName=${memberData?.individual?.name}&agreementMemberId=${memberData?.agreementMemberId}`
            );
          }}>
          <div className="image-container">
            <img
              className="image"
              src={
                memberData?.individual?.profilePicture
                  ? memberData?.individual?.profilePicture
                  : image
              }
              alt="user"
            />
          </div>
          <div className="user-data">
            <div className="name">{memberData?.individual?.name}</div>
            <div className="role-link">
              <div className="role">
                {memberData?.individual?.title
                  ? memberData?.individual?.title
                  : 'Agreement Member'}
              </div>
              <div className="link">{memberData?.email}</div>
            </div>
          </div>
        </div>
        <div className="remove-btn">
          <span className="status">
            <StatusComponent status={memberData?.status} />
          </span>
          {memberData?.status === 'PENDING' && (
            <Icon
              onClick={() => removeMember(memberData?.agreementMemberId)}
              name="log-out"
              size={30}
              color="#E666578C"
            />
          )}
        </div>
      </div>
    </CollaboratorCardWrapper>
  );
}
