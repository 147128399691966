import React, { useState } from 'react';
import { CustomButton, ModalPopup } from 'components';
import CloseDealModalWrapper from './styles';

const STATUS = ['REJECTED', 'APPROVED'];

export const CloseDealModal = (props) => {
  const { closePopUp, closeDeal } = props;
  const [step, setStep] = useState(0);
  return (
    <ModalPopup
      open={false}
      onClose={() => closePopUp()}
      hasTitle
      title="Close Deal">
      <CloseDealModalWrapper>
        {step === 0 && (
          <>
            <p>Please choose how do you want to proceed with the deal:</p>
            <div>
              <p>
                <b>Reject and Cancel:</b> Reject the deal due to the rejected
                document, which may notify the other party and prevent the deal
                from moving forward.
              </p>
              <p>
                <b>Approve with exceptions:</b> Approve the deal despite the
                rejected document, potentially allowing the deal to move forward
                with modifications or exceptions.
              </p>
            </div>
            <div className="btns-group">
              <CustomButton
                color="themeTertiary"
                variant="textBtn"
                onClick={() => setStep(1)}
                label="
                Reject and Cancel"
              />
              <CustomButton
                onClick={() => setStep(2)}
                variant="textBtn"
                label="Approve with exceptions"
              />
            </div>
          </>
        )}
        {step === 1 && (
          <>
            <p className="steps-p">
              Are you sure you want to close this deal by rejecting and
              cancelling it?
            </p>
            <div className="btns-group">
              <CustomButton
                customPadding="16px 38px"
                variant="textBtn"
                label="No"
                onClick={() => closePopUp()}
              />
              <CustomButton
                customPadding="16px 38px"
                label="Yes"
                onClick={() => closeDeal(STATUS[0])}
              />
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <p className="steps-p">
              Are you sure you want to close this deal by accepting it with
              exceptions?
            </p>
            <div className="btns-group">
              <CustomButton
                customPadding="16px 38px"
                variant="textBtn"
                label="No"
                onClick={() => closePopUp()}
              />
              <CustomButton
                customPadding="16px 38px"
                label="Yes"
                onClick={() => closeDeal(STATUS[1])}
              />
            </div>
          </>
        )}
      </CloseDealModalWrapper>
    </ModalPopup>
  );
};
