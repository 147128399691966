import { useState } from 'react';
import { ModalPopup } from 'components';
import { Textarea, CustomButton } from 'components';
import Icon from 'utlils/Icons/Icon';
import { toast } from 'react-toastify';
import { AttachmentsTypes } from 'pages/IndividualDashboard/constants';
import { checkForFileType } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import AddRequestModalWrapper from './styles';
import ReactDatePicker from 'react-datepicker';
import { CustomFileUploadButton } from 'components';
const DEFAULT_POST_STATE = {
  message: '',
  date: '',
};

export const AddRequestModal = (props) => {
  const { closePopUp, createAgreement } = props;
  const [attachment, setAttachment] = useState({});
  const [postState, setPostState] = useState(DEFAULT_POST_STATE);
  const isBtnDisabled = () => {
    return !postState.message;
  };

  const onChangeHandler = (value = '', label = '') => {
    setPostState((prev) => ({
      ...prev,
      [label]: value,
    }));
  };

  const attachmentsHandler = async (files) => {
    if (AttachmentsTypes.includes(files[0].type)) {
      setAttachment(files[0]);
    } else {
      toast.error(`File type of ${files[0].type} is not supported.`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const postUpdate = async () => {
    let data = {
      message: postState.message,
      dueDate: postState?.date,
      attachments: attachment,
    };
    createAgreement(data);
  };

  return (
    <AddRequestModalWrapper>
      <ModalPopup
        open={false}
        onClose={() => closePopUp()}
        hasTitle
        title="Add">
        <div className="post-update-popup">
          <div className="popup-comment">
            <Textarea
              required
              label={`Message for collaborator`}
              placeholder={'Enter your message here'}
              value={postState?.message}
              onChange={(e) => onChangeHandler(e.target.value, 'message')}
              rows={4}
            />
          </div>
          <div>
            <div className="col-lg-6 task-due-date-wrapper">
              <label className="task-due-date-label form-label">Due Date</label>
              <ReactDatePicker
                className="task-due-date"
                dateFormat="dd/MM/yyyy"
                onChange={(date) => onChangeHandler(date, 'date')}
                minDate={new Date()}
                placeholderText="Select due date"
                selectsStart
                selected={postState?.date}
              />
            </div>
          </div>
          <div className="popup-attachment">
            <p>Add Attachments</p>
            <CustomFileUploadButton
              borderWidth="0px 0px 1px 0px"
              borderStyle="solid"
              borderColor="#219FFF"
              background="none"
              fontFamily="'Poppins', 'Open Sans'"
              fontSize="15px"
              fontWeight="500"
              textColor="#219FFF"
              handleFile={(file) => attachmentsHandler(file)}
              label="Add"
              iconColor="#219FFF"
              iconName="add"
              iconSize="18"
              isMultiple={true}
            />
          </div>
          <div className="post-attachment">
            {attachment.name ? (
              <div className="img-wrapper">
                <div className="img-container">
                  {checkForFileType(attachment?.name) === 'others' ? (
                    <Icon name="attachment" />
                  ) : (
                    <img
                      src={checkForFileType(attachment?.name)}
                      alt="attachment"
                    />
                  )}
                </div>
                <span className="img-text">{attachment?.name}</span>
                <span className="remove-icon" onClick={() => setAttachment({})}>
                  <Icon color="rgba(146, 157, 175, 1)" name="small-close" />
                </span>
              </div>
            ) : null}
          </div>
          <div className="popup-button">
            <CustomButton
              className="w-100"
              label="Add"
              color="themeSecondary"
              onClick={postUpdate}
              disabled={isBtnDisabled()}
            />
          </div>
        </div>
      </ModalPopup>
    </AddRequestModalWrapper>
  );
};
