export const MIHINT_ADMIN_DASHBOARD_NAV_LIST = (role) => [
  // {
  //   id: 'home',
  //   title: 'Home',
  //   url:
  //     role === 'INDIVIDUAL'
  //       ? '/individual/profile/info/basic_details'
  //       : '/enterprise/profile/view/details',
  //   isDropdown: false,
  // },
  // {
  //   id: 'explore',
  //   title: 'Explore',
  //   isDropdown: true,
  //   dropDownItems: [
  //     {
  //       id: 'explore_teams',
  //       title: 'Explore Teams',
  //       url: '/explore/teams/search-team',
  //     },
  //     {
  //       id: 'explore_projects',
  //       title: 'Explore Projects',
  //       url: '/explore/projects/search-project',
  //     },
  //     {
  //       id: 'explore_jobs',
  //       title: 'Explore Jobs',
  //       url: '/explore/jobs/search-job',
  //     },
  //     {
  //       id: 'explore_networks',
  //       title: 'Explore Networks',
  //       url: '/explore/networks/search-people',
  //     },
  //   ],
  // },
  {},
];

export const leftSidebarData = [
  // {
  //   type: 'dashboard',
  //   url: '/miHintAdmin/dashboard/',
  //   iconName: 'publications',
  //   title: 'Dashboard',
  //   hasSubMenu: false,
  // },
  {
    type: 'waitlist',
    url: '/miHintAdmin/waitlist',
    iconName: 'publications',
    title: 'Waitlist',
    hasSubMenu: false,
  },
  {
    type: 'Reports',
    url: '',
    iconName: 'document-text',
    title: 'Reports',
    hasSubMenu: true,
    elements: [
      {
        type: 'project',
        title: 'Project',
        url: `/miHintAdmin/project-reports`,
      },
      {
        type: 'team',
        title: 'Team',
        url: `/miHintAdmin/team-reports`,
      },
    ],
  },
];

export const TYPES = {
  TEAM_DASHBOARD: 'TEAM_DASHBOARD',
  PERSONAL: 'PERSONAL',
  ENTERPRISE: 'ENTERPRISE',
  ENTERPRISE_DASHBOARD: 'ENTERPRISE_DASHBOARD',
};

export const REPORT_STATUS_TYPES = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'ACTIVE',
    label: 'Pending',
  },
  {
    value: 'BLOCKED',
    label: 'Blocked',
  },
];
