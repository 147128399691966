import React from 'react';
import { BiddingApplicationWrapper } from './style';
import Icon from 'utlils/Icons/Icon';
import { CustomButton } from 'components';

export default function BiddingApplication(props) {
  const { questions, applicantDocuments } = props;
  let document =
    applicantDocuments.length > 0 && JSON.parse(applicantDocuments[0]);
  console.log(questions);
  return (
    <BiddingApplicationWrapper>
      <div className="questions">
        {questions &&
          Object.keys(questions).map((question) => (
            <>
              <div className="question-heading">{question}</div>
              <div className="answer">{questions[question]}</div>
            </>
          ))}
      </div>
      {applicantDocuments.length > 0 && (
        <div className="your-documents">
          <div className="your-documents-heading">Your Documents</div>
          <a href={document?.location} className="link">
            <div className="link-content-wrapper">
              <div className="icon-name">
                <Icon name="attachment" />
                <span>
                  {document.location.split('/').slice(-1)[0].split('_')[0]}.
                  {document.location.split('.').slice(-1)}
                </span>
              </div>
              <CustomButton variant="textBtn">
                Download
                <Icon name="download-box" color="#0ABD00" size={16} />
              </CustomButton>
            </div>
          </a>
        </div>
      )}
      {questions === null && applicantDocuments.length === 0 && (
        <div className="no-data-available">
          You have applied for this bidding
        </div>
      )}
    </BiddingApplicationWrapper>
  );
}
