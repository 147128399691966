import styled from 'styled-components';

export const AgreementFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 22;
  top: auto;
  bottom: auto;
  padding: 12px 16px;
  text-align: left;

  .filter-title-wrapper {
    border-bottom: 1.5px solid #e9eef4;
    height: 60px;

    .filter-title {
      padding: 20px 30px;
      font-weight: 500;
      font-size: normal;
      color: #17191d;
    }
  }

  .btn-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px 12px;
  }
`;
