import { useState, useCallback, useEffect } from 'react';
import { Loader } from 'components';
import { useParams, useNavigate } from 'react-router-dom';
import { FETCH_PUBLIC_PROJECT_SERVICE, useAxiosWrapper } from 'services';
import { CustomButton, ModalPopup } from 'components';
import Icon from 'utlils/Icons/Icon';
import tokenService from 'services/token.service';

export const ProjectPublicDetails = (props) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [projectDetails, setProjectDetails] = useState({});
  const [projectData, setProjectData] = useState(null);
  const [showBlockedPopup, setShowBlockedPopup] = useState(false);
  const { projectId } = useParams();
  const loggedInUser = tokenService.getSession();
  const navigate = useNavigate();

  const getProjectDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PUBLIC_PROJECT_SERVICE}/${projectId}?st=details`,
      method: 'GET',
      type: 'FETCH_PROJECT_DETAILS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data.data;
      const isBlocked = result?.projectState === 'BLOCKED';
      setShowBlockedPopup(isBlocked);
      setProjectData(result);
      setProjectDetails(
        result?.publishProjects !== null ? result?.publishProjects?.FAQ : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    getProjectDetails();
  }, [getProjectDetails]);

  return (
    <div className="main-wrapper">
      <div className="heading">Details</div>
      {context.busy.status ? (
        <Loader loading={context.busy.status} />
      ) : (
        <div className="content">
          {projectData?.problemStatement ? (
            <div className="details-item">
              <p className="details-item-label">Problem Statement</p>
              <div className="details-item-value">
                {projectData?.problemStatement}
              </div>
            </div>
          ) : null}
          {projectData?.solution ? (
            <div className="details-item">
              <p className="details-item-label">
                What are we doing to solve this problem?
              </p>
              <div className="details-item-value">{projectData?.solution}</div>
            </div>
          ) : null}
          {projectData?.link ? (
            <div className="details-item">
              <p className="details-item-label">Link for Reference</p>
              <div className="details-item-value">{projectData?.link}</div>
            </div>
          ) : null}
          {projectDetails !== null
            ? Object.keys(projectDetails).map((key, index) => {
                return (
                  <div className="details-item" key={index}>
                    <p className="details-item-label">{key}</p>
                    <div className="details-item-value">
                      {projectDetails[key]}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      )}
      {showBlockedPopup && (
        <ModalPopup
          popupWrapperClass="project-blocked-popup-wrapper"
          open={false}
          hasCloseIcon={false}
          title="Project Blocked Due to Multiple Issues">
          <div className="project-blocked-popup">
            <Icon name="reject" size={100} />
            <p>
              This project has been <span>Blocked</span> due to multiple issues
              and concerns that have arisen. The decision to block the project
              was made after careful consideration of various factors to ensure
              the integrity and safety of our platform. If you still want to
              proceed, please reach out to our support team for further
              assistance.
            </p>
            <CustomButton
              color="themeSecondary"
              onClick={() =>
                navigate(
                  loggedInUser.role === 'SMALL_ENTERPRISE'
                    ? '/enterprise/profile/view/details'
                    : `/explore/projects/search-project`
                )
              }
              label="Continue"
            />
          </div>
        </ModalPopup>
      )}
    </div>
  );
};
