import styled from 'styled-components';

export const JobDetailsWrapper = styled.div`
  & .description-heading {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
  }
  & .description {
    font-weight: 400;
    font-size: 16px;
    color: rgba(64, 79, 103, 0.8);
  }
  & .Job-type-heading {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
  }
  & .Job-type {
    font-weight: 400;
    font-size: 16px;
    color: rgba(64, 79, 103, 0.8);
  }
  & .designation-heading {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
  }
  & .designation {
    font-weight: 400;
    font-size: 16px;
    color: rgba(64, 79, 103, 0.8);
  }
  & .skills-heading {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
  }
  & .skills {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  & .mode-heading {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
  }
  & .mode {
    font-weight: 400;
    font-size: 16px;
    color: rgba(64, 79, 103, 0.8);
  }
`;
