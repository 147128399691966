import React from 'react';
import { JobDetailsWrapper } from './style';
import { CustomButton } from 'components';

export default function JobDetails(props) {
  const { details } = props;
  return (
    <JobDetailsWrapper>
      <div className="description-heading">Job description</div>
      <div
        className="description"
        dangerouslySetInnerHTML={{
          __html: details?.appliedJob?.jobDescription,
        }}></div>
      <br />
      <div className="Job-type-heading">Job type</div>
      <div className="Job-type">{details?.appliedJob?.jobType}</div>
      <br />
      <div className="designation-heading">Designation</div>
      <div className="designation">{details?.appliedJob?.designation}</div>
      <br />
      {details?.technologiesList && (
        <>
          {details?.technologiesList.length > 0 && (
            <>
              <div className="skills-heading">Skills</div>
              <div className="skills">
                {details?.technologiesList.map((list, index) => (
                  <CustomButton
                    key={index + 1}
                    variant="light"
                    color="themeSecondary"
                    label={list?.name}
                  />
                ))}
              </div>
            </>
          )}
          <br />
        </>
      )}
      <div className="mode-heading">Mode</div>
      <div className="mode">{details?.appliedJob?.workEnvironment}</div>
      <br />
    </JobDetailsWrapper>
  );
}
