import { useCallback, useEffect, useState } from 'react';
import { ModalPopup, Input, CustomButton } from 'components';
import AddCollaboratorCard from '../addCollaboratorCard';
import {
  GET_COLLABORATION_APPLICANT_LIST,
  GET_BIDDING_APPLICANTS_LIST,
  ADD_AGREEMENT_MEMBERS,
  useAxiosWrapper,
  FETCH_APPLICANT_DETAILS_BASED_ON_JOBID,
} from 'services';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const AddCollaboratorPopUp = (props) => {
  const {
    agreementStatus,
    setOpenAddCollaboratorPopup = () => {},
    agreementType,
    collaborationId,
    onAddCollaborator,
    jobId,
    biddingId,
    existingMembersList,
  } = props;
  const [membersList, setMembersList] = useState([]);
  const [filteredMemberList, setFilteredMemberList] = useState([]);
  const [fnWrapper] = useAxiosWrapper();
  const [searchText, setSearchText] = useState('');
  const { id } = useParams();
  const getCollaborationApplicantList = useCallback(async () => {
    let payload = {
      collaborationId,
      status: 'ACCEPTED',
    };
    const res = await fnWrapper({
      url: GET_COLLABORATION_APPLICANT_LIST,
      method: 'GET',
      type: 'GET_COLLABORATION_APPLICANT_LIST',
      params: payload,
    });
    if (res.data.statusCode === 200) {
      const result = res?.data?.data;
      // eslint-disable-next-line array-callback-return
      let resData = result
        .filter(
          (item) =>
            !existingMembersList?.includes(item?.collaborationApplicant?.id)
        )
        .map((item) => {
          return {
            id: item?.collaborationApplicant?.id,
            name: item?.collaborationApplicant?.individual?.name,
            profilePicture:
              item?.collaborationApplicant?.individual?.profilePicture,
            title: item?.collaborationApplicant?.individual?.title,
            selected: false,
          };
        });
      setMembersList(resData);
      setFilteredMemberList(resData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaborationId]);

  const getJobApplicantList = useCallback(
    async () => {
      let payload = {
        jobId: jobId,
        status: 'SELECTED',
      };
      const res = await fnWrapper({
        url: `${FETCH_APPLICANT_DETAILS_BASED_ON_JOBID}`,
        method: 'GET',
        params: payload,
        type: 'FETCH_APPLICANT_DETAILS_BASED_ON_JOBID',
      });
      if (res.data.statusCode === 200) {
        const result = res?.data?.data;
        // eslint-disable-next-line array-callback-return
        let resData = result
          .filter((item) => !existingMembersList?.includes(item?.applicant?.id))
          .map((item) => {
            return {
              id: item?.applicant?.id,
              name: item?.applicant?.individual?.name,
              profilePicture: item?.applicant?.individual?.profilePicture,
              title: item?.applicant?.individual?.title,
              selected: false,
            };
          });
        setMembersList(resData);
        setFilteredMemberList(resData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobId]
  );

  const getBiddingApplicantList = useCallback(
    async () => {
      let payload = {
        biddingId,
        status: 'ACCEPTED',
      };
      const res = await fnWrapper({
        url: `${GET_BIDDING_APPLICANTS_LIST}/`,
        method: 'GET',
        type: 'GET_BIDDING_APPLICANT_LIST',
        params: payload,
      });
      if (res.data.statusCode === 200) {
        const result = res?.data?.data;
        // eslint-disable-next-line array-callback-return
        let resData = result
          .filter(
            (item) => !existingMembersList?.includes(item?.biddingApplicant?.id)
          )
          .map((item) => {
            return {
              id: item?.biddingApplicant?.id,
              name: item?.biddingApplicant?.individual?.name,
              profilePicture:
                item?.biddingApplicant?.individual?.profilePicture,
              title: item?.biddingApplicant?.individual?.title,
              selected: false,
            };
          });
        setMembersList(resData);
        setFilteredMemberList(resData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [biddingId]
  );

  const addCollaborationMembers = useCallback(
    async (collaboratorIds) => {
      let payload = {
        agreementId: id,
        collaboratorIds,
      };
      const res = await fnWrapper({
        url: ADD_AGREEMENT_MEMBERS,
        method: 'POST',
        type: 'ADD_AGREEMENT_MEMBERS',
        payload,
      });
      if (res?.data?.statusCode === 200) {
        toast.success(res?.data?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setMembersList([]);
        setFilteredMemberList([]);
        onAddCollaborator();
        setOpenAddCollaboratorPopup(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collaborationId]
  );

  useEffect(() => {
    fetchAgreementMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAgreementMembers = useCallback(() => {
    switch (agreementType) {
      case 'COLLABORATION':
        getCollaborationApplicantList();
        break;
      case 'BIDDING':
        getBiddingApplicantList();
        break;
      case 'JOB':
        getJobApplicantList();
        break;
      default:
        break;
    }
  }, [agreementType]);

  const toggleSelectCollaborator = (id) => {
    setFilteredMemberList(
      filteredMemberList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            selected: !item.selected,
          };
        }
        return item;
      })
    );
  };

  const onSearchChange = (value) => {
    setSearchText(value);
    if (value.length > 0) {
      let filteredList = membersList.filter((item) =>
        item?.name.includes(value)
      );
      setFilteredMemberList(filteredList);
    } else {
      setFilteredMemberList(membersList);
    }
  };

  const submitCollaboratorsList = () => {
    let collaboratorsList = [];
    filteredMemberList.forEach((item) => {
      if (item?.selected) {
        collaboratorsList.push(item?.id);
      }
    });
    if (collaboratorsList.length > 0) {
      addCollaborationMembers(collaboratorsList);
    }
  };
  return (
    <>
      <ModalPopup
        open={false}
        title={'Add collaborator'}
        onClose={() => setOpenAddCollaboratorPopup(false)}>
        {agreementStatus !== 'SUCCESSFULLY_CLOSED' ? (
          <>
            <Input
              value={searchText}
              iconLeft={true}
              onChange={(e) => onSearchChange(e?.target?.value)}
              iconName={'search'}
              placeholder={`Search ${agreementType?.toLowerCase()}`}
            />
            <div className="collaborator-list">
              {membersList?.length > 0 ? (
                filteredMemberList?.length > 0 ? (
                  filteredMemberList.map((item, index) => (
                    <AddCollaboratorCard
                      key={index}
                      memberData={item}
                      onSelectClick={(id) => toggleSelectCollaborator(id)}
                    />
                  ))
                ) : (
                  <p className="no-data">No search results found</p>
                )
              ) : (
                <p className="no-data">No members Found</p>
              )}
            </div>
            <div className="submit-btn">
              <CustomButton
                onClick={submitCollaboratorsList}
                label="Add collaborator"
                color="themeSecondary"
              />
            </div>
          </>
        ) : (
          <div className="already-closed-case">
            This agreement is already closed!
          </div>
        )}
      </ModalPopup>
    </>
  );
};
