import styled from 'styled-components';

export const CollaboratorCardWrapper = styled.div`
  padding: 15px;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  & .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    & .user-detail {
      display: flex;
      gap: 8px;
      cursor: pointer;
      align-items: center;
      width: 80%;
      & .image-container {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        & .image {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
      & .user-data {
        & .name {
          font-size: 15px;
          font-weight: 500;
          color: rgba(23, 25, 29, 1);
        }
        & .role-link {
          display: flex;
          font-size: 12px;
          font-weight: 400;
          color: rgba(64, 79, 103, 1);
          & .role {
            padding-right: 10px;
            border-right: 1px solid rgba(64, 79, 103, 1);
          }
          & .link {
            padding-left: 10px;
          }
        }
      }
    }
    .remove-btn {
      display: flex;
      gap: 16px;
      .status {
        font-family: Poppins;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        color: #929daf;
      }
    }
  }
`;
