import styled from 'styled-components';

const RequestMessageCardWrapper = styled.div`
  & .request {
    display: flex;
    flex-direction: column;
    font-family: Poppins;
    background-color: #f4f7fc;
    text-align: left;
    gap: 8px;
    padding: 14px 16px;
    border-radius: 18px;
    .card-header-action {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .user-info {
        display: flex;
        gap: 8px;
        .user-details {
          display: flex;
          flex-direction: column;
          gap: 0px;
          line-height: 10px;
          .user-name {
            font-size: 16px;
            font-weight: 500;
            color: #040d20;
          }
          .time {
            font-size: 14px;
            font-weight: 400;
            color: #929daf;
          }
        }
        .profile-round-img {
          border-radius: 50%;
          height: 40px;
          width: 40px;
          display: flex;
          place-content: center;
          background-color: #d6f3f9;
          .prof-img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
          .prof-txt {
            font-size: 18px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .options {
        font-family: Poppins;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 10px;
        .three-dot-option {
          cursor: pointer;
          position: relative;
          .dropdown-container-request {
            font-size: 14px;
          }
        }
      }
    }
    .user-message {
      font-size: 14px;
      font-weight: 400;
      line-height: 21.75px;
      color: #404f67;
    }

    .user-docs {
    }

    .user-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & .comment-sub-request-options {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .due-date {
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 21.75px;
        color: #404f6799;

        .date {
          font-weight: 500;
          color: #bd1f00c4;
        }
      }
    }
    .aprroveRejectPopUp {
      display: flex;
      flex-direction: column;
      gap: 12px;
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      color: #404f67;
      padding: 16px 12px;
      .btns {
        display: flex;
        gap: 8px;
        justify-content: center;
      }
    }
  }
  & .sub-request-wrapper {
    padding: 0px 30px;
    & .sub-requests {
      border: 1px solid #c4c4c4;
      border-top: none;
      padding-top: 30px;
      border-radius: 0px 0px 20px 20px;
    }
    & .no-data {
      display: grid;
      place-content: center;
      font-size: 16px;
      font-family: 'Poppins', 'Open Sans';
    }
  }
  & .save-btn {
    margin-top: 15px;
    display: grid;
    place-content: center;
  }
`;

export default RequestMessageCardWrapper;
