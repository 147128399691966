import { CustomButton } from 'components';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';
import { DropDown } from 'components';
import { REPORT_STATUS_TYPES } from '../constants';

export default function ReportFilter(props) {
  const {
    filterTitle,
    state,
    projectFilterOnChangeHandler,
    FilterSaveHandler,
  } = props;
  return (
    <FilterWrapper>
      <div className="projects-filter-container">
        <div className="filter-title-wrapper">
          <div className="filter-title">{filterTitle}</div>
        </div>
        <div className="filter-input-container">
          <div className="row">
            <div className="col-lg-6">
              <DropDown
                label="Report Status"
                items={REPORT_STATUS_TYPES}
                placeholder={REPORT_STATUS_TYPES[0].label}
                value={state.status}
                onClick={(data) => projectFilterOnChangeHandler('status', data)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 filter-date-wrapper">
              <label className="filter-date-label form-label">Start Date</label>
              <ReactDatePicker
                dateFormat="MMM dd, yyyy"
                selected={state.startDate}
                onChange={(date) =>
                  projectFilterOnChangeHandler('startDate', date)
                }
                selectsStart
                placeholderText="From"
                className="filter-custom-date"
              />
            </div>
            <div className="col-lg-6 filter-date-wrapper">
              <label className="filter-date-label form-label">End Date</label>
              <ReactDatePicker
                dateFormat="MMM dd, yyyy"
                selected={state.endDate}
                onChange={(date) =>
                  projectFilterOnChangeHandler('endDate', date)
                }
                selectsEnd
                startDate={state.startDate}
                endDate={state.endDate}
                placeholderText="To"
                className="filter-custom-date"
              />
            </div>
          </div>
          <div className="row gy-3">
            <div className="col-lg-6">
              <CustomButton
                className="w-100"
                label="Apply"
                color="themeSecondary"
                onClick={() => FilterSaveHandler('Apply')}
              />
            </div>
            <div className="col-lg-6">
              <CustomButton
                onClick={() => FilterSaveHandler('Cancel')}
                className="w-100"
                label="Cancel"
                variant="light"
                color="themeSecondary"
              />
            </div>
          </div>
        </div>
      </div>
    </FilterWrapper>
  );
}

const FilterWrapper = styled.div`
  .projects-filter-container {
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.06);
    right: 0;
    position: absolute;
    z-index: 22;
    width: 40%;

    .filter-title-wrapper {
      border-bottom: 1.5px solid $gray-5;
      height: 60px;

      .filter-title {
        padding: 20px 30px;
        font-weight: $fw_500;
        font-size: $fs_normal;
        color: $gray-1;
      }
    }

    .filter-input-container {
      padding: 20px 30px;

      .filter-input-fields {
        display: flex;
        align-items: center;
        gap: 15px;
      }

      @media (max-width: 576px) {
        padding: 10px 5px;
      }
    }

    .filter-date-wrapper {
      height: 106px;
    }

    .filter-date-label {
      font-weight: $fw_600;
      font-size: $fs_small;
      color: $gray_2;
    }

    .filter-custom-date {
      height: 52px;
      border-radius: 12px;
      border: 2px solid $gray_5;
      padding: 17px;
      width: 100%;
    }

    .filter-custom-date:focus {
      outline: none;
    }
  }
`;
