import React from 'react';
import { Route, Routes } from 'react-router-dom';
import YourAgreements from './YourAgreements';
import OtherAgreements from './OtherAgreements';
import AgreementCommunication from './AgreementCommunication';

export default function IndividualAgreements() {
  return (
    <Routes>
      <Route path="">
        <Route
          element={<YourAgreements />}
          path="your-agreements/:agreementType"
        />
        <Route
          element={<OtherAgreements />}
          path="other-agreements/:agreementType"
        />
        <Route
          path="communication/request/:agreementId"
          element={<AgreementCommunication />}
        />
      </Route>
    </Routes>
  );
}
