import styled from 'styled-components';

export const ViewAgreementWrapper = styled.div`
  & .main-wrapper {
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 16px;
    & .heading {
      display: flex;
      justify-content: space-between;
      font-size: 25px;
      font-weight: 700;
      & .heading-data {
        display: flex;
        gap: 5px;
        align-items: center;
        & .type {
          font-size: 14px;
          font-weight: 400;
          color: rgba(23, 25, 29, 0.4);
        }
      }
    }
    .search-container {
      display: flex;
      height: 50px;
      gap: 8px;
    }
    & .content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  & .submit-btn {
    display: grid;
    place-content: center;
    margin-top: 10px;
  }
  .close-agreement-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 16px 24px;
    .btn-wrapper {
      display: flex;
      gap: 10px;
      width: 100%;
      justify-content: center;
    }
  }
  & .no-data {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.75px;
    color: #404f67;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 50px;
  }
`;
