import { HomeNavWrapper } from './style';
import { useNavigate } from 'react-router-dom';
import cx from 'classname';
import { useState, useCallback } from 'react';
import Icon from 'utlils/Icons/Icon';
import { Button, OptionWrapper } from 'components';
import logo from 'assests/images/new-logo.png';
import hamBurgerBlack from 'assests/images/hamBurgerBlack.png';

export const HomeNavbar = ({ options }) => {
  const [active, setActive] = useState(options.components[0].id);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const navigate = useNavigate();

  const onNavMenuClick = useCallback(
    (item) => {
      setActive(item.id);
      setOpenDropdown(!openDropdown);
      if (item?.url && !item?.isDropdown) {
        navigate(`${item.url}`, { replace: true });
      }
    },
    [navigate, openDropdown]
  );

  const onClickDropdownMenu = useCallback(
    (item) => {
      setSelectedDropdown(item.id);
      setOpenDropdown(false);
      if (item?.url) {
        navigate(`${item.url}`, { replace: true });
      }
    },
    [navigate]
  );

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <HomeNavWrapper>
      <nav className="navbar navbar-expand-lg homenav">
        <a href={process.env.REACT_APP_BASE_URL} className="navbar-brand">
          <img src={logo} alt="logo" className="homenav_logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerHome"
          aria-controls="navbarTogglerHome"
          aria-label="Toggle navigation"
          data-toggle="collapse"
          data-target="#navbarTogglerHome"
          aria-expanded={!isNavCollapsed ? true : false}
          onClick={handleNavCollapse}>
          <img src={hamBurgerBlack} className="ham-burger" alt="ham-burger" />
        </button>
        <div
          className={cx(isNavCollapsed && 'collapse', 'navbar-collapse')}
          id="navbarTogglerHome">
          <div className="homenav-items">
            {options.components.map((item) => {
              const isDropdownItem = item?.isDropdown;
              return (
                <div key={item.id} className="homenav-items_menu-item">
                  <div
                    className={cx(
                      'homenav-items_menu-item_list',
                      active === item.id ? 'homenav-items_menu_active' : ''
                    )}
                    onClick={() => onNavMenuClick(item)}>
                    <span
                      className={cx(
                        'homenav-items_menu-item_label',
                        active === item.id
                          ? 'homenav-items_menu-item_list_active'
                          : ''
                      )}>
                      {item.title}
                    </span>
                    {isDropdownItem && (
                      <Icon
                        name={
                          active === item.id && openDropdown
                            ? 'arrow-drop-up'
                            : 'arrow-down'
                        }
                        color="#404F67"
                        className="dropdown-icon"
                        size={active === item.id && openDropdown ? 18 : 22}
                      />
                    )}
                  </div>

                  {active === item.id && openDropdown && isDropdownItem && (
                    <OptionWrapper
                      className="option-wrapper"
                      onClick={onClickDropdownMenu}
                      options={item.dropDownItems}
                      selectedOption={selectedDropdown}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="left-half">
            {/* <span
              className="invest-link"
              onClick={() => navigate(options.invest.action)}>
              {options.invest.title}
            </span> */}
            <Button
              label={options.primaryButton.title}
              backgroundColor="transparent"
              color="#FFFFFF"
              buttonStyle={{ borderRadius: '25px', padding: '20px' }}
              onClick={() => navigate(options.primaryButton.action)}
            />
            <Button
              label={options.secondaryButton.title}
              backgroundColor="#FFFFFF40"
              hoverBackground="#219fff"
              hoverColor="#ffffff"
              color="#FFFFFF"
              buttonStyle={{
                width: '150px',
                borderRadius: '25px',
                padding: '20px',
              }}
              onClick={() => navigate(options.secondaryButton.action)}
            />
          </div>
        </div>
      </nav>
    </HomeNavWrapper>
  );
};
